import { BaseStore } from "./BaseStore";
import { observable, action, makeObservable } from "mobx";
import request from "helpers/api/PrivateApiRequest";

export interface KontaktpersonInterface {
    "id"?: number,
    "navn": string,
    "email"?: string,
    "telefonDirekte"?: string,
    "telefonMobil"?: string
}

export class KontaktpersonStore extends BaseStore {
    @observable kontaktpersoner: Array<KontaktpersonInterface> = [];
    @observable selectedId: number | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.kontaktpersoner = [];
        this.selectedId = null;
    }

    @action
    getKontaktpersoner() {
        return this.get('kontaktpersoner', 'kontaktpersoner');
    }

    @action
    createKontaktperson(kontaktperson: KontaktpersonInterface) {
        return this.create(`kontaktpersoner`, kontaktperson, this.kontaktpersoner);
    }

    @action
    updateKontaktperson(kontaktperson: KontaktpersonInterface) {
        return this.update(`kontaktpersoner/${kontaktperson.id}`, kontaktperson, this.kontaktpersoner);
    }

    @action
    setSelectedId(id: number | null) {
        this.selectedId = id;
    }
}

export const kontaktpersonStore = new KontaktpersonStore();