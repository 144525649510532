import * as React from 'react';
import { observer } from 'mobx-react';
import SearchInput from 'components/SearchInput';

import css from './style.module.css';
import SelectLageropgave from 'components/SelectLageropgave';
import SelectKatalog from 'components/SelectKatalog';

interface Props {
    onSearchChanged: (value: string) => void,
    onKatalogIdChanged: (value: number | null) => void,
    onLageropgaveIdChanged: (value: number) => void,
    disableSelectLageropgave?: boolean,
    hideSearch?: boolean,
    hideKatalog?: boolean
}

@observer class TopActions extends React.Component<Props> {

    render() {
        const { onSearchChanged, onKatalogIdChanged, hideKatalog, hideSearch, onLageropgaveIdChanged, disableSelectLageropgave } = this.props;

        return (
            <div className={css.container}>
                {
                    !hideKatalog &&
                    <div style={{ marginBottom: '10px' }}>
                        <SelectKatalog onChange={onKatalogIdChanged} />
                    </div>
                }

                <div className={css.search}>
                    {
                        !hideSearch &&
                        <SearchInput onChange={onSearchChanged} />
                    }

                    <div style={{ display: 'inline-block' }}>
                        <SelectLageropgave
                            disabled={disableSelectLageropgave}
                            style={{ marginLeft: !hideSearch ? '10px': '0px' }}
                            onChange={onLageropgaveIdChanged} />
                    </div>
                </div>
            </div>
        )
    }
}

export default TopActions;