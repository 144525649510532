import React from 'react';
import { observer } from 'mobx-react';
import styles from './style.module.css';

interface Props {
    title: string,
    children: React.ReactNode,
    image?: string
}

@observer class PageDescription extends React.Component<Props> {

    render() {
        const { title, children, image } = this.props;

        return (
            <div className={styles.container}>
                {
                    image &&
                    <div className={styles.img}>
                        <img src={image} alt="Overblik" />
                    </div>
                }

                <div className={styles.description}>
                    <h1>
                        {title}
                    </h1>
                    {children}
                </div>
            </div>
        )
    }
}

export default PageDescription;