import React from 'react';
import { observer } from 'mobx-react';
import { kundeStore, KundeInterface } from 'stores/KundeStore';
import Button from 'components/Button';
import MyDock, { Row } from 'components/Dock';
import _ from 'lodash';
import { authStore } from 'stores/AuthStore';
import { navigationStore } from 'stores/NavigationStore';

interface State {
    isExpanded: boolean
}

@observer class SwitchUserButton extends React.Component<any, State> {

    state = { isExpanded: false }

    componentDidMount() {
        kundeStore.getKunder();
    }

    switchUser = async (e: React.MouseEvent, kundeId: number) => {
        e.preventDefault();

        await authStore.changeToken(kundeId);
        navigationStore.push('/', () => {
            window.location.reload();
        });
    }

    render() {
        const { isExpanded } = this.state;
        const kunder = kundeStore.kunder || [];
        if (kunder.length < 2) return null;

        return (
            <div style={{ marginRight: '15px', marginTop: '-20px', float: 'right' }}>
                <Button
                    label='Skift kunde'
                    onClick={() => this.setState({ isExpanded: true })} />
                <MyDock position='right' isVisible={isExpanded}>
                    <h2>
                        Skift kunde
                    </h2>

                    <Row label='Kunder' alignSelf='start'>
                        {
                            _.orderBy(kunder, 'firmanavn').map((kunde: KundeInterface, index: number) => {
                                return <p key={index}><a href=' ' onClick={(e) => this.switchUser(e, kunde.id)}>{kunde.firmanavn}</a></p>
                            })
                        }
                    </Row>

                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => this.setState({ isExpanded: false })}
                            label='Luk'
                            icon='cross'
                            type='button'
                            style={{ marginTop: '100px' }}
                        />
                    </div>
                </MyDock>
            </div >
        )
    }
}

export default SwitchUserButton;