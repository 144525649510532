import React from 'react';
import { observer } from 'mobx-react';
import Header from './Header';
import { authStore } from 'stores/AuthStore';
import { navigationStore } from 'stores/NavigationStore';

import styles from './style.module.css';
import SwitchUserButton from 'components/SwitchUserButton';
import { lageropgaveStore } from 'stores/LageropgaveStore';

@observer class DashboardPage extends React.Component {

    async componentDidMount() {
        if (!authStore.isAuthenticated) {
            navigationStore.replace('/login');
        }
        else {
            await lageropgaveStore.getLageropgaver();
            if (lageropgaveStore.lageropgaver.length < 1) {
                authStore.logout();
            }
            else {
                lageropgaveStore.setSelectedLageropgave(lageropgaveStore.lageropgaver[0]);
            }
        }
    }

    render() {
        const { children } = this.props;

        if (!authStore.isAuthenticated || !lageropgaveStore.selectedLageropgave) {
            return null;
        }

        return (
            <div style={{ minWidth: '1050px' }}>
                <Header />
                <SwitchUserButton />
                <div className={styles.container}>
                    {children}
                </div>
            </div>
        )
    }
}

export default DashboardPage;