import { BaseStore } from "./BaseStore";
import { observable, action, makeObservable } from "mobx";
import { AdresseInterface } from "./AdresseStore";
import { KontaktpersonInterface } from "./KontaktpersonStore";
import request from "helpers/api/PrivateApiRequest";

export enum OrdreTypeEnum {
    UDEFINERET = 0,
    TIMEPRIS = 1,
    TILBUD = 2,
    TARIF = 3
}

export interface OrdreInterface {
    "id": number,
    "navn": string,
    "ordreType": number,
    "eanNummer": string,
    "rekvisitionsNummer": string,
    "type": OrdreTypeEnum,
    "adresseFra": AdresseInterface,
    "kontaktFra": KontaktpersonInterface,
    "adresseTil": AdresseInterface,
    "kontaktTil": KontaktpersonInterface,
    "kundensKontaktperson": KontaktpersonInterface,
    "roskilde": boolean,
    "glostrup": boolean,
    "koege": boolean
}

export class OrdreStore extends BaseStore {
    @observable ordrer: Array<OrdreInterface> = [];
    @observable selectedId: number | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.ordrer = [];
        this.selectedId = null;
    }

    @action
    getOrdrer(lageropgaveId?: number) {
        return this.get(`ordre${lageropgaveId ? '?lageropgaveid=' + lageropgaveId : ''}`, 'ordrer');
    }

    @action
    setSelectedId(id: number | null) {
        this.selectedId = id;
    }
}

export const ordreStore = new OrdreStore();