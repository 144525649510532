import Dropdown from "components/Forms/Dropdown";
import { FieldState } from "formstate";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { shipmondoStore } from "stores/PublicApi/ShipmondoStore";

interface Props {
    fieldState: FieldState<any>,
    countryCode: string,
    carrierCode: string,
}

const SelectProduct = observer((props: Props) => {

    const { carrierCode, countryCode } = props;
    useEffect(() => {
        props.fieldState.onChange(null);

        shipmondoStore.getProducts(carrierCode, countryCode);
    }, [carrierCode, countryCode])

    useEffect(() => {
        return () => {
            props.fieldState.onChange(null);
            shipmondoStore.resetProducts();
        }
    }, [])

    return (
        <div style={{ marginTop: '8px' }}>
            <Dropdown
                fieldState={props.fieldState}
                placeholder='Vælg leveringstype'
                options={shipmondoStore.products.map((p) => ({
                    label: p.name,
                    value: p.code
                }))}
            />
        </div>
    )
})

export default SelectProduct;