import React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import Form from 'components/Forms/Form';
import { FieldState, FormState } from 'formstate';
import DateInput from 'components/Forms/DateInput';
import Breadcrumb from 'components/Breadcrumb';
import { navigationStore } from 'stores/NavigationStore';
import DetailsRow from 'components/DetailsRow';
import SelectType, { SelectTypeEnum } from './SelectType';
import Checkbox from 'components/Forms/Checkbox';
import SelectKatalog from 'components/SelectKatalog';
import { katalogStore } from 'stores/KatalogStore';
import { authStore } from 'stores/AuthStore';
import SelectLageropgave from 'components/SelectLageropgave';
import { lageropgaveStore } from 'stores/LageropgaveStore';
import { isRequired } from 'helpers/FormValidation';
import moment from 'moment';
import _ from 'lodash';

@observer class ExportBevaegelserPage extends React.Component {

    form = new FormState({
        lageropgaveId: new FieldState<number | null>(lageropgaveStore.selectedLageropgave!.id),
        from: new FieldState(null).validators(isRequired),
        to: new FieldState(null).validators(isRequired),
        type: new FieldState(SelectTypeEnum.ALLE),
        onlyOeko: new FieldState(null),
        onlyRetur: new FieldState(null),
        onlySkade: new FieldState(null),
        katalogId: new FieldState<number | null>(null),
        includeBestilling: new FieldState(true),
    })

    async componentDidMount() {
        katalogStore.getKataloger();
    }

    onSubmit = async () => {
        const form = this.form.$;
        await authStore.getDownloadToken();

        let vareNr: null | string = null;
        if (form.katalogId.$) {
            const katalog = _.find(katalogStore.kataloger, (k) => k.id === form.katalogId.$);
            vareNr = katalog?.varenr || null;
        }

        window.open([
            `${process.env.REACT_APP_API_BASEURL}lageropgaver/${form.lageropgaveId.$}/statistik/bevaegelser-to-csv`,
            `?clientKey=${process.env.REACT_APP_CLIENT_KEY}`,
            `&token=${authStore.downloadToken}`,
            `&from=${moment(form.from.$!).format('MM-DD-YYYY')}`,
            `&to=${moment(form.to.$!).format('MM-DD-YYYY')}`,
            `${form.type.$ !== SelectTypeEnum.ALLE ? `&type=${form.type.$}` : ''}`,
            `${form.onlyOeko.$ ? `&onlyOeko=${form.onlyOeko.$}` : ''}`,
            `${form.onlyRetur.$ ? `&onlyRetur=${form.onlyRetur.$}` : ''}`,
            `${form.onlySkade.$ ? `&onlySkade=${form.onlySkade.$}` : ''}`,
            `${vareNr ? `&onlyVareNr=${vareNr}` : ''}`,
            `${form.includeBestilling.$ ? `&includeBestilling=${form.includeBestilling.$}` : ''}`,
        ].join(''));
    }

    render() {
        const form = this.form.$;

        return (
            <div>
                <Breadcrumb
                    onRootClicked={() => navigationStore.push('/bevaegelser')}
                    rootLabel='Bevægelser'
                    label='Eksporter bevægelser (CSV)'
                />

                <Form onSubmit={this.onSubmit} formState={this.form}>
                    <DetailsRow label='Lageropgave' alignLabel='center'>
                        <SelectLageropgave onChange={(opgaveId) => form.lageropgaveId.onChange(opgaveId)} />
                    </DetailsRow>

                    <DetailsRow label='Fra' alignLabel='center'>
                        <DateInput fieldState={form.from} allowPastDates={true} />
                    </DetailsRow>

                    <DetailsRow label='Til' alignLabel='center'>
                        <DateInput fieldState={form.to} allowPastDates={true} />
                    </DetailsRow>

                    <DetailsRow label='Type'>
                        <SelectType fieldState={form.type} />
                    </DetailsRow>

                    {
                        katalogStore.kataloger.length > 0 &&
                        <DetailsRow label='Varenummer'>
                            <SelectKatalog
                                skipGetData={true}
                                onChange={(val) => form.katalogId.onChange(val)}
                            />
                        </DetailsRow>
                    }

                    <DetailsRow label='Filtrering'>
                        <Checkbox
                            id='onlyOeko'
                            label='Kun økologiske varer'
                            fieldState={form.onlyOeko}
                        />

                        <div style={{ marginTop: '8px' }} />
                        <Checkbox
                            id='onlyRetur'
                            label='Kun returvarer'
                            fieldState={form.onlyRetur}
                        />

                        <div style={{ marginTop: '8px' }} />
                        <Checkbox
                            id='onlySkade'
                            label='Kun beskadigede varer'
                            fieldState={form.onlySkade}
                        />

                        {/* <Checkbox
                            id='onlyVareNr'
                            label='Kun varenummer'
                            fieldState={form.onlyVareNr}
                        /> */}
                    </DetailsRow>


                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => navigationStore.push('/bevaegelser')}
                            label='Fortryd'
                            type='button'
                            icon='cross'
                            style={{ marginRight: '20px', marginTop: '40px' }}
                        />
                        <Button
                            primary={true}
                            label='Download bevægelser'
                            icon='arrowDownBox'
                        />
                    </div>
                </Form>
            </div>
        )
    }
}

export default ExportBevaegelserPage;