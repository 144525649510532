import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import css from './style.module.css';

interface Props {
    onChange: (value: string) => void,
    placeholder?: string
}

@observer class SearchInput extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.searchQueryChanged = _.debounce(this.searchQueryChanged, 100);
    }

    searchQueryChanged = (value: string) => {
        this.props.onChange(value);
    }

    render() {
        return (
            <input
                type='text'
                className={css.input}
                placeholder={this.props.placeholder || 'Søg...'}
                onChange={(e) => this.searchQueryChanged(e.target.value)} />
        )
    }
}

export default SearchInput;