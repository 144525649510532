// import request from 'helpers/api/Request';
import { action, makeObservable } from 'mobx';
import _ from 'lodash';
import LoadPromise from 'helpers/LoadPromise';
import SaveableStore from './SaveableStore';
import { AxiosInstance } from 'axios';

export interface PagingResultInterface<T> {
    "results": Array<T>,
    "currentPage": number,
    "pageCount": number,
    "pageSize": number,
    "rowCount": number,
    "firstRowOnPage": number,
    "lastRowOnPage": number,
    "hasNextPage": boolean // Added client side
}

export class BaseStore extends SaveableStore {
    request: AxiosInstance;

    constructor(request: AxiosInstance, name?: string) {
        super(name);
        
        this.request = request;
        makeObservable(this);
    }

    @action
    getPaged = async (pageableEndpoint: string, thisPage: PagingResultInterface<any> | null, classProperty: string, pageSize: number = 20) => {
        return new Promise(async (resolve, reject) => {
            try {
                let pageNumber = 1;

                if (thisPage) {
                    if (thisPage.currentPage === thisPage.pageCount) {
                        resolve(thisPage);
                        return;
                    }

                    pageNumber = thisPage.currentPage + 1;
                }

                const hasQueryParameters = pageableEndpoint.indexOf('?') !== -1;
                const firstSeperator = hasQueryParameters ? '&' : '?';

                const res = await this.request.get(`${pageableEndpoint}${firstSeperator}pageNumber=${pageNumber}&pageSize=${pageSize}`);

                if ((this as any)[classProperty] && (this as any)[classProperty]['results'] instanceof Array) {
                    const newResults = (this as any)[classProperty]['results'].concat(res.data.results);
                    res.data.results = newResults;
                }

                res.data.hasNextPage = res.data.currentPage < res.data.pageCount;

                (this as any)[classProperty] = res.data;
                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    get(endpoint: string, classProperty: string, force: boolean = true): any {
        return new Promise(async (resolve, reject) => {
            try {

                if (force || (!force && (!(this as any)[classProperty] || (this as any)[classProperty].length === 0))) {
                    let res = await this.request.get(endpoint);
                    (this as any)[classProperty] = res.data;
                }

                resolve((this as any)[classProperty]);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    create(endpoint: string, obj: any, target: any) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await this.request.post(endpoint, obj);

                if (res.data.id) {
                    obj.id = res.data.id;
                }

                if (target instanceof Array) {
                    target.push(obj);
                }
                else {
                    target = obj;
                }

                resolve(obj);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    update(endpoint: string, obj: any, target: any, useResponseObject?: boolean) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await this.request.put(endpoint, obj);
                if (target instanceof Array) {
                    const index = _.findIndex(target, { id: obj.id });
                    target.splice(index, 1, useResponseObject ? res.data : obj);
                }
                else {
                    target = obj;
                }

                resolve(obj);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    delete(endpoint: string, id: number, target: any) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await this.request.delete(endpoint);
                if (target instanceof Array) {
                    _.remove(target, (t: any) => t.id === id);
                }
                else {
                    target = null;
                }

                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    }

    getById(endpoint: string, target: Array<any>) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await this.request.get(endpoint);
                const foundUser = res.data;
                const index = _.findIndex(target, { id: foundUser.id });
                if (index) {
                    target.splice(index, 1, foundUser);
                }
                else {
                    target.push(foundUser);
                }

                resolve(foundUser);
            }
            catch (error) {
                reject(error);
            }
        })
    }
}