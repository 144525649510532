import * as React from 'react';
import { Table } from 'rsuite';
const { Cell } = Table;

const SelectableCell = ({ rowData, dataKey, ...props }: any) => {
    return (
        <Cell {...props} style={props.selectedid === rowData.id ? { background: '#0096EB' } : { cursor: 'pointer' }}>
            <span>
                {rowData[dataKey]}
                {props.children ? props.children(rowData) : null}
            </span>
        </Cell >
    )
}

export default SelectableCell;