import DetailsRow from "components/DetailsRow";
import { observer } from "mobx-react";
import React from "react";
import { inventoryStore } from "stores/PublicApi/InventoryStore";
import SelectedInventoryOverlay, { SelectedInventoryOverlayActionEnum } from "./SelectedInventoryOverlay";

interface State {
    selectedInventoryId: number | null,
    action: SelectedInventoryOverlayActionEnum
}

@observer class ShopifyInventory extends React.Component<any, State> {

    state = {
        selectedInventoryId: null,
        action: SelectedInventoryOverlayActionEnum.CREATE
    }

    componentDidMount() {
        inventoryStore.getInventories();
    }

    componentWillUnmount() {
        inventoryStore.resetStore();
    }

    onClose = () => {
        inventoryStore.getInventories();
        this.setState({ selectedInventoryId: null });
    }

    render() {
        const { selectedInventoryId, action } = this.state;
        const inventories = inventoryStore.inventories;

        return (
            <div>
                <DetailsRow label='Lagersager'>
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        {
                            inventories.map((inventory, index) => {
                                return (
                                    <li
                                        style={{ marginBottom: '10px' }}
                                        key={index}>
                                        <button
                                            onClick={() => this.setState({
                                                selectedInventoryId: inventory.id,
                                                action: inventory.shopifyEnabled
                                                    ? SelectedInventoryOverlayActionEnum.UPDATE
                                                    : SelectedInventoryOverlayActionEnum.CREATE
                                            })}
                                            type='button'
                                            style={{ textDecoration: 'underline', background: 'transparent' }}>
                                            {inventory.name} {inventory.shopifyEnabled ? ' (Aktiv Shopify integration)' : ''}
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </DetailsRow>
                {
                    selectedInventoryId &&
                    <SelectedInventoryOverlay
                        action={action}
                        inventoryId={selectedInventoryId}
                        onClose={() => this.onClose()}
                    />
                }

            </div>
        )
    }

}

export default ShopifyInventory;