import * as React from 'react';
import { observer } from 'mobx-react';
import css from './style.module.css';

interface Props {
    rootLabel: string,
    label: string
    onRootClicked: () => void
}

@observer class Breadcrumb extends React.Component<Props> {

    render() {
        const { onRootClicked, rootLabel, label } = this.props;

        return (
            <ul className={css.ul}>
                <li><button className={css.rootButton} onClick={onRootClicked}>{rootLabel}</button></li>
                <li className={css.activeLabel}>{label}</li>
            </ul>
        )
    }
}

export default Breadcrumb;