import { observable, action, makeObservable } from "mobx";

interface ToastInterface {
    message: string,
    title: string,
    level: string,
    position?: string,
    autoDismiss?: number,
    dismissible?: 'both' | 'click' | 'button'
}

export class ToastStore {
    @observable toasts: Array<ToastInterface> = [];

    constructor() {
        makeObservable(this);
    }

    @action
    addError(message: string) {
        this.addToast({
            title: 'Fejl',
            level: 'error',
            message: message,
            dismissible: 'click'
        });
    }

    @action
    addToast(toast: ToastInterface) {
        if (!toast.position) {
            toast.position = 'tc';
        }

        this.toasts.push(toast);
    }

    @action
    resetToasts() {
        this.toasts = [];
    }
}

export const toastStore = new ToastStore();