import { observable, action, makeObservable } from "mobx";
import _ from 'lodash';
import { KatalogInterface } from "./KatalogStore";
import { BaseStore } from "./BaseStore";
import request from "helpers/api/PrivateApiRequest";
import OpbevaringsHolderHelper from 'helpers/OpbevaringsHolderHelper';

export interface LageropgaveInterface {
    "bedstFoerPaakraevet": number,
    "deaktiverAutoInOut": boolean,
    "id": number,
    "kundeId": number,
    "kundenavn": string,
    "lagerlinieBilledePaakraevet": boolean,
    "navn": string,
    "ordrenavn": string,
    "skjulInOutIApp": boolean,
    "visShipmondoIWebUI": boolean
}

export interface LagerlinieInterface {
    "id": number,
    "lageropgaveId": number,
    "metaOeko": boolean,
    "metaRetur": boolean,
    "metaSkade": boolean,
    "beskrivelse": string,
    "pris": Number,
    "modtagetDato": string,
    "fil": FilInterface,
    "filUrl": string,
    "opbevaringsholdere": Array<OpbevaringsholderInterface>
}

export interface OpbevaringsholderInterface {
    "id": number,
    "lagerlinieId": number,
    "holderId": string,
    "holderTypeId": number,
    "holderNavn": string,
    "bedstFoerDato"?: string,
    "identifikationstekst": string,
    "note": string,
    "parentId": number,
    "lageropgaveId": number,
    "antal": number,
    "uspecificeretAntal": number,
    "pluk": number,
    "reserveret": number,
    "guid": string,
    "parentGuid": string,
    "kundenavn": string,
    "lagerlinieNote": string,
    "iBrug": boolean,
    "lagerlinieIdentifikation": string,
    "katalog"?: KatalogInterface,
    "_isChild"?: boolean // Added client side
}

export interface FilInterface {

}

export interface SelectedIdInterface {
    antal: number,
    uspecificeretAntal: number
}

export interface MappedLagerlinieInterface extends OpbevaringsholderInterface {
    modtagetDato?: string,
    children?: Array<MappedLagerlinieInterface>
}

export class LageropgaveStore extends BaseStore {

    @observable lageropgaver: Array<LageropgaveInterface> = [];

    @observable lagerlinier: Array<LagerlinieInterface> = [];
    @observable mappedLagerlinier: Array<MappedLagerlinieInterface> = [];
    @observable selectedLageropgave: LageropgaveInterface | null = null;
    @observable selectedIds: { [key: string]: SelectedIdInterface } = {};

    constructor() {
        super(request, 'LageropgaverStore');
        makeObservable(this);

        this.initSessionStorage(this, [
            'selectedIds',
            'lagerlinier',
            'selectedLageropgave'
        ])
    }

    @action
    resetStore() {
        this.lageropgaver = [];
        this.lagerlinier = [];
        this.selectedIds = {};
        this.selectedLageropgave = null;
    }

    @action
    resetSelectedIds = () => {
        this.selectedIds = {};
    }

    @action
    getLageropgaver(force: boolean = true) {
        return this.get(`lageropgaver`, 'lageropgaver', force);
    }

    @action
    setSelectedLageropgave(lageropgave: LageropgaveInterface) {
        this.selectedLageropgave = lageropgave;
    }

    @action
    async getLagerlinier(opgaveId: number) {
        const res = await this.get(`lageropgaver/${opgaveId}/lagerlinier/v2`, 'lagerlinier');
        this.mappedLagerlinier = this.mapLagerlinier(this.lagerlinier);
        return res;
    }

    @action
    filterLagerlinierBySearchQuery(searchQuery: string) {
        const loweredQuery = searchQuery.toLowerCase();

        if (searchQuery.length === 0) {
            this.mappedLagerlinier = this.mapLagerlinier(this.lagerlinier);
        }
        else {
            const filtered = this.lagerlinier.filter((l) =>
                (l.beskrivelse && l.beskrivelse.toLowerCase().indexOf(loweredQuery) !== -1)
                || !!_.find(l.opbevaringsholdere, (oh) =>
                    (!oh.katalog && oh.identifikationstekst && oh.identifikationstekst.toLowerCase().indexOf(loweredQuery) !== -1)
                    || (oh.katalog && oh.katalog.varenr && oh.katalog.varenr.toLowerCase().indexOf(loweredQuery) !== -1)
                    || (oh.katalog && oh.katalog.navn && oh.katalog.navn.toLowerCase().indexOf(loweredQuery) !== -1)
                    || (oh.katalog && oh.katalog.beskrivelse && oh.katalog.beskrivelse.toLowerCase().indexOf(loweredQuery) !== -1)
                    || (oh.holderId && oh.holderId.toLowerCase().indexOf(loweredQuery) !== -1)
                )
            )

            this.mappedLagerlinier = this.mapLagerlinier(filtered);
        }
    }

    @action
    filterLagerlinierByKatalog(katalogId: number | null) {
        if (!katalogId) {
            this.mappedLagerlinier = this.mapLagerlinier(this.lagerlinier);
        }
        else {
            const filtered = this.lagerlinier.filter((l) =>
                _.some(l.opbevaringsholdere, (oh) => oh.katalog?.id === katalogId)
            )

            this.mappedLagerlinier = this.mapLagerlinier(filtered)
        }
    }

    mapOpbevaringsholder = (opbevaringsholder: OpbevaringsholderInterface, lagerlinie: LagerlinieInterface, unprocessedHoldere: Array<OpbevaringsholderInterface>, isTopOpbevaringsholder: boolean = false): any => {
        const unprocessed: Array<OpbevaringsholderInterface> = [];
        const children: Array<OpbevaringsholderInterface> = [];

        // Split array into two, 'used' childrens and the rest, which is unprocessed.
        unprocessedHoldere.forEach((oh: OpbevaringsholderInterface) => {
            if (oh.parentId === opbevaringsholder.id) {
                children.push(oh)
            }
            else {
                unprocessed.push(oh)
            }
        })

        return {
            ...opbevaringsholder,
            beskrivelse: isTopOpbevaringsholder ? lagerlinie?.beskrivelse : null,
            fil: isTopOpbevaringsholder && lagerlinie?.filUrl?.length > 0 ? { url: lagerlinie.filUrl } : null,
            metaOeko: isTopOpbevaringsholder ? lagerlinie?.metaOeko : null,
            metaRetur: isTopOpbevaringsholder ? lagerlinie?.metaRetur : null,
            metaSkade: isTopOpbevaringsholder ? lagerlinie?.metaSkade : null,
            modtagetDato: lagerlinie?.modtagetDato,
            prettyHolderName: OpbevaringsHolderHelper.holderIdentifikation(opbevaringsholder),
            lagerlinieId: lagerlinie?.id,
            children: children.map((oh: OpbevaringsholderInterface) => {
                // Only forward unprocessed holdere, which will limit the loop amount down the tree.
                return this.mapOpbevaringsholder(oh, lagerlinie, unprocessed)
            })
        }
    }

    mapLagerlinier = (lagerlinier: Array<LagerlinieInterface>) => {
        const x = lagerlinier.filter((l) => l.opbevaringsholdere && l.opbevaringsholdere?.length > 0);
        return x.map((lagerlinie) => {
            const topOh = _.find(lagerlinie.opbevaringsholdere, (oh: OpbevaringsholderInterface) => oh.parentId === null);
            return topOh ? this.mapOpbevaringsholder(topOh, lagerlinie, lagerlinie.opbevaringsholdere, true) : [];
        })
    }

    @action
    setSelectedId = (id: number, value: SelectedIdInterface) => {
        let ids = this.selectedIds;
        ids[id] = {
            antal: Number(value.antal) || 0,
            uspecificeretAntal: Number(value.uspecificeretAntal) || 0
        };
        this.selectedIds = Object.assign({}, ids);
    }

    @action
    deleteSelectedId(id: number) {
        let ids = this.selectedIds;
        if (!ids[id]) return;

        delete ids[id];
        this.selectedIds = Object.assign({}, ids);
    }

    addTreeOfOpbevaringsholdere = (collection: Array<OpbevaringsholderInterface>, parentId: number) => {
        const holdere = lageropgaveStore.lagerlinier.map((linie: LagerlinieInterface) => linie.opbevaringsholdere).flat();
        const children = _.filter(holdere, { parentId: parentId });
        for (const c of children) {
            if (c.iBrug) continue;
            if (c.reserveret > 0 && c.antal > 0 && c.antal === c.reserveret) continue; // Hvis alt er reserveret hoppet vi videre

            collection.push({
                ...c,
                pluk: OpbevaringsHolderHelper.holderAntal(c),
                _isChild: true
            });

            this.addTreeOfOpbevaringsholdere(collection, c.id);
        }

        return collection;
    }

    getSelectedOpbevaringsholdere = (includeChildren: boolean = true) => {
        const holdere = this.lagerlinier.map((linie: LagerlinieInterface) => linie.opbevaringsholdere).flat();

        const filteredHoldere: Array<OpbevaringsholderInterface> = [];

        for (const h of holdere) {
            const val = this.selectedIds[h.id.toString()];
            if (!val) continue;

            filteredHoldere.push({
                ...h,
                pluk: val.antal,
                uspecificeretAntal: val.uspecificeretAntal
            });

            if (includeChildren && val.uspecificeretAntal === 0) {
                this.addTreeOfOpbevaringsholdere(filteredHoldere, h.id);
            }
        }

        return filteredHoldere;
    }

}

export const lageropgaveStore = new LageropgaveStore();