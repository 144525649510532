import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import DetailsRow from "components/DetailsRow";
import { observer } from "mobx-react";
import React from "react";
import { navigationStore } from "stores/NavigationStore";
import { authStore } from "stores/PublicApi/AuthStore";
import { publicApiTokenStore } from "stores/PublicApiTokenStore";
import ShopifyInventory from "./ShopifyInventory";

@observer class PublicApiSettingsPage extends React.Component {

    async componentDidMount() {
        if (!publicApiTokenStore.bearerToken) {
            await authStore.getToken();
        }
        authStore.getApiKeys();
    }

    componentWillUnmount() {
        authStore.resetStore();
    }

    onCreateApiKey = () => {
        authStore.createApiKey();
    }

    onDeleteApiKey = (keyIdentification: string) => {
        if (!window.confirm('Er du sikker på at du vil fjerne denne API nøgle? Hvis du sletter den, vil eventuelle aktive Shopify integrationer øjeblikkeligt stoppe med at virke.')) return;

        authStore.deleteApiKey(keyIdentification);
    }

    render() {
        if (!publicApiTokenStore.bearerToken) return null;

        const apiKeys = authStore.apiKeys;
        const apiKey = apiKeys.length > 0 ? authStore.apiKeys[0] : null;

        return (
            <div>
                <Breadcrumb
                    onRootClicked={() => navigationStore.push('/')}
                    rootLabel='Indstillinger'
                    label='Shopify'
                />
                <DetailsRow label='API nøgle' alignLabel='center'>
                    {
                        apiKey &&
                        <span>
                            {
                                apiKey.key &&
                                <>
                                    <b style={{ marginBottom: '8px' }}>Vises kun én gang - Kopier nu og gem et sikkert sted:</b><br />
                                    <textarea
                                        onFocus={(ev) => ev.target.select()}
                                        readOnly={true}
                                        style={{
                                            width: '400px',
                                            height: '120px'
                                        }}>
                                        {apiKey.key}
                                    </textarea>
                                </>
                            }

                            {
                                apiKey.key === null &&
                                <>
                                    Aktiv og oplyst ved generering
                                    <Button
                                        style={{ marginLeft: '15px' }}
                                        label='Slet nøgle'
                                        onClick={() => this.onDeleteApiKey(apiKey.keyIdentification)}
                                    />
                                </>
                            }

                        </span>
                    }

                    {
                        !apiKey &&
                        <span>
                            <Button label='Opret nøgle' onClick={this.onCreateApiKey} />
                        </span>
                    }
                </DetailsRow>
                <ShopifyInventory />
            </div>
        )
    }

}

export default PublicApiSettingsPage;