import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toastStore } from "stores/ToastStore";
import { Cache } from 'helpers/api/Cache';
import { loadingStore } from "stores/LoadingStore";
import { authStore } from "stores/AuthStore";
import { privateApiTokenStore } from "stores/PrivateApiTokenStore";

export interface BearerTokenInterface {
    bearerToken: string | null
}

class RequestFactory {
    
    buildRequest = (baseUrl: string, clientKey: string, tokenStore: BearerTokenInterface) => {
        const request = axios.create({
            baseURL: baseUrl,
            headers: {
                'client-key': clientKey,
                'cache-control': 'no-cache'
            }
        })

        // Keep bearer token updated
        request.interceptors.request.use(async (config) => {
            if (this.isCacheableMethod(config)) {
                const uuid = config.url;
                if (uuid) {
                    const cacheUrl = baseUrl + uuid;
                    const lastCachedResult = Cache.get(cacheUrl);
                    if (lastCachedResult) {
                        config.headers = { ...config.headers, 'If-None-Match': lastCachedResult.etag };
                    }
                }
            }
            
            loadingStore.addRequest();
            
            if (tokenStore.bearerToken) {
                config.headers!['Authorization'] = 'bearer ' + tokenStore.bearerToken;
            }
    
            return config;
        })

        // Apply general response logic
        request.interceptors.response.use(
            this.successResponseHandler,
            this.errorResponseHandler
        )

        return request;

    }

    successResponseHandler = (response: AxiosResponse) => {
        loadingStore.removeRequest();

        if (this.isCacheableMethod(response.config)) {
            const responseETAG = response.headers.etag;
            if (responseETAG && response.config.url) {
                Cache.set(response.config.url, responseETAG, response.data);
            }
        }

        return response;
    }

    errorResponseHandler = (error: AxiosError): any => {
        loadingStore.removeRequest();

        if (error.response) {
            switch (error.response.status) {
                case 302:
                    const getCachedResult = this.getCacheByAxiosConfig(error.response.config);
                    if (!getCachedResult) {
                        return Promise.reject(error);
                    }
                    const newResponse = error.response;
                    newResponse.status = 200;
                    newResponse.data = getCachedResult.value;
                    return Promise.resolve(newResponse);

                case 400:
                    if (error.response.data.error) {
                        toastStore.addError(error.response.data.error);
                    }

                    break;

                case 401:
                    if (authStore) {
                        authStore.logout();
                    }

                    break;
                case 403:
                    toastStore.addError(error.response.data.error);
                    break;

                case 404:
                    toastStore.addError('Fejlkode 404 - Ikke fundet');
                    break;

                case 503:
                    toastStore.addError('Systemet bliver opdateret. Prøv igen om et øjeblik.');
                    break;

            }

        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        return Promise.reject(error);
    }

    getCacheByAxiosConfig = (config: AxiosRequestConfig) => {
        if (config.url) {
            return Cache.get(config.url);
        }
        return null;
    }

    isCacheableMethod = (config: AxiosRequestConfig) => {
        if (config.method) {
            return ~['GET', 'HEAD'].indexOf(config.method.toUpperCase());
        }
        return false;
    }
    
}

export const requestFactory = new RequestFactory();