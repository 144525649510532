import * as React from 'react';
import { toastStore } from 'stores/ToastStore';
import { observer } from 'mobx-react';
import { uploadFile } from 'helpers/FileHelper';

interface Props {
    onSubmit: Function,
    className?: string,
    customUpload?: boolean,
    allowedFileTypes?: Array<string>
}

@observer class UploadFile extends React.Component<Props> {

    hiddenUploadFieldRef: React.RefObject<any>;

    allowedFileTypes = [
        'pdf'
    ]

    constructor(props: Props) {
        super(props);

        this.hiddenUploadFieldRef = React.createRef();
    }

    selectFile = () => {
        this.hiddenUploadFieldRef.current.click();
    }

    uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        var { onSubmit, customUpload } = this.props;

        if (e.target.value !== "") {
            
            var file = e.target.files![0];
            
            // Fix to allow the same file to be selected again by end user, if anything goes wrong in browser.
            // Ref: https://stackoverflow.com/questions/55144603/react-cannot-use-fileinput-twice-without-refreshing-the-page
            this.hiddenUploadFieldRef.current.value = "";
            if (customUpload) {
                onSubmit(file);
                return;
            }

            try {
                const doc = await uploadFile(file);
                onSubmit(doc);
            }
            catch(error: any) {
                toastStore.addError(error);
            }
        }
    }

    render() {
        const { className, children, allowedFileTypes } = this.props;

        var fileTypes = allowedFileTypes ?? this.allowedFileTypes;
        const acceptFiles = fileTypes.toString();

        const hideStyle = {
            height: '0',
            width: '0',
            position: "absolute",
            top: "-9999px",
            overflow: 'hidden'
        } as React.CSSProperties

        return (
            <span>
                <input onChange={this.uploadFile} style={hideStyle} ref={this.hiddenUploadFieldRef} type="file" accept={acceptFiles} />
                <span className={className} onClick={this.selectFile}>
                    {children}
                </span>
            </span>
        );
    }

}

export default UploadFile;