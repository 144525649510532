import { BaseStore } from './BaseStore';
import request from '../helpers/api/PrivateApiRequest';
import Axios from 'axios';
import { privateApiTokenStore } from './PrivateApiTokenStore';

export interface FileInterface {
    "id": number,
    "displaynavn": string,
    "filnavn": string,
    "container": string,
    "verificeret": boolean,
    "beskrivelse": string,
    "udleveringsId": number,
    "url": string
}

export class FileStore extends BaseStore {

    constructor() {
        super(request);
    }

    createFile = (opts: FileInterface): Promise<{presignedUrl: string, expiresAt: string, fil: FileInterface}> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`filer`, opts);
                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    uploadFileToAPI = (presignedUrl: string, file: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const axiosRawRequest = Axios.create({
                    baseURL: process.env.REACT_APP_API_BASEURL,
                    headers: {
                        'client-key': process.env.REACT_APP_CLIENT_KEY!,
                        'Authorization': 'bearer ' + privateApiTokenStore.bearerToken,
                        'cache-control': 'no-cache'
                    }
                });

                const formData = new FormData();
                formData.append('file', file);

                const res = await axiosRawRequest.post(presignedUrl, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });

                resolve(res.data);
            }
            catch(error) {
                console.log(error)
                reject(error);
            }
        })
    }

    uploadFileToAzureBlob = (presignedUrl: string, file: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const axiosRawRequest = Axios.create({
                    baseURL: process.env.REACT_APP_API_BASEURL,
                    headers: {
                        'cache-control': 'no-cache'
                    }
                });
                console.log(file)
                const res = await axiosRawRequest.put(presignedUrl, file as Blob, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'content-type': file.type
                    }
                });

                resolve(res.data);
            }
            catch(error) {
                console.log(error)
                reject(error);
            }
        })
    }

}

export const fileStore = new FileStore();