import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

import css from './style.module.css';

interface Props {
    fieldState: FieldState<any>,
    placeholder?: string,
    type?: 'text' | 'textarea' | 'number',
    disabled?: boolean,
    maxLength?: number
}

@observer class Input extends React.Component<Props> {

    render() {
        const { fieldState, placeholder, type, disabled, maxLength } = this.props;

        return (
            <span style={{ position: 'relative' }}>
                <div className={css.error}>
                    {fieldState.error}
                </div>

                {
                    type === 'textarea' &&
                    <textarea
                        placeholder={placeholder}
                        className={`${css.input} ${css.textarea}`}
                        value={fieldState.value ?? ''}
                        rows={10}
                        maxLength={maxLength}
                        onChange={(e) => fieldState.onChange(e.target.value)}
                    />
                }

                {
                    type !== 'textarea' &&
                    <input
                        disabled={disabled}
                        placeholder={placeholder}
                        className={css.input}
                        type={type ?? 'text'}
                        value={fieldState.value ?? ''}
                        maxLength={maxLength}
                        onChange={(e) => fieldState.onChange(e.target.value)}
                    />
                }

            </span>
        )
    }
}

export default Input;