import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import _ from 'lodash';

import css from './style.module.css';

export interface DropdownOptionInterface {
    value: any,
    label: string
}

interface Props {
    fieldState: FieldState<any>,
    options: Array<DropdownOptionInterface>,
    placeholder?: string,
    orderBy?: string
    type?: 'number' | 'string',
    onCreate?: () => void,
    onChange?: (value: number | string) => void,
    style?: any,
    disabled?: boolean,
    containerStyle?: React.CSSProperties
}

@observer class Dropdown extends React.Component<Props> {

    PLACEHOLDER_VALUE = -1;
    ONCREATE_VALUE = -2;

    componentDidMount() {
        const { fieldState, placeholder } = this.props;
        const opts = this.getFormattedOptions();
        let value = fieldState.value;

        if (!value && !placeholder && opts.length > 0) {
            value = opts[0].value;
            fieldState.onChange(value);
        }
    }

    onChange(value: any) {
        const { fieldState, type, onCreate, onChange } = this.props;

        // Placeholder value can be string as well as number,
        // so we need to be able to compare "-1" with -1.
        // Therefore the two equals (==) is not a bug!
        if (value == this.ONCREATE_VALUE && onCreate) {
            onCreate();
            return;
        }

        if (value == this.PLACEHOLDER_VALUE) {
            fieldState.onChange(null);
        }
        else {
            if (type === 'number') {
                fieldState.onChange(Number(value));
            }
            else {
                fieldState.onChange(value);
            }
        }

        if (onChange) {
            onChange(value);
        }
    }

    getFormattedOptions = () => {
        const { orderBy, options } = this.props;

        let opts = options;
        if (orderBy) {
            opts = _.sortBy(opts, orderBy);
        }

        return opts;
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.options.length === 0 && nextProps.placeholder) {
            this.props.fieldState.reset();
        }
    }

    render() {
        const { fieldState, placeholder, onCreate, style, disabled, containerStyle } = this.props;

        const opts = this.getFormattedOptions();

        let value = fieldState.value;
        if (!value && placeholder) {
            value = this.PLACEHOLDER_VALUE;
        }

        if (onCreate && !_.find(opts, { value: this.ONCREATE_VALUE })) {
            opts.unshift({
                label: '--- Opret ny ---',
                value: this.ONCREATE_VALUE
            })
        }

        if (placeholder && !_.find(opts, { value: this.PLACEHOLDER_VALUE })) {
            opts.unshift({
                label: placeholder,
                value: this.PLACEHOLDER_VALUE
            })
        }

        return (
            <div style={containerStyle || { position: 'relative' }}>
                <div className={css.error}>
                    {fieldState.error}
                </div>
                <select
                    disabled={disabled}
                    style={style}
                    className={`${css.select} ${disabled ? css.disabled : ''}`}
                    value={value || undefined}
                    onChange={(e) => this.onChange(e.currentTarget.value)}>
                    {opts.map((option, index) => (
                        <option
                            value={option.value}
                            key={index}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        )
    }
}

export default Dropdown;