import * as React from 'react';
import { observer } from 'mobx-react';
import { lageropgaveStore } from 'stores/LageropgaveStore';
import Dropdown from 'components/Forms/Dropdown';
import { FormState, FieldState } from 'formstate';
import _ from 'lodash';

interface Props {
    onChange: (opgaveId: number) => void,
    style?: React.CSSProperties,
    disabled?: boolean
}

@observer class SelectLageropgave extends React.Component<Props> {

    form = new FormState({
        id: new FieldState(lageropgaveStore.selectedLageropgave!.id)
    })

    onChange = (val: any) => {
        const { onChange } = this.props;
        const lageropgave = _.find(lageropgaveStore.lageropgaver, l => l.id === Number(val));
        if (lageropgave) {
            lageropgaveStore.setSelectedLageropgave(lageropgave);
        }
        onChange(val);
    }

    render() {
        const { style, disabled } = this.props;
        if (lageropgaveStore.lageropgaver.length < 2) return null;

        const options = lageropgaveStore.lageropgaver.map((o) => ({
            label: o.navn,
            value: o.id
        }))

        return (
            <Dropdown
                disabled={disabled}
                style={style}
                options={options}
                type='number'
                onChange={this.onChange}
                fieldState={this.form.$.id} />
        )
    }
}

export default SelectLageropgave;