import * as React from 'react';
import { observer } from 'mobx-react';
import DetailsRow from 'components/DetailsRow';
import { Table } from 'rsuite';
import { VareInterface, vareStore } from 'stores/VareStore';
const { Column, HeaderCell, Cell } = Table;

interface Props {
    title: string,
    varer: Array<VareInterface>
    adresseId?: number,
    hideSpatialInfo?: boolean,
    priceLabel: string
}

@observer class SelectVarer extends React.Component<Props> {

    onAntalChanged = (id: number, value: number) => {
        const { adresseId } = this.props;
        vareStore.setSelectedId(id, value, adresseId);
    }

    render() {
        const { varer, title, adresseId, hideSpatialInfo, priceLabel } = this.props;
        const selectedIds = vareStore.selectedIds;

        return (
            <DetailsRow label={title} >
                <Table
                    data={varer}
                    rowKey='id'
                    renderEmpty={() => null}
                    autoHeight>

                    <Column flexGrow={1}>
                        <HeaderCell>Type</HeaderCell>
                        <Cell dataKey='navn' />
                    </Column>

                    {
                        !hideSpatialInfo &&
                        <Column width={90}>
                            <HeaderCell>M3</HeaderCell>
                            <Cell dataKey='m3' />
                        </Column>
                    }

                    {
                        !hideSpatialInfo &&
                        <Column width={90}>
                            <HeaderCell>M2</HeaderCell>
                            <Cell dataKey='m2' />
                        </Column>
                    }

                    <Column width={90}>
                        <HeaderCell>{priceLabel}</HeaderCell>
                        <Cell dataKey='udsalgspris' />
                    </Column>

                    <Column width={90} align='right' fixed='right'>
                        <HeaderCell><span style={{ color: '#0096EB', fontWeight: 600 }}>Vælg antal</span></HeaderCell>
                        <Cell>
                            {(rowData: any, rowIndex: any) => {
                                const value = vareStore.getSelectedId(selectedIds, rowData.id, adresseId);

                                return (
                                    <span>
                                        <input
                                            placeholder='0'
                                            value={value ?? ''}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onAntalChanged(rowData.id, Number(e.target.value))}
                                            style={{ width: '30px', textAlign: 'right' }} />
                                    </span>
                                )
                            }}
                        </Cell>
                    </Column>

                </Table>

            </DetailsRow>
        )
    }
}

export default SelectVarer;