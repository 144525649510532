import { observer } from "mobx-react-lite";
import { ProduktInterface, SelectedIdInterface } from "stores/LageropgaveProduktStore";
import { Table } from 'rsuite';
import { bestilUdleveringStore } from 'stores/BestilUdleveringStore';
const { Column, HeaderCell, Cell } = Table;

const BestilProdukterTable = observer(() => {

    const data = bestilUdleveringStore.produkter;

    return (
        <Table
            data={data}
            rowKey='id'
            autoHeight>

            <Column>
                <HeaderCell>Antal</HeaderCell>
                <Cell>
                    {(produkt: ProduktInterface, rowIndex: number) => {
                        return produkt.pluk;
                    }}
                </Cell>
            </Column>

            <Column>
                <HeaderCell>Type</HeaderCell>
                <Cell>
                    {(produkt: ProduktInterface, rowIndex: number) => {
                        return produkt.holderNavn;
                    }}
                </Cell>
            </Column>

            <Column flexGrow={1}>
                <HeaderCell>Identifikation</HeaderCell>
                <Cell>
                    {(produkt: ProduktInterface, rowIndex: number) => {
                        return produkt.identifikation;
                    }}
                </Cell>
            </Column>
        </Table>
    )

})

export default BestilProdukterTable;