import { BaseStore } from 'stores/BaseStore';
import { observable, action, makeObservable } from 'mobx';
import moment from 'moment';
import { KatalogInterface } from './KatalogStore';
import request from 'helpers/api/PrivateApiRequest';

export interface BeholdningInterface {
    "identifikation": string,
    "antal": number,
    "holdertypeId": number,
    "holdertypeNavn": string,
    "katalog"?: KatalogInterface
}

export enum BevaegelseTypeEnum {
    IND = 0,
    UD = 1
}

export interface PaamindelseInterface {
    "id"?: number,
    "lageropgaveId": number,
    "identifikationstekst": string,
    "paamindVedBeholding": number,
    "email": string,
    "deaktiveret"?: true,
    "oprettetDato"?: string,
    "holderTypeId": number
    "katalog": { "id": number } | null
}

export class StatistikStore extends BaseStore {
    @observable beholdning: Array<BeholdningInterface> = [];
    @observable bevaegelserInd: any = {};
    @observable bevaegelserUd: any = {};
    @observable paamindelser: Array<PaamindelseInterface> = [];

    @observable year: number = moment().year();
    @observable lageropgaveId: number | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.beholdning = [];
        this.bevaegelserInd = {};
        this.bevaegelserUd = {};
        this.year = moment().year();
        this.lageropgaveId = null;
        this.paamindelser = [];
    }

    @action
    setLageropgaveId = (id: number | null) => {
        this.lageropgaveId = id;
    }

    @action
    setYear = (value: number) => {
        this.year = value;
    }

    @action
    getBeholdning = () => {
        return this.get(`lageropgaver/${this.lageropgaveId}/statistik/beholdning`, 'beholdning');
    }

    @action
    getBevaegelser = () => {
        return Promise.all([
            this.get(`lageropgaver/${this.lageropgaveId}/statistik/bevaegelser?aarstal=${this.year}&type=${BevaegelseTypeEnum.IND}`, 'bevaegelserInd'),
            this.get(`lageropgaver/${this.lageropgaveId}/statistik/bevaegelser?aarstal=${this.year}&type=${BevaegelseTypeEnum.UD}`, 'bevaegelserUd')
        ])
    }

    @action
    getPaamindelser = () => {
        return this.get(`lageropgaver/${this.lageropgaveId}/paamindelser`, 'paamindelser');
    }

    @action
    createPaamindelse = (paamindelse: PaamindelseInterface) => {
        return this.create(`lageropgaver/${this.lageropgaveId}/paamindelser`, paamindelse, this.paamindelser);
    }

    @action
    deletePaamindelse = (id: number) => {
        return this.delete(`lageropgaver/${this.lageropgaveId}/paamindelser/${id}`, id, this.paamindelser);
    }
}

export const statistikStore = new StatistikStore();