import * as React from 'react';
import { observer } from 'mobx-react';

import css from './style.module.css';

interface Props {
    label: string,
    subLabel?: string,
    children: React.ReactNode,
    alignLabel?: string,
    labelWidth?: string,
    containerMargin?: string
}

@observer class DetailsRow extends React.Component<Props> {

    render() {
        const { label, children, alignLabel, labelWidth, containerMargin, subLabel } = this.props;

        return (
            <div className={css.container} style={{ margin: containerMargin ?? undefined }}>
                <div
                    className={css.label}
                    style={{
                        alignSelf: alignLabel ?? undefined,
                        width: labelWidth ?? undefined
                    }}>
                    {label}

                    {
                        subLabel &&
                        <p className={css.subLabel}>
                            {subLabel}
                        </p>
                    }

                </div>
                <div className={css.content}>
                    {children}
                </div>
            </div>
        )
    }
}

export default DetailsRow;