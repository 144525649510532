import { BaseStore, PagingResultInterface } from "./BaseStore";
import { KontaktpersonInterface } from "./KontaktpersonStore";
import { OpbevaringsholderInterface } from "./LageropgaveStore";
import { AdresseInterface } from "./AdresseStore";
import { observable, action, makeObservable } from "mobx";
import { FileInterface } from "./FileStore";
import { ProduktInterface } from "./LageropgaveProduktStore";
import request from "helpers/api/PrivateApiRequest";

export interface VareInterface {
    "id": number,
    "antal": number,
    "vareId": number,
    "varenavn": string,
    "vareFakturaNavn": string,
    "adresseId"?: number
}

export enum BestillingTypeEnum {
    INDLEVERING,
    UDLEVERING
}

export interface UdleveringInterface {
    "brugerId": number,
    "fil": FileInterface,
    "id": number
    "kundensnavn": string,
    "registreringsnummer": string
}

export interface BestillingInterface {
    "id"?: number,
    "dato"?: string,
    "type": BestillingTypeEnum,
    "indUdAdresse": AdresseInterface,
    "indUdKontakt": KontaktpersonInterface,
    "ordre": {
        "id": string,
        "ordrenavn": string
    },
    "opbevaringsholdere"?: Array<OpbevaringsholderInterface>,
    "produkter"?: Array<ProduktInterface>,
    "varer"?: Array<VareInterface>,
    "status"?: string,
    "tekst": string,
    "filer": Array<FileInterface>,
    "trackAndTrace"?: string,
    "udlevering": UdleveringInterface | null,
    "sporingsNote": string | null,
    "oprettetAf": {
        "id": number,
        "brugernavn": string
    },
    // Shipmondo
    // "shipmondoSmsNotifikation"?: boolean,
    // "shipmondoEmailNotifikation"?: boolean,
    "shipmondoTransportoerKode"?: string,
    "shipmondoProduktKode"?: string,
    "shipmondoPickupPointId"?: string,
    "shipmondoTransportoerInstruks"?: string,
    // "shipmondoTilladIngenKvit"?: boolean,
    "shipmondoProduktServices": Array<string>
}


export class BestillingStore extends BaseStore {
    @observable bestillinger: PagingResultInterface<BestillingInterface> | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.bestillinger = null;
    }

    @action
    getBestillinger = () => {
        return this.getPaged('bestillinger', this.bestillinger, 'bestillinger', 5);
    }

    @action
    createBestilling = (bestilling: BestillingInterface) => {
        return this.create(`bestillinger`, bestilling, this.bestillinger?.results || null);
    }

    @action
    createProduktBestilling = (bestilling: BestillingInterface) => {
        return this.create(`bestillinger/produkter`, bestilling, this.bestillinger?.results || null);
    }

    @action
    deleteBestilling = (bestillingId: number) => {
        return this.delete(`bestillinger/${bestillingId}`, bestillingId, this.bestillinger?.results);
    }
}

export const bestillingStore = new BestillingStore();