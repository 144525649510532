import Dropdown from "components/Forms/Dropdown";
import { FieldState } from "formstate";
import { observer } from "mobx-react";
import React from "react";
import { shipmondoStore } from "stores/PublicApi/ShipmondoStore";

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectCountryCode extends React.Component<Props, any> {

    async componentDidMount() {
        await shipmondoStore.getCountryCodes();
        
        const { fieldState } = this.props;
        if (!fieldState.value) {
            fieldState.onChange(shipmondoStore.countryCodes[0]);
        }
    }

    render() {
        const { fieldState } = this.props;
        const countryCodes = shipmondoStore.countryCodes;

        if (countryCodes.length === 0) return null;

        return (
            <Dropdown
                options={countryCodes.map((cc) => ({
                    value: cc.code,
                    label: cc.name
                }))}
                fieldState={fieldState}
            />
        )
    }
}

export default SelectCountryCode;