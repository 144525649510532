import { BaseStore } from "./BaseStore";
import { observable, action, makeObservable } from "mobx";
import request from "helpers/api/PrivateApiRequest";

export interface KundeIdentifikationInterface {
    identifikation: string,
    holdertypeId: number,
    holdertypeNavn: string
}

export class KundeIdentifikationStore extends BaseStore {
    @observable data: Array<KundeIdentifikationInterface> = [];

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.data = [];
    }

    @action
    getAll(lageropgaveId: number) {
        return this.get(`lageropgaver/${lageropgaveId}/kundeidentifikation`, 'data');
    }
}

export const kundeIdentifikationStore = new KundeIdentifikationStore();