import React from 'react';
import { observer } from 'mobx-react';
import { katalogStore } from 'stores/KatalogStore';
import { FieldState, FormState } from 'formstate';
import Dropdown from 'components/Forms/Dropdown';

interface Props {
    onChange: (value: number | null) => void,
    skipGetData?: boolean
}

@observer class ComponentName extends React.Component<Props> {

    form = new FormState({
        value: new FieldState(null)
    })

    componentDidMount() {
        const { skipGetData } = this.props;
        if (skipGetData) return;
        
        katalogStore.getKataloger();
    }

    render() {
        const kataloger = katalogStore.kataloger || [];
        if (kataloger.length === 0) return null;

        const { onChange } = this.props;
        const opts = kataloger.map((kat) => {
            return {
                value: kat.id!,
                label: kat.navn!
            }
        })

        return (
            <Dropdown
                fieldState={this.form.$.value}
                options={opts}
                type='number'
                placeholder='Alle varer'
                onChange={(val) => onChange(!val || Number(val) === -1 ? null : Number(val))}
            />
        )
    }
}

export default ComponentName;