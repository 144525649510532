import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState, FormState } from 'formstate';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import Button from 'components/Button';
import { isRequired, isValidEmail } from 'helpers/FormValidation';
import { loadingStore } from 'stores/LoadingStore';
import logoSrc from './jdlogistik_logo.png';
import css from './style.module.css';

interface Props {
    onSubmit: (email: string) => void
}

@observer class EmailInput extends React.Component<Props> {

    form = new FormState({
        email: new FieldState('').disableAutoValidation().validators(isRequired, isValidEmail)
    })

    onSubmit = (data: any) => {
        const { onSubmit } = this.props;
        onSubmit(data.email)
    }

    render() {
        const isLoading = loadingStore.isLoading;

        return (
            <div className={css.container}>
                <img className={css.logo} src={logoSrc} alt='JD Logistik' />
                <h1>Digital opbevaring</h1>
                <Form 
                    onSubmit={this.onSubmit} 
                    formState={this.form}>
                    <Input
                        placeholder='Indtast din email'
                        fieldState={this.form.$.email}
                    />
                    <Button
                        disabledLabel={'Øjeblik...'}
                        disabled={isLoading}
                        style={{ marginLeft: '5px' }}
                        type='submit'
                        primary={true}
                        label='Log ind' />
                </Form>
            </div>
        )
    }
}

export default EmailInput;