import Button from "components/Button";
import MyDock, { Row } from "components/Dock";
import DateInput from "components/Forms/DateInput";
import Form from "components/Forms/Form";
import { FieldState, FormState } from "formstate";
import { isRequired } from "helpers/FormValidation";
import moment from "moment";
import { authStore } from "stores/AuthStore";
import { lageropgaveStore } from "stores/LageropgaveStore";

interface Props {
    isVisible: boolean,
    onClose: () => void
}

const DownloadOpbevaringsbevisOverlay = (props: Props) => {

    const form = new FormState({
        fromDate: new FieldState(moment(new Date(), 'DD/MM/YYYY').startOf('month').toISOString(true)).validators(isRequired)
    })

    const lageropgaveId = lageropgaveStore.selectedLageropgave!.id;

    const onSubmit = async () => {
        if (authStore.user?.kunde.skjulBevisPaaWeb) {
            return;
        }

        const from = moment(form.$.fromDate.$).format('YYYY-MM-DD');

        await authStore.getDownloadToken();
        const token = authStore.downloadToken;
        window.open(`
            ${process.env.REACT_APP_API_BASEURL}lageropgaver/${lageropgaveId}/download?UdleveringerFrom=${from}&clientKey=${process.env.REACT_APP_CLIENT_KEY}&token=${token}&type=pdf`
        )

        props.onClose();
    }



    return (
        <MyDock position='right' isVisible={props.isVisible}>
            <h2>
                Hent opbevaringsbevis
            </h2>

            <Form onSubmit={onSubmit} formState={form}>
                <Row label='Fra'>
                    <DateInput
                        allowPastDates={true}
                        fieldState={form.$.fromDate}
                    />
                </Row>
                <div style={{ textAlign: 'center', marginTop: '80px' }}>
                    <Button
                        onClick={() => props.onClose()}
                        label='Fortryd'
                        icon='cross'
                        type='button'
                        style={{ marginRight: '20px' }}
                    />
                    <Button
                        primary={true}
                        label='Hent bevis'
                        icon='cross'
                        type='submit'
                    />
                </div>
            </Form>
        </MyDock>
    )
}

export default DownloadOpbevaringsbevisOverlay;