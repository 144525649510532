import React from 'react';
import { observer } from 'mobx-react';
import logoImg from './logo.svg';
import styles from './style.module.css';
import { HeaderEnum, navigationStore } from 'stores/NavigationStore';
import { authStore } from 'stores/AuthStore';
import Button from 'components/Button';


@observer class Header extends React.Component {

    goto = (endpoint: string) => {
        navigationStore.push(endpoint);
    }

    render() {
        const activeHeader = navigationStore.activeHeader;

        return (
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImg} alt='JD Logistik' onClick={() => navigationStore.push('/')} />
                </div>
                <div className={styles.navigation}>
                    <button onClick={() => this.goto('')} className={`${styles.button} ${styles.mr15} ${activeHeader === HeaderEnum.LAGERLINIER && styles.active}`}>Din opbevaring</button>
                    <button onClick={() => this.goto('/bevaegelser')} className={`${styles.button} ${styles.mr15} ${activeHeader === HeaderEnum.BEVAEGELSER && styles.active}`}>Ordrehistorik</button>
                    <button onClick={() => this.goto('/varekatalog')} className={`${styles.button} ${styles.mr15} ${activeHeader === HeaderEnum.VAREKATALOG && styles.active}`}>Varekatalog</button>
                    <button onClick={() => this.goto('/overblik')} className={`${styles.button} ${styles.mr15} ${activeHeader === HeaderEnum.OVERBLIK && styles.active}`}>Overblik</button>

                </div>
                <div style={{ height: '40px', alignSelf: 'center', marginRight: '20px' }}>
                    <Button primary={true} label='Bestil opbevaring' icon='box' onClick={() => navigationStore.push('/indlevering')} />
                </div>
                <a className={`${styles.button} ${styles.mr15}`} href='https://jdlogistik.dk/docs/Web-lager%20guide.pdf' target='_blank' rel='noreferrer'>
                    Hjælpeguide
                </a>
                <button className={`${styles.button} ${styles.mr15}`} onClick={authStore.logout}>
                    Log ud
                </button>
            </header>
        )
    }
}

export default Header;