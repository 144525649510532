import Dropdown from "components/Forms/Dropdown";
import { FieldState } from "formstate";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { lageropgaveStore } from "stores/LageropgaveStore";
import { shipmondoStore } from "stores/PublicApi/ShipmondoStore";

interface Props {
    fieldState: FieldState<any>,
    countryCode: string
}

const SelectCarrier = observer((props: Props) => {

    useEffect(() => {
        shipmondoStore.getCarriers(lageropgaveStore.selectedLageropgave!.id, props.countryCode);

        return () => {
            props.fieldState.onChange(null);
            shipmondoStore.resetCarriers();
        }
    }, [])

    return (
        <Dropdown
            fieldState={props.fieldState}
            placeholder='Palle Fragt'
            options={shipmondoStore.carriers.map((c) => ({
                label: c.name,
                value: c.code
            }))}
        />
    )
})

export default SelectCarrier;