import * as React from 'react';
import { observer } from 'mobx-react';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, Tooltip, YAxis, Bar } from 'recharts';
import { BeholdningInterface, statistikStore } from 'stores/StatistikStore';
import _ from 'lodash';

interface Props {
    searchQuery: string,
    katalogId: number | null
}

@observer class Bevaegelser extends React.Component<Props> {

    mapBevaegelser = (bevaegelser: any, antalKey: string) => {
        const { searchQuery, katalogId } = this.props;
        const data: Array<any> = [];

        for (var i = 1; i <= 12; i++) {
            const item: any = {};
            item['month'] = this.monthToLabel(i);
            item[antalKey] = 0;
            data.push(item);
        }

        for (const key of Object.keys(bevaegelser)) {
            const k = key.split('_');
            const identifikation = k[0] || '';
            // const holderTypeId = k[1];

            if (searchQuery.length > 0 && identifikation.toLowerCase().indexOf(searchQuery) === -1) {
                continue;
            }

            Object.keys(bevaegelser[key]).forEach((monthKey) => {
                const beholdning = bevaegelser[key][monthKey] as BeholdningInterface;
                if (katalogId && beholdning.katalog?.id !== katalogId) {
                    return;
                }

                const item = _.find(data, { month: this.monthToLabel(Number(monthKey)) });
                if (item) {
                    item[antalKey] += Math.abs(bevaegelser[key][monthKey].antal) // Always positive!! 
                }
            })
        }

        return data;
    }

    monthToLabel = (month: number) => {
        switch (month) {
            case 1: return 'Januar';
            case 2: return 'Februar';
            case 3: return 'Marts';
            case 4: return 'April';
            case 5: return 'Maj';
            case 6: return 'Juni';
            case 7: return 'Juli';
            case 8: return 'August';
            case 9: return 'September';
            case 10: return 'Oktober';
            case 11: return 'November';
            case 12: return 'December';
        }
    }


    render() {
        const bevaegelserInd = statistikStore.bevaegelserInd;
        const bevaegelserUd = statistikStore.bevaegelserUd;

        const data = _.values(_.merge(
            _.keyBy(this.mapBevaegelser(bevaegelserInd, 'ind'), 'month'), 
            _.keyBy(this.mapBevaegelser(bevaegelserUd, 'ud'), 'month')
        ));

        return (
            <div>
                <ResponsiveContainer width='100%' height={280}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray='6 6' stroke='#0096EB' opacity={0.2} />
                        <XAxis dataKey='month' />
                        <Tooltip />
                        <YAxis />
                        <Bar dataKey='ind' fill='#004173' />
                        <Bar dataKey='ud' fill='#0096EB'  />
                    </BarChart>
                </ResponsiveContainer>
            </div>

        )
    }
}

export default Bevaegelser;