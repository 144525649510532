import { privateApiTokenStore } from 'stores/PrivateApiTokenStore';
import { requestFactory } from './RequestFactory';

const baseUrl = process.env.REACT_APP_API_BASEURL!;
const clientKey = process.env.REACT_APP_CLIENT_KEY!;

const request = requestFactory.buildRequest(
    baseUrl,
    clientKey,
    privateApiTokenStore
)

export default request;