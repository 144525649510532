import * as React from 'react';
import { observer } from 'mobx-react';
import UploadFile from 'components/UploadFile';
import { FileInterface } from 'stores/FileStore';

import css from './style.module.css';

interface Props {
    onChange: (files: Array<FileInterface>) => void
}

interface State {
    files: Array<FileInterface>
}

@observer class FileList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            files: []
        }
    }

    removeFile = (fileId: number) => {
        const files = this.state.files;
        const newFiles = files.filter((f) => f.id !== fileId);
        this.setFiles(newFiles);
    }

    onSubmit = (file: any) => {
        const newFiles = [...this.state.files, file];
        this.setFiles(newFiles);
    }

    setFiles = (files: Array<FileInterface>) => {
        const { onChange } = this.props;

        this.setState({
            files: files
        }, () => onChange(files));
    }

    render() {
        const { files } = this.state;

        return (
            <div>
                <ul className={css.FileList}>
                    {files.map((file, index) => (
                        <li key={index}>{file.displaynavn} <button onClick={() => this.removeFile(file.id)} className={css.DeleteButton}>[slet]</button></li>
                    ))}
                </ul>

                <span>
                    <UploadFile onSubmit={this.onSubmit}>
                        <button type='button' className={css.UploadButton}>Vedhæft PDF</button>
                    </UploadFile>
                </span>
            </div>

        )
    }
}

export default FileList;