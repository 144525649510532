import request from "helpers/api/PrivateApiRequest";
import { action, observable, makeObservable } from "mobx";
import { BaseStore } from "./BaseStore";
import { FileInterface } from "./FileStore";


export interface StregkodeInterface {
    "id"?: number,
    "stregkode": string
}

export interface KatalogInterface {
    "id"?: number,
    "varenr"?: string,
    "navn"?: string,
    "beskrivelse"?: string,
    "metaOeko"?: boolean,
    "kundeId"?: number,
    "filer": Array<FileInterface>,
    "stregkoder": Array<StregkodeInterface>
}

export class KatalogStore extends BaseStore {
    @observable kataloger: Array<KatalogInterface> = [];
    @observable katalog: KatalogInterface | null = null;


    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }
    @action
    resetStore = () => {
        this.kataloger = [];
        this.katalog = null;
    }

    @action
    getKataloger = (includeArchived: boolean = false) => {
        return this.get(`katalog?includeArchived=${includeArchived}`, 'kataloger');
    }

    @action
    createKatalog = (katalog: KatalogInterface) => {
        return this.create(`katalog`, katalog, this.kataloger);
    }

    @action
    updateKatalog = (katalogId: string, katalog: KatalogInterface) => {
        return this.update(`katalog/${katalogId}`, katalog, this.kataloger);
    }

    @action
    deleteKatalog = (id: number) => {
        return this.delete(`katalog/${id}`, id, this.kataloger);
    }

    @action
    archiveKatalog = (id: number) => {
        return this.delete(`katalog/${id}/soft`, id, this.kataloger);
    }

    @action
    getKatalog = (id: string) => {
        return this.get(`katalog/${id}`, 'katalog');
    }

    @action
    createStregkode = (katalogId: string, stregkode: string) => {
        return this.create(`katalog/${katalogId}/stregkoder`, { stregkode: stregkode }, this.katalog?.stregkoder);
    }

    @action
    updateStregkode = (katalogId: string, stregkode: StregkodeInterface) => {
        return this.update(`katalog/${katalogId}/stregkoder/${stregkode.id!}`, stregkode, this.katalog?.stregkoder);
    }

    @action
    deleteStregkode = (katalogId: string, stregkodeId: number) => {
        return this.delete(`katalog/${katalogId}/stregkoder/${stregkodeId}`, stregkodeId, this.katalog?.stregkoder);
    }


    @action
    createFile = (katalogId: string, file: FileInterface) => {
        return this.create(`katalog/${katalogId}/filer`, file, this.katalog?.filer);
    }

    @action
    deleteFile = (katalogId: string, fileId: number) => {
        return this.delete(`katalog/${katalogId}/filer/${fileId}`, fileId, this.katalog?.filer);
    }
}

export const katalogStore = new KatalogStore();