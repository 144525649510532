import request from "helpers/api/PublicApiRequest";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";

export enum ProductServiceCodeEnum {
    EARLY_DAY = 'EARLY_DAY',
    END_DAY = 'END_DAY',
    DAY_DELIVERY = 'DAY_DELIVERY',
    EVE_DELIVERY = 'EVE_DELIVERY',
    TIMED_DELIVERY = 'TIMED_DELIVERY',
    MIDDLE_DAY = 'MIDDLE_DAY',
    SATURDAY_DELIVERY = 'SATURDAY_DELIVERY',

    EMAIL_NT = 'EMAIL_NT',
    SMS_NT = 'SMS_NT',
    FLEX = 'FLEX'
}

export interface CountryCodeInterface {
    "name": string,
    "code": string
}

export interface CarrierInterface {
    "code": string,
    "name": string
}

export interface ProductServiceInterface {
    "code": ProductServiceCodeEnum,
    "name": string
}

export interface ProductInterface {
    "code": string,
    "id": number,
    "name": string,
    "available": boolean,
    "own_agreement_available": boolean,
    "customs_declaration_required": boolean,
    "service_point_available": boolean,
    "service_point_required": boolean,
    "required_fields": string,
    "sender_country_code": string,
    "receiver_country_code": string,
    "expected_transit_time": string,
    "required_parcel_fields": string,
    "optional_parcel_fields": string,
    "delivery_location_type": string,
    "carrier": CarrierInterface,
    "available_services": Array<ProductServiceInterface>,
    "required_services": Array<{
        code: string
        id: number,
        name: string,
        note: string
        required_fields: "receiver_email"
    }>,
    "weight_intervals": Array<{
        "from_weight": number,
        "to_weight": number,
        "description": string
    }>
}

export interface PickupPointInterface {
    "address": string,
    "address2": string,
    "agent": string,
    "carrier_code": string,
    "city": string,
    "company_name": string,
    "country": string,
    "distance": string,
    "id": number,
    "in_delivery": boolean,
    "latitude": Number,
    "longitude": Number,
    "name": string,
    "number": Number,
    "opening_hours": Array<string>,
    "out_delivery": boolean,
    "zipcode": number
}

class ShipmondoStore extends BaseStore {

    @observable carriers: Array<CarrierInterface> = [];
    @observable products: Array<ProductInterface> = [];
    @observable pickupPoints: Array<PickupPointInterface> = [];
    @observable countryCodes: Array<CountryCodeInterface> = [];

    constructor() {
        super(request);
        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.resetCarriers();
        this.resetProducts();
        this.resetPickupPoints();
        // this.countryCodes = []; - Skip for now. They must almost be considered static
    }

    @action
    resetProducts = () => {
        this.products = [];
    }

    @action
    resetPickupPoints = () => {
        this.pickupPoints = [];
    }

    @action
    resetCarriers = () => {
        this.carriers = [];
    }

    @action
    getCarriers = (lageropgaveId: number, receiverCountryCode: string) => {
        return this.get(`shipmondo/carriers?inventoryId=${lageropgaveId}&receiverCountryCode=${receiverCountryCode}`, 'carriers');
    }

    @action
    getProducts = (carrierCode: string, countryCode: string) => {
        return this.get(`shipmondo/carriers/${carrierCode}/products?receiverCountryCode=${countryCode}`, 'products');
    }

    @action
    getPickupPoints = (carrierCode: string, countryCode: string, zipCode: string) => {
        return this.get(`shipmondo/carriers/${carrierCode}/pickup-points?receiverCountryCode=${countryCode}&receiverZipCode=${zipCode}`, 'pickupPoints');
    }

    @action
    getCountryCodes = () => {
        return this.get(`shipmondo/country-codes`, 'countryCodes', false);
    }

    checkValidSubmit = (inventoryId: number, addressId: number, carrierCode: string, productCode: string, pickupPointId: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`shipmondo/is-valid`, {
                    inventoryId: inventoryId,
                    addressId: addressId,
                    carrierCode: carrierCode,
                    productCode: productCode,
                    pickupPointId: pickupPointId,
                })

                if (res.status === 200) {
                    resolve(true);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }
}

export const shipmondoStore = new ShipmondoStore();