import * as React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/Button';

import css from './style.module.css';
import { navigationStore } from 'stores/NavigationStore';
import { lageropgaveStore, LagerlinieInterface } from 'stores/LageropgaveStore';
import _ from 'lodash';
import OpbevaringsHolderHelper from 'helpers/OpbevaringsHolderHelper';

@observer class PlukTableAction extends React.Component {

    onNulstil = () => {
        if (window.confirm("Er du sikker på at du vil nulstille de valgte varer?")) {
            lageropgaveStore.resetSelectedIds()
        }
    }

    render() {
        const selectedIds = lageropgaveStore.selectedIds;
        const anyIds = Object.keys(selectedIds).length > 0;

        const holdere = lageropgaveStore.lagerlinier.map((linie: LagerlinieInterface) => linie.opbevaringsholdere).flat();

        return (
            <div style={{ width: '500px' }}>
                <div className={css.container}>
                    {
                        !anyIds &&
                        <div style={{ padding: '20px 0', textAlign: 'center' }}>
                            Der er intet valgt til udlevering.
                        </div>

                    }

                    {
                        anyIds &&
                        <>
                            <div className={css.grid}>
                                <span><strong>Antal</strong></span>
                                <span><strong>Vare</strong></span>
                                <span><strong>Identifikation</strong></span>
                                {
                                    Object.keys(selectedIds).map((key, index) => {
                                        const holder = _.find(holdere, { id: Number(key) });
                                        if (!holder) return null;

                                        // Only paller are the top of the tree.
                                        const linje = _.find(lageropgaveStore.mappedLagerlinier, ml => ml.id === Number(key));
                                        let sum = 1;
                                        if (linje) {
                                            sum = _.sumBy(linje.children, (c: any) => OpbevaringsHolderHelper.holderAntal(c));
                                            if (sum === 0) {
                                                sum = linje.children?.length || 1;
                                            }
                                        }

                                        const uspecificeret = selectedIds[key]?.uspecificeretAntal > 0 || false;
                                        const antal = uspecificeret ? selectedIds[key]?.uspecificeretAntal : selectedIds[key]?.antal;
                                        return (
                                            <React.Fragment key={index}>
                                                <div>
                                                    <span>{antal} {uspecificeret ? 'usp.' : ''}</span>
                                                    {sum > 1 && <span style={{ fontSize: '0.8em' }}> ({sum} stk)</span>}
                                                </div>
                                                <span>{holder.holderNavn}</span>
                                                <span>{OpbevaringsHolderHelper.holderIdentifikation(holder)}</span>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>

                            <div className={css.buttons}>
                                <Button
                                    label='Nulstil'
                                    icon='cross'
                                    onClick={() => this.onNulstil()}
                                    style={{ marginRight: '10px' }}
                                />

                                <Button
                                    label='Udlevér'
                                    icon='arrowUpBox'
                                    primary={anyIds}
                                    onClick={anyIds ? () => navigationStore.push('/udlevering') : undefined} />
                            </div>
                        </>
                    }

                </div>

            </div>
        )
    }
}

export default PlukTableAction;