import * as React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { navigationStore } from 'stores/NavigationStore';
import { FormState, FieldState } from 'formstate';
import Form from 'components/Forms/Form';
import DetailsRow from 'components/DetailsRow';
import SelectOrdre from 'components/SelectOrdre';
import { vareStore } from 'stores/VareStore';
import SelectVarer from './SelectVarer';
import Button from 'components/Button';
import _ from 'lodash';
import { OrdreInterface, ordreStore } from 'stores/OrdreStore';
import { adresseStore } from 'stores/AdresseStore';
import { kontaktpersonStore } from 'stores/KontaktpersonStore';
import { bestillingStore, BestillingTypeEnum } from 'stores/BestillingStore';
import DateInput from 'components/Forms/DateInput';
import SelectKontaktperson from 'components/SelectKontaktperson';
import SelectAdresse from 'components/BestilUdleveringPage/SelectAdresse';
import Input from 'components/Forms/Input';
import { isRequired } from 'helpers/FormValidation';
import { loadingStore } from 'stores/LoadingStore';
import FileList from 'components/FileList';
import { FileInterface } from 'stores/FileStore';
import { getLocalAdresseIdByOrdre } from 'helpers/AdresseHelper';
import SetLocation, { LocationEnum } from 'components/SetLocation';
import { lageropgaveStore } from 'stores/LageropgaveStore';

interface State {
    location: LocationEnum
}

@observer class BestilOpbevaringPage extends React.Component<any, State> {

    state = { location: LocationEnum.JD_LOGISTIK }
    private filesToBeSaved: Array<FileInterface> | null;

    form = new FormState({
        date: new FieldState(''),
        adresse: new FieldState<null | number>(null),
        ordre: new FieldState<null | number>(null).validators(isRequired),
        kontaktperson: new FieldState<null | number>(null),
        tekst: new FieldState<null | string>(null)
    })

    constructor(props: any) {
        super(props);

        this.filesToBeSaved = null;
    }

    onOrdreChanged = (ordreId: number) => {
        vareStore.getVarer(ordreId);
        ordreStore.setSelectedId(ordreId);
    }

    getAdresse = (ordre?: OrdreInterface, adresseId?: number): { id: number } | undefined => {
        const { location } = this.state;
        switch (location) {
            case LocationEnum.JD_LOGISTIK:
                return { id: getLocalAdresseIdByOrdre(ordre) }
            case LocationEnum.DOEGNTERMINAL:
                return { id: Number(process.env.REACT_APP_ADRESSE_DOEGNTERMINAL_ID) }
            case LocationEnum.ANDEN_ADRESSE:
                if (adresseId) {
                    return { id: adresseId }
                }
                else return undefined;
            default:
                return undefined;
        }
    }

    onSubmit = async () => {
        if (Object.keys(vareStore.selectedIds).length === 0) {
            alert('Du skal vælge en eller flere varer.');
            return;
        }

        const form = this.form.$;
        const ordre = _.find(ordreStore.ordrer, { id: form.ordre.$! }) as OrdreInterface;;

        const adresse = this.getAdresse(ordre, form.adresse.$ ? Number(form.adresse.$) : undefined);
        if (!adresse) {
            alert('Adresse er påkrævet. Du mangler at vælge et udleveringssted');
            return;
        }

        await bestillingStore.createBestilling({
            indUdAdresse: adresse,
            indUdKontakt: form.kontaktperson.$
                ? { id: form.kontaktperson.$! }
                : { id: process.env.REACT_APP_DRIFT_KONTAKTPERSON_ID },
            ordre: ordre,
            opbevaringsholdere: [],
            varer: vareStore.getSelectedIds().map((item) => ({
                vareId: item.vareId,
                antal: item.antal,
                adresseId: item.adresseId
            })),
            type: BestillingTypeEnum.INDLEVERING,
            dato: form.date.$.length === 0 ? null : form.date.$,
            tekst: form.tekst.$,
            filer: this.filesToBeSaved || []
        } as any);

        vareStore.resetSelectedIds();
        navigationStore.replace('/indlevering/done');
    }

    onCancel = () => {
        vareStore.resetSelectedIds();
        navigationStore.goBack();
    }

    onFilesChanged = (files: Array<FileInterface>) => {
        this.filesToBeSaved = files;
    }

    onLocationChanged = (location: LocationEnum) => {
        this.setState({ location: location });
    }

    render() {
        const { location } = this.state;
        const form = this.form.$;
        const varer = vareStore.varer || [];
        const isLoading = loadingStore.isLoading;

        const jernholmenId = Number(process.env.REACT_APP_ADRESSE_JERNHOLMEN_ID);
        const roskildeId = Number(process.env.REACT_APP_ADRESSE_ROSKILDE_ID)

        return (
            <div>
                <Breadcrumb
                    onRootClicked={() => navigationStore.push('/')}
                    rootLabel='Aktive lagerlinier'
                    label='Bestil opbevaring'
                />

                <Form onSubmit={this.onSubmit} formState={this.form}>

                    <SelectOrdre
                        title='Vælg ordre til fakturering'
                        onChange={this.onOrdreChanged}
                        fieldState={form.ordre}
                        lageropgaveId={lageropgaveStore.selectedLageropgave!.id}
                    />

                    {
                        (ordreStore.selectedId && varer.length > 0) &&
                        <>
                            <SelectVarer
                                priceLabel='Kr./dag'
                                title='Jernholmen 54, 2650 Hvidovre'
                                adresseId={jernholmenId}
                                varer={varer.filter((v) => !!_.find(v.adresser, { id: jernholmenId }))} />

                            <SelectVarer
                                priceLabel='Kr./dag'
                                title='Øde-Hastrup-Vej 77, 4000 Roskilde'
                                adresseId={roskildeId}
                                varer={varer.filter((v) => !!_.find(v.adresser, { id: roskildeId }))} />

                            <SelectVarer
                                title='Tilkøb'
                                hideSpatialInfo={true}
                                priceLabel='Kr. stk.'
                                varer={varer.filter((v) => v.adresser.length === 0)} />

                            <DetailsRow label='Indleveringssted'>
                                <SetLocation
                                    location={location}
                                    onChange={this.onLocationChanged}
                                />

                                {
                                    location === LocationEnum.ANDEN_ADRESSE &&
                                    <SelectAdresse
                                        fieldState={form.adresse}
                                        isShipmondo={false}
                                    />
                                }
                            </DetailsRow>

                            {
                                (form.adresse.$ !== null && form.adresse.$ !== Number(process.env.REACT_APP_ADRESSE_DOEGNTERMINAL_ID)) &&
                                <DetailsRow
                                    label='Kontaktperson'
                                    subLabel='Ansvarlig på adressen'
                                    alignLabel='center'>
                                    <SelectKontaktperson
                                        fieldState={form.kontaktperson}
                                    />
                                </DetailsRow>
                            }

                            <DetailsRow label='Dato for indlevering' alignLabel='center'>
                                <DateInput
                                    fieldState={form.date}
                                    placeholder='Hurtigst muligt'
                                />
                            </DetailsRow>

                            <DetailsRow label='Bemærkning'>
                                <Input
                                    fieldState={form.tekst}
                                    type='textarea' />
                                <FileList
                                    onChange={this.onFilesChanged}
                                />
                            </DetailsRow>

                        </>
                    }

                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={this.onCancel}
                            label='Fortryd'
                            type='button'
                            icon='cross'
                            style={{ marginRight: '20px', marginTop: '40px' }}
                        />
                        <Button
                            primary={true}
                            label='Bestil opbevaring'
                            icon='arrowDownBox'
                            disabledLabel='Øjeblik...'
                            disabled={isLoading}
                        />
                    </div>

                </Form>
            </div>
        )
    }
}

export default BestilOpbevaringPage;