import request from "helpers/api/PublicApiRequest";
import LoadPromise from "helpers/LoadPromise";
import { action, makeObservable, observable } from "mobx";
import { authStore as privateAuthStore } from "stores/AuthStore";
import { publicApiTokenStore } from "stores/PublicApiTokenStore";

export interface PublicApiKeyInterface {
    "key": string,
    "keyIdentification": string,
    "secret": string,
    "revoked": boolean,
    "expiresAt": string
}

export class AuthStore {

    @observable apiKeys: Array<PublicApiKeyInterface> = [];
 
    constructor() {
        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.apiKeys = [];
    }

    @action
    getToken = () => {
        return LoadPromise(async (resolve, reject) => {
            try {
                const downloadToken = await privateAuthStore.getDownloadToken();

                const res = await request.post(`auth/exchange`, {
                    downloadToken: downloadToken
                })

                publicApiTokenStore.setBearerToken(res.data.bearerToken);
                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    getApiKeys = () => {
        return LoadPromise(async(resolve, reject) => {
            try {
                const res = await request.get(`auth/keys`);
                if (res.status === 200) {
                    this.apiKeys = res.data;
                    resolve(res.data);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    createApiKey = () => {
        return LoadPromise(async(resolve, reject) => {
            try {
                const res = await request.post(`auth/keys`);
                if (res.status === 200) {
                    this.apiKeys.push(res.data);
                    resolve(res.data);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    deleteApiKey = (keyIdentification: string) => {
        return LoadPromise(async(resolve, reject) => {
            try {
                const res = await request.delete(`auth/keys/${keyIdentification}`);
                if (res.status === 204) {
                    this.apiKeys = [];
                    resolve(true);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const authStore = new AuthStore();