import Button from "components/Button";
import { observer } from "mobx-react-lite";
import React from "react";
import { lageropgaveProduktStore } from "stores/LageropgaveProduktStore";
import { navigationStore } from "stores/NavigationStore";
import css from './style.module.css';

const ProdukterTableAction = observer(() => {

    const onNulstil = () => {
        if (window.confirm("Er du sikker på at du vil nulstille de valgte varer?")) {
            lageropgaveProduktStore.resetSelectedIds()
        }
    }

    const selectedIds = lageropgaveProduktStore.selectedIds;
    const anyIds = Object.keys(selectedIds).length > 0;

    return (
        <div style={{ width: '500px' }}>
            <div className={css.container}>
                {
                    !anyIds &&
                    <div style={{ padding: '20px 0', textAlign: 'center' }}>
                        Der er intet valgt til udlevering.
                    </div>

                }

                {
                    anyIds &&
                    <>
                        <div className={css.grid}>
                            <span><strong>Antal</strong></span>
                            <span><strong>Type</strong></span>
                            <span><strong>Vare</strong></span>
                            {
                                Object.keys(selectedIds).map((key, index) => {
                                    const entity = selectedIds[key];
                                    if (!entity) return null;

                                    return (
                                        <React.Fragment key={index}>
                                            <span>{entity.antal}</span>
                                            <span>{entity.produkt.holderNavn}</span>
                                            <span>{entity.produkt.identifikation}</span>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>

                        <div className={css.buttons}>
                            <Button
                                label='Nulstil'
                                icon='cross'
                                onClick={() => onNulstil()}
                                style={{ marginRight: '10px' }}
                            />

                            <Button
                                label='Udlevér'
                                icon='arrowUpBox'
                                primary={anyIds}
                                onClick={anyIds ? () => navigationStore.push('/udlevering/produkter') : undefined} />
                        </div>
                    </>
                }

            </div>

        </div>
    )

})

export default ProdukterTableAction;