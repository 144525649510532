import Input from "components/Forms/Input";
import { FieldState } from "formstate";
import { useEffect } from "react";

interface Props {
    fieldState: FieldState<any>
}

const TransportInstructions = (props: Props) => {

    useEffect(() => {
        return () => {
            props.fieldState.onChange(null);
        }
    }, [])

    return (
            <Input
                placeholder="Max 50 karakterer"
                fieldState={props.fieldState}
                maxLength={50}
            />
    )
}

export default TransportInstructions;