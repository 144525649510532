import request from "helpers/api/PublicApiRequest";
import LoadPromise from "helpers/LoadPromise";
import { action, makeObservable, observable } from "mobx";

export interface InventoryInterface {
    "id": number,
    "createdOn": string,
    "createdById": number,
    "modifiedOn": string,
    "modifiedById": number,
    "food": true,
    "email": string,
    "name": string,
    "shopifyEnabled": boolean,
    "billingInterval": number,
    "orders": [
        {
            "id": number,
            "createdOn": string,
            "createdById": number,
            "modifiedOn": string,
            "modifiedById": number,
            "name": string
        }
    ]
}

export class InventoryStore {

    @observable inventories: Array<InventoryInterface> = [];

    constructor() {
        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.inventories = [];
    }

    @action
    getInventories = () => {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await request.get(`inventories`);
                if (res.status === 200) {
                    this.inventories = res.data;
                    resolve(res.data);
                } else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const inventoryStore = new InventoryStore();