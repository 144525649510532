import { OrdreInterface } from "stores/OrdreStore";

export const getLocalAdresseIdByOrdre = (ordre?: OrdreInterface) => {
    if (ordre?.roskilde === true) {
        return Number(process.env.REACT_APP_ADRESSE_ROSKILDE_ID);
    }

    if (ordre?.glostrup === true) {
        return Number(process.env.REACT_APP_ADRESSE_GLOSTRUP_ID);
    }

    if (ordre?.koege === true) {
        return Number(process.env.REACT_APP_ADRESSE_KOEGE_ID);
    }

    return Number(process.env.REACT_APP_ADRESSE_JERNHOLMEN_ID);

}