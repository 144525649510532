import * as React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import { authStore } from 'stores/AuthStore';

interface Props {
    onSubmit: () => void,
    onTermsDeclined: () => void
}

interface State {
    selected: boolean
}

@observer class AcceptTerms extends React.Component<Props, State> {

    state = { selected: false }

    onSubmit = () => {
        const { onSubmit } = this.props;
        onSubmit();
    }

    onChange = () => {
        this.setState({ selected: !this.state.selected });
    }

    getTerms = () => {
        const kunde = authStore.user!.kunde;

        if (kunde.spedition) {
            return <span>Ved at benytte platformen accepterer jeg <a href="terms/NSAB_2015_DK_version.pdf" target='_blank'>Nordisk Speditørforbunds Almindelige Bestemmelser (NSAB)</a> og <a href="terms/CMR-loven.pdf" target='_blank'>CMR-loven</a>.</span>
        }
        else {
            if (kunde.erErhverv) {
                return <span>Ved at benytte platformen accepterer jeg <a href="terms/DMF-Betingelser-Erhverv.pdf">Betingelser Vedrørende Erhvervsflytninger og Opbevaringsaftaler</a>.</span>
            }
            else {
                return <span>Ved at benytte platformen accepterer jeg <a href="terms/DMF-Betingelser-Privat.pdf">Betingelser Vedrørende Privatflytninger og Opbevaringsaftaler</a>.</span>
            }
        }
    }

    render() {
        const { onTermsDeclined } = this.props;
        const { selected } = this.state;

        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ marginBottom: '20px' }}>
                        Accepter vilkår for brug.
                    </h1>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='checkbox'
                            onChange={this.onChange} />
                        {this.getTerms()}
                    </label>

                </div>

                <Button
                    disabled={!selected}
                    style={{ marginTop: '30px' }}
                    label='Log ind'
                    primary={true}
                    onClick={this.onSubmit} />

                <div style={{ marginTop: '80px', textAlign: 'center' }}>
                    <button onClick={onTermsDeclined} style={{ color: '#b9b9b9', textDecoration: 'underline' }}>Jeg accepterer ikke</button>
                </div>


            </div>
        )
    }
}

export default AcceptTerms;