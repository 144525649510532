import React from 'react';
import { observer } from 'mobx-react';
// import { navigationStore } from 'stores/NavigationStore';
import EmailInput from 'components/LoginPage/EmailInput';
import CodeInput from 'components/LoginPage/CodeInput';
import { authStore } from 'stores/AuthStore';
import { navigationStore } from 'stores/NavigationStore';
import { FormState, FieldState } from 'formstate';

import css from './style.module.css';
import AcceptTerms from './AcceptTerms';

enum StepEnum {
    EMAIL,
    PINCODE,
    TERMS
}

interface State {
    email: string | null,
    step: StepEnum
}

@observer class LoginPage extends React.Component<any, State> {

    form = new FormState({
        email: new FieldState(''),
        pin: new FieldState('')
    })

    constructor(props: any) {
        super(props);

        this.state = {
            email: null,
            step: StepEnum.EMAIL
        }
    }

    componentDidMount() {
        if (authStore.isAuthenticated) {
            navigationStore.replace('/');
        }
    }

    onValidEmailSubmitted = async (email: string) => {
        try {
            await authStore.sendPinCodeToEmail(email);
            this.setState({ email: email, step: StepEnum.PINCODE });
        }
        catch (error) {

        }
    }

    onValidPinCodeSubmitted = async (pinCode: number) => {
        await authStore.getToken(this.state.email!, pinCode);
        await authStore.getMe();

        if (!authStore.user!.betingelser) {
            this.setState({ step: StepEnum.TERMS });
        }
        else {
            navigationStore.replace('/');
        }
    }

    onPinCodeDeclined = () => {
        this.setState({ email: null, step: StepEnum.EMAIL });
    }

    onTermsAccepted = async () => {
        const user = authStore.user;
        user!.betingelser = true;
        await authStore.updateUser(user!);
        navigationStore.replace('/');
    }

    onTermsDeclined = async () => {
        authStore.logout();
        this.setState({ email: null, step: StepEnum.EMAIL });
    }

    render() {
        const { email, step } = this.state;

        return (
            <div>
                <div className={css.container}>

                    {
                        step === StepEnum.EMAIL &&
                        <EmailInput onSubmit={this.onValidEmailSubmitted} />
                    }

                    {
                        step === StepEnum.PINCODE &&
                        <CodeInput
                            email={email!}
                            onValidSubmit={this.onValidPinCodeSubmitted}
                            onBackClicked={this.onPinCodeDeclined}
                        />
                    }

                    {
                        step === StepEnum.TERMS &&
                        <AcceptTerms
                            onSubmit={this.onTermsAccepted}
                            onTermsDeclined={this.onTermsDeclined}
                        />
                    }

                </div>
            </div>
        )
    }
}

export default LoginPage;