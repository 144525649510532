import * as React from 'react';
import { observer } from 'mobx-react';
import { lageropgaveStore } from 'stores/LageropgaveStore';
import SelectLageropgave from 'components/SelectLageropgave';
import { statistikStore } from 'stores/StatistikStore';
import Bevaegelser from './Bevaegelser';
import Beholdning from './Beholdning';
import SearchInput from 'components/SearchInput';
import moment from 'moment';
import SelectYear from './SelectYear';
import css from './style.module.css';
import Paamindelser from './Paamindelser';
import CreatePaamindelse from './Paamindelser/CreatePaamindelse';
import SelectKatalog from 'components/SelectKatalog';

interface State {
    searchQuery: string,
    year: number,
    selectedKatalogId: number | null
}

@observer class OverblikPage extends React.Component<any, State> {

    state = {
        searchQuery: '',
        year: moment().year(),
        selectedKatalogId: null
    }

    async componentDidMount() {
        this.getStats(lageropgaveStore.selectedLageropgave!.id);
    }

    componentWillUnmount() {
        statistikStore.setYear(moment().year());
    }

    getStats = (lageropgaveId: number) => {
        statistikStore.setLageropgaveId(lageropgaveId);
        statistikStore.getBeholdning();
        statistikStore.getBevaegelser();
        statistikStore.getPaamindelser();
    }

    onSearchQueryChanged = (value: string) => {
        this.setState({ searchQuery: value.toLowerCase() });
    }

    onYearChanged = (value: number | string) => {
        statistikStore.setYear(Number(value));
        statistikStore.getBevaegelser();
    }

    onKatalogChanged = (value: number | null) => {
        this.setState({ selectedKatalogId: value });
    }

    render() {
        const { searchQuery, selectedKatalogId } = this.state;

        return (
            <div className={css.overblik}>
                <div style={{ paddingTop: '35px', paddingBottom: '15px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            {
                                !selectedKatalogId &&
                                <SearchInput onChange={this.onSearchQueryChanged} />
                            }

                            {
                                !searchQuery &&
                                <div style={{ marginTop: '10px' }}>
                                    <SelectKatalog onChange={this.onKatalogChanged} />
                                </div>
                            }

                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <SelectLageropgave onChange={this.getStats} />
                        </div>
                    </div>

                </div>

                <h2>
                    Aktuel beholdning
                </h2>
                <Beholdning searchQuery={searchQuery} katalogId={selectedKatalogId} />

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <div style={{ flex: 1 }}>
                        <h2>
                            Bevægelser {statistikStore.year}
                        </h2>
                    </div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                        <SelectYear onChange={this.onYearChanged} />
                    </div>
                </div>

                <Bevaegelser searchQuery={searchQuery} katalogId={selectedKatalogId} />

                {
                    searchQuery.length === 0 &&
                    <>
                        <div style={{ display: 'flex', alignItems: 'baseline', paddingTop: '40px' }}>
                            <div style={{ flex: 1 }}>
                                <h2>
                                    Påmindelser
                                </h2>
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <CreatePaamindelse />
                            </div>
                        </div>
                        <Paamindelser />
                    </>
                }

            </div>
        )
    }
}

export default OverblikPage;