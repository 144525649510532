import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import _ from 'lodash';
import { kundeIdentifikationStore } from 'stores/KundeIdentifikationStore';
import { statistikStore } from 'stores/StatistikStore';

interface Props {
    fieldState: FieldState<any>,
    lageropgaveId?: number,
    holderType: FieldState<number | null>
}

@observer class SelectIdentifikation extends React.Component<Props> {

    async componentWillReceiveProps(nextProps: Props) {
        if (nextProps.lageropgaveId) {
            await kundeIdentifikationStore.getAll(statistikStore.lageropgaveId!);
        }
    }

    render() {
        const { fieldState, holderType } = this.props;
        const options: Array<any> = [];

        kundeIdentifikationStore.data.forEach((h) => {
            if (holderType.$ && Number(holderType.$) === Number(h.holdertypeId)) {
                options.push({
                    label: h.identifikation,
                    value: h.identifikation
                })
            }
        })

        return (
            <Dropdown
                placeholder='Vælg identifikation'
                options={_.orderBy(options)}
                fieldState={fieldState} />
        )
    }
}

export default SelectIdentifikation;