import React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

export enum SelectTypeEnum {
    ALLE = -1,
    IND = 0,
    UD = 1
}

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectType extends React.Component<Props> {

    render() {
        const { fieldState } = this.props;

        return (
            <div>
                <div>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='radio'
                            checked={fieldState.value === SelectTypeEnum.ALLE}
                            onChange={() => fieldState.onChange(SelectTypeEnum.ALLE)}
                        />
                        Alle
                    </label>
                </div>

                <div style={{ marginTop: '8px' }}>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='radio'
                            checked={fieldState.value === SelectTypeEnum.IND}
                            onChange={() => fieldState.onChange(SelectTypeEnum.IND)}
                        />
                        Indleveringer
                    </label>
                </div>

                <div style={{ marginTop: '8px' }}>
                    <label>
                        <input
                            style={{ marginRight: '8px' }}
                            type='radio'
                            checked={fieldState.value === SelectTypeEnum.UD}
                            onChange={() => fieldState.onChange(SelectTypeEnum.UD)}
                        />
                        Udleveringer
                    </label>
                </div>
            </div>
        )
    }
}

export default SelectType;