import MyDock, { Row } from "components/Dock";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { FieldState, FormState } from "formstate";
import { observer } from "mobx-react";
import React from "react";
import Button from "components/Button";
import { InventoryShopifyDetailsInterface, inventoryShopifyDetailsStore } from "stores/PublicApi/InventoryShopifyDetailsStore";
import { isRequired } from "helpers/FormValidation";
import { toastStore } from "stores/ToastStore";

export enum SelectedInventoryOverlayActionEnum {
    UPDATE,
    CREATE
}

interface Props {
    inventoryId?: number | null,
    onClose: () => void
    action: SelectedInventoryOverlayActionEnum
}

@observer class SelectedInventoryOverlay extends React.Component<Props> {

    form = new FormState({
        shopUrl: new FieldState('').validators(isRequired),
        token: new FieldState('').validators(isRequired),
        appSecret: new FieldState('').validators(isRequired),
        shipmondoUsername: new FieldState('').validators(isRequired),
        shipmondoPassword: new FieldState('').validators(isRequired),
        shipmondoDeactivateTestMode: new FieldState(true).validators(isRequired)
    })

    async componentDidMount() {
        const { inventoryId, action } = this.props;
        if (inventoryId && action === SelectedInventoryOverlayActionEnum.UPDATE) {
            const details = await inventoryShopifyDetailsStore.getDetails(inventoryId) as InventoryShopifyDetailsInterface;
            const form = this.form.$;

            form.shopUrl.reset(details.shopUrl);
            form.token.reset(details.token);
            form.appSecret.reset(details.appSecret);
            form.shipmondoUsername.reset(details.shipmondoUsername);
            form.shipmondoPassword.reset(details.shipmondoPassword);
            form.shipmondoDeactivateTestMode.reset(details.shipmondoDeactivateTestMode);
        }
    }

    componentWillUnmount() {
        inventoryShopifyDetailsStore.resetStore();
    }

    onSubmit = async () => {
        const { inventoryId, onClose } = this.props;
        const form = this.form.$;

        const details = {
            inventoryId: inventoryId,
            shopUrl: form.shopUrl.$,
            token: form.token.$,
            appSecret: form.appSecret.$,
            shipmondoUsername: form.shipmondoUsername.$,
            shipmondoPassword: form.shipmondoPassword.$,
            shipmondoDeactivateTestMode: form.shipmondoDeactivateTestMode.$
        } as InventoryShopifyDetailsInterface

        try {
            await inventoryShopifyDetailsStore.createDetails(details)
            onClose();
        }
        catch (error) {
            toastStore.addError('Der kunne ikke oprettes forbindelse. Tjek at alle felter er indtastet rigtigt igen.')
        }

    }

    onDelete = async () => {
        const { inventoryId, onClose } = this.props;
        if (!window.confirm('Er du sikker på at du vil slette denne integration?')) return;

        await inventoryShopifyDetailsStore.deleteDetails(inventoryId!);
        onClose();
    }

    render() {
        const { inventoryId, onClose, action } = this.props;
        const form = this.form.$;
        const details = inventoryShopifyDetailsStore.details;
        const disabled = action === SelectedInventoryOverlayActionEnum.UPDATE;

        return (
            <MyDock position='right' isVisible={true}>
                <h2>
                    Shopify
                </h2>
                <Form onSubmit={this.onSubmit} formState={this.form}>
                    <Row label='Shop URL'>
                        <Input
                            disabled={disabled}
                            fieldState={form.shopUrl}
                        />
                    </Row>
                    <Row label='Token'>
                        <Input
                            disabled={disabled}
                            fieldState={form.token}
                        />
                    </Row>
                    <Row label='App Secret'>
                        <Input
                            disabled={disabled}
                            fieldState={form.appSecret}
                        />
                    </Row>
                    <Row label='Shipmondo username'>
                        <Input
                            disabled={disabled}
                            fieldState={form.shipmondoUsername}
                        />
                    </Row>
                    <Row label='Shipmondo password'>
                        <Input
                            disabled={disabled}
                            fieldState={form.shipmondoPassword}
                        />
                    </Row>

                    <div style={{ textAlign: 'center' }}>
                        {
                            action === SelectedInventoryOverlayActionEnum.CREATE &&
                            <Button
                                label='Gem'
                                primary={true}
                                icon='arrowDownBox'
                                type='submit'
                                style={{ marginTop: '100px', marginRight: '20px' }}
                            />
                        }

                        {
                            action === SelectedInventoryOverlayActionEnum.UPDATE &&
                            <Button
                                label='Fjern integration'
                                icon='cross'
                                type='button'
                                onClick={() => this.onDelete()}
                                style={{ marginTop: '100px', marginRight: '20px', background: 'red' }}
                            />
                        }


                        <Button
                            onClick={() => onClose()}
                            label='Luk'
                            icon='cross'
                            type='button'
                            style={{ marginTop: '100px' }}
                        />
                    </div>
                </Form>

            </MyDock>
        )
    }

}

export default SelectedInventoryOverlay;