import * as React from 'react';
import { observer } from 'mobx-react';
import DoneDescription from 'components/DoneDescription';

@observer class IndleveringDonePage extends React.Component {

    render() {
        return (
            <DoneDescription />
        )
    }
}

export default IndleveringDonePage;