import React from 'react';
import { observer } from 'mobx-react';

import css from './style.module.css';

interface Props {
    label: string,
    primary?: boolean
    style?: React.CSSProperties,
    icon?: string
    onClick?: () => void,
    type?: 'button' | 'submit' | 'reset',
    autofocus?: boolean,
    disabled?: boolean,
    disabledLabel?: string
}

@observer class Button extends React.Component<Props> {

    getIcon = () => {
        const { icon } = this.props;

        let iconClass = null;

        switch (icon) {
            case 'box':
                iconClass = css.box;
                break;
            case 'arrowDown':
                iconClass = css.arrowDown;
                break;
            case 'arrowDownBox':
                iconClass = css.arrowDownBox;
                break;
            case 'arrowUpBox':
                iconClass = css.arrowUpBox;
                break;
            case 'cross':
                iconClass = css.cross;
                break;
        }

        return icon && <span className={`${css.icon} ${iconClass}`} />;
    }

    render() {
        const { label, type, primary, style, onClick, autofocus, disabled, disabledLabel } = this.props;

        return (
            <button
                disabled={disabled}
                autoFocus={autofocus}
                type={type}
                onClick={onClick}
                style={style}
                className={`${css.button} ${primary && !disabled ? css.primary : null}`}>
                {this.getIcon()}
                {disabled && disabledLabel ? disabledLabel : label}
            </button>
        )
    }
}

export default Button;