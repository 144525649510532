import { action, observable, computed, makeObservable } from 'mobx';

export class LoadingStore {
    @observable apiRequests: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    addRequest() {
        this.apiRequests++;
    }

    @action
    removeRequest() {
        this.apiRequests--;
        if (this.apiRequests < 0) this.apiRequests = 0;
    }

    @computed
    get isLoading() {
        return this.apiRequests > 0;
    }
}

export const loadingStore = new LoadingStore();