import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AdresseInterface, adresseStore } from 'stores/AdresseStore';
import { Table } from 'rsuite';
import _ from 'lodash';
import { FieldState } from 'formstate';
import SelectableCell from 'components/SelectableCell';
import CreateAdresse from 'components/CreateAdresse';
import SearchInput from 'components/SearchInput';
const { Column, HeaderCell } = Table;

interface Props {
    fieldState: FieldState<any>,
    isShipmondo?: boolean
}

const SelectAdresse = observer((props: Props) => {
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        adresseStore.getAdresser();

        return () => {
            adresseStore.setSelectedAdresse(null);
        }
    }, [])

    const onChange = (adresse: AdresseInterface | null) => {
        const { fieldState } = props;

        setSearchQuery('');
        adresseStore.setSelectedAdresse(adresse);
        fieldState.onChange(adresse?.id ?? null);
    }

    const filteredAddresses = () => {
        if (searchQuery.length < 2) return [];
        let adresser = adresseStore.adresser;

        // If shipmondo, only show addresses that have country codes, as they are required
        if (props.isShipmondo) {
            adresser = _.filter(adresser, a => !!a.landekode);
        }

        if (searchQuery) {
            adresser = adresser.filter((a) =>
                (a.navn && a.navn.toLowerCase().indexOf(searchQuery) !== -1) ||
                (a.adresseLinie && a.adresseLinie.toLowerCase().indexOf(searchQuery) !== -1) ||
                (a.postnummer && a.postnummer.toString().indexOf(searchQuery) !== -1) ||
                (a.by && a.by.toLowerCase().indexOf(searchQuery) !== -1)
            );
        }

        return adresser;
    }

    const onAdresseCreated = (adresse: AdresseInterface) => {
        adresseStore.setSelectedAdresse(adresse);
        setSearchQuery('');
    }

    const selectedAdresse = adresseStore.selectedAdresse;
    const adresser = filteredAddresses();

    if (selectedAdresse) {
        return (
            <>
                <div>
                    <b>{selectedAdresse.navn}</b><br />
                    {selectedAdresse.adresseLinie}<br />
                    {selectedAdresse.postnummer} {selectedAdresse.by}
                </div>
                <button
                    type='button'
                    onClick={() => adresseStore.setSelectedAdresse(null)}
                    style={{ padding: 0, textDecoration: 'underline', background: 'transparent', marginTop: '8px' }}
                >
                    Fjern
                </button>
            </>
        )
    }

    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <SearchInput
                    placeholder='Søg på adresse...'
                    onChange={value => setSearchQuery(value.toLowerCase())}
                />
            </div>

            {
                adresser.length > 0 &&
                <div>
                    <p style={{ paddingTop: '8px', paddingBottom: '8px', fontWeight: 600 }}>
                        Vi fandt disse adresser du har bestilt til før. Klik på en for at vælge:
                    </p>
                    <Table
                        onRowClick={(adresse: AdresseInterface) => onChange(adresse)}
                        data={adresser.slice()}
                        rowKey='id'
                        renderEmpty={() => null}
                        autoHeight>

                        <Column flexGrow={1}>
                            <HeaderCell>Navn</HeaderCell>
                            <SelectableCell dataKey='navn' />
                        </Column>

                        <Column flexGrow={1}>
                            <HeaderCell>Adresse</HeaderCell>
                            <SelectableCell>
                                {(rowData: any, rowIndex: any) => {
                                    return `${rowData.adresseLinie}, ${rowData.postnummer} ${rowData.by}`;
                                }}
                            </SelectableCell>
                        </Column>

                        <Column width={60}>
                            <HeaderCell></HeaderCell>
                            <SelectableCell>
                                {(rowData: any, rowIndex: any) => {
                                    const archiveAddress = async (e: React.MouseEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (window.confirm('Er du sikker på at du vil arkivere denne adresse?')) {
                                            adresseStore.setSelectedAdresse(null);
                                            await adresseStore.deleteAdresse(rowData.id);
                                        }
                                    }

                                    const btnStyle = {
                                        display: 'block',
                                        color: '#d66161',
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                        marginLeft: '0',
                                        padding: '5px',
                                        background: 'white',
                                        marginTop: '5px'
                                    };

                                    return (
                                        <div>
                                            <button
                                                style={btnStyle}
                                                onClick={archiveAddress}>[Slet]</button>
                                        </div>
                                    )


                                }}
                            </SelectableCell>
                        </Column>

                    </Table>
                </div>
            }

            {
                searchQuery.length > 1 &&
                <p style={{ paddingTop: '14px', fontWeight: 600 }}>
                    {
                        adresser.length === 0 &&
                        <span>Det ser ikke ud som om at du har bestilt til denne adresse før. </span>
                    }

                    {
                        adresser.length > 0 &&
                        <span>Ikke på listen? </span>
                    }
                    <CreateAdresse
                        onAdresseCreated={onAdresseCreated}
                        fieldState={props.fieldState}
                        label='Klik her for at oprette ny adresse'
                    />
                </p>
            }

        </>
    )
})

export default SelectAdresse;