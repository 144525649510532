import * as React from 'react';
import { observer } from 'mobx-react';
import ReactCodeInput from 'react-code-input';

import './style.css';
import Button from 'components/Button';
import { loadingStore } from 'stores/LoadingStore';

interface State {
    pinCode: number | null
}

interface Props {
    onValidSubmit: (pinCode: number) => void,
    onBackClicked: () => void,
    email: string
}

@observer class CodeInput extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            pinCode: null
        }
    }

    onChange = (pinCode: number) => {
        this.setState({ pinCode: pinCode });
    }

    onSubmit = () => {
        const { onValidSubmit } = this.props;
        const { pinCode } = this.state;

        onValidSubmit(pinCode!);
    }

    onBackClicked = (e: React.MouseEvent) => {
        e.preventDefault();

        this.props.onBackClicked();
    }

    render() {
        const { email } = this.props;
        const { pinCode } = this.state;
        const isLoading = loadingStore.isLoading;

        return (
            <div>
                <p style={{ marginBottom: '20px' }}>
                    Vi har sendt en 4-cifret kode til <b>{email}</b>. Koden kan kun bruges én gang.
                </p>

                <div style={{ textAlign: 'center' }}>
                    <ReactCodeInput
                        type='number'
                        fields={4}
                        onChange={this.onChange}
                        inputStyle={{
                            textAlign: 'center',
                            width: '96px',
                            height: '120px',
                            fontSize: '50px',
                            margin: '4px',
                            borderRadius: '3px',
                            border: '3px solid rgb(223, 225, 230)'
                        }}
                    />
                </div>

                {
                    (pinCode && pinCode!.toString().length === 4) &&
                    <Button
                        disabled={isLoading}
                        disabledLabel={'Øjeblik...'}
                        autofocus={true}
                        style={{ marginTop: '30px' }}
                        label='Fortsæt'
                        primary={true}
                        onClick={this.onSubmit} />

                }

                <div style={{ marginTop: '80px', textAlign: 'center' }}>
                    <button onClick={this.onBackClicked} style={{ color: '#b9b9b9', textDecoration: 'underline' }}>Tilbage til forside</button>
                </div>


            </div>
        )
    }
}

export default CodeInput;