import * as React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import MyDock, { Row } from 'components/Dock';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import SelectHoldertype from './SelectHoldertype';
import { isRequired } from 'helpers/FormValidation';
import { statistikStore } from 'stores/StatistikStore';
import SelectIdentifikation from './SelectIdentifikation';
import SelectKatalog from 'components/SelectKatalog';

interface State {
    visible: boolean
}

@observer class CreatePaamindelse extends React.Component<any, State> {

    form = new FormState({
        email: new FieldState('').validators(isRequired),
        holderType: new FieldState<number | null>(null).validators(isRequired),
        paamindVedBeholding: new FieldState<number | null>(null).validators(isRequired),
        identifikationstekst: new FieldState(''),
        katalogId: new FieldState<number | null>(null)
    })

    state = { visible: false }

    onSubmit = async () => {
        const form = this.form.$;

        if (!form.identifikationstekst.$ && !form.katalogId.$) {
            alert('Identifikationstekst eller vare skal være valgt');
            return;
        }

        if (form.identifikationstekst.$ && form.katalogId.$) {
            alert('Du skal vælge enten identifikationstekst eller vare');
            return;
        }

        await statistikStore.createPaamindelse({
            lageropgaveId: Number(statistikStore.lageropgaveId),
            identifikationstekst: form.identifikationstekst.$,
            paamindVedBeholding: Number(form.paamindVedBeholding.$),
            email: form.email.$,
            holderTypeId: Number(form.holderType.$),
            katalog: form.katalogId.$ ? { id: form.katalogId.$ } : null
        });

        await statistikStore.getPaamindelser();
        this.setState({ visible: false });
        
    }

    onKatalogChanged = (value: number | null) => {
        this.form.$.katalogId.onChange(value);
    }

    render() {
        const lageropgaveId = statistikStore.lageropgaveId;
        const { visible } = this.state;
        const form = this.form.$;

        return (
            <div>
                <Button
                    type='button'
                    onClick={() => this.setState({ visible: true })}
                    label='Ny påmindelse'
                    primary={true}
                />
                <MyDock position='right' isVisible={visible}>
                    <h2>
                        Opret påmindelse
                    </h2>

                    <Form onSubmit={this.onSubmit} formState={this.form}>
                        <Row label='Email'>
                            <Input fieldState={form.email} />
                        </Row>

                        <Row label='Holdertype'>
                            <SelectHoldertype fieldState={form.holderType} />
                        </Row>

                        <Row label='Påmind ved (antal)'>
                            <Input fieldState={form.paamindVedBeholding} type='number' />
                        </Row>

                        <Row label='Identifikation'>
                            <SelectIdentifikation
                                fieldState={form.identifikationstekst}
                                holderType={form.holderType}
                                lageropgaveId={lageropgaveId ?? undefined}
                            />

                            <div style={{ marginTop: '10px' }}>
                                <SelectKatalog onChange={this.onKatalogChanged} skipGetData={true} />
                            </div>
                        </Row>

                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => this.setState({ visible: false })}
                                label='Fortryd'
                                icon='cross'
                                type='button'
                                style={{ marginRight: '20px', marginTop: '100px' }}
                            />
                            <Button
                                primary={true}
                                label='Opret påmindelse'
                                icon='arrowDownBox'
                            />
                        </div>
                    </Form>
                </MyDock>
            </div>
        )
    }
}

export default CreatePaamindelse;