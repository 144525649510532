export const isRequired = (value: any) => {
    const error = 'Påkrævet';
    if (value == null || (typeof value === 'string' && !value.trim())) {
        return error;
    }
    return null;
}

export const isValidEmail = (value: string) => {
    // Empty values are not invalid emails
    if (isRequired(value)) return null;
    value = value.trim();
    // Src : http://emailregex.com/
    if (!checkEmailValidityWithRegEx(value)) {
        return 'Ugyldig email adresse';
    }
    return null;
}

export const checkEmailValidityWithRegEx = (email: string) => {
    return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(email));
}

export const checkPhoneNumberValidityWithRegEx = (phoneNumber: string) => {
    // Regex that allows for numbers only, along with the "+" symbol, and requires a minimum of 8 digits
    return (/^(?=.*\d{8})[+\d]+$/g.exec(phoneNumber))
}

export const isValidPhoneNUmber = (value: string) => {
    if (isRequired(value)) return null;

    value = value.trim();

    if (!checkPhoneNumberValidityWithRegEx(value)) {
        return 'Ugyldigt telefonnummer';
    }
    return null;
}