import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState, FormState } from 'formstate';
import { kontaktpersonStore } from 'stores/KontaktpersonStore';
import _ from 'lodash';
import MyDock, { Row } from 'components/Dock';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Button from 'components/Button';
import { isRequired, isValidEmail, isValidPhoneNUmber } from 'helpers/FormValidation';

interface Props {
    fieldState: FieldState<any>
}

interface State {
    showOverlay: boolean
}

@observer class SelectKontaktperson extends React.Component<Props, State> {

    form = new FormState({
        navn: new FieldState('').validators(isRequired),
        email: new FieldState('').validators(isValidEmail).disableAutoValidation(),
        telefonDirekte: new FieldState('').validators(isValidPhoneNUmber),
        telefonMobil: new FieldState('').validators(isValidPhoneNUmber),
    })

    state = { showOverlay: false };

    componentDidMount() {
        this.props.fieldState.validators(isRequired);
        kontaktpersonStore.getKontaktpersoner();
    }

    componentWillUnmount() {
        this.props.fieldState.validators();
        kontaktpersonStore.setSelectedId(null);
    }

    onChange = (id: number | null) => {
        const { fieldState } = this.props;

        kontaktpersonStore.setSelectedId(id);
        fieldState.onChange(id);
    }

    createKontaktperson = async () => {
        const { fieldState } = this.props;
        const form = this.form.$;

        const res: any = await kontaktpersonStore.createKontaktperson({
            navn: form.navn.$,
            email: form.email.$,
            telefonDirekte: form.telefonDirekte.$,
            telefonMobil: form.telefonMobil.$,
        })

        fieldState.onChange(res.id);
        kontaktpersonStore.setSelectedId(res.id);

        this.setState({ showOverlay: false });
    }

    updateKontaktperson = async () => {
        const { fieldState } = this.props;
        const form = this.form.$;

        const res: any = await kontaktpersonStore.updateKontaktperson({
            id: fieldState.value,
            navn: form.navn.$,
            email: form.email.$,
            telefonDirekte: form.telefonDirekte.$,
            telefonMobil: form.telefonMobil.$,
        })

        fieldState.onChange(res.id);
        kontaktpersonStore.setSelectedId(res.id);

        this.setState({ showOverlay: false });
    }

    onCreate = () => {
        const { fieldState } = this.props;
        fieldState.onChange(null);

        this.setState({ showOverlay: true })
    }

    onEdit = () => {
        const { fieldState } = this.props;
        const id = Number(fieldState.value);
        if (id === -1) return;

        const kontaktperson = _.find(kontaktpersonStore.kontaktpersoner, { id: id });
        if (!kontaktperson) return;

        const form = this.form.$;
        form.navn.reset(kontaktperson.navn);
        form.email.reset(kontaktperson.email);
        form.telefonDirekte.reset(kontaktperson.telefonDirekte);
        form.telefonMobil.reset(kontaktperson.telefonMobil);


        this.setState({ showOverlay: true });
    }

    render() {
        const { showOverlay } = this.state;
        const { fieldState } = this.props;
        const isNew = !fieldState.value;

        const form = this.form.$;

        let options = kontaktpersonStore.kontaktpersoner.map((k) => ({
            label: k.navn + (k.email ? ` (${k.email})` : ''),
            value: k.id
        }))

        options = _.orderBy(options, 'label');

        return (
            <div>
                <Dropdown
                    onCreate={() => this.onCreate()}
                    placeholder='Vælg kontaktperson...'
                    orderBy='navn'
                    options={options}
                    type='number'
                    fieldState={fieldState} />

                {
                    (fieldState.value && !showOverlay) &&
                    <button
                        type='button'
                        style={{ fontSize: '12px' }}
                        onClick={() => this.onEdit()}>
                        Vis detaljer
                    </button>
                }

                <MyDock position='right' isVisible={showOverlay}>
                    <h2>
                        {isNew ? 'Ny kontaktperson' : 'Ret kontaktperson'}
                    </h2>

                    <Form onSubmit={isNew ? this.createKontaktperson : this.updateKontaktperson} formState={this.form}>
                        <Row label='Navn'>
                            <Input fieldState={form.navn} />
                        </Row>

                        <Row label='Email'>
                            <Input fieldState={form.email} />
                        </Row>

                        <Row label='Telefon (direkte)'>
                            <Input fieldState={form.telefonDirekte} />
                        </Row>

                        <Row label='Telefon (mobil)'>
                            <Input fieldState={form.telefonMobil} />
                        </Row>

                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => this.setState({ showOverlay: false })}
                                label='Luk'
                                icon='cross'
                                type='button'
                                style={{ marginRight: '20px', marginTop: '100px' }}
                            />
                            <Button
                                primary={true}
                                label={isNew ? 'Opret kontaktperson' : 'Gem ændringer'}
                                icon='arrowDownBox'
                            />
                        </div>
                    </Form>
                </MyDock>
            </div >

        )
    }
}

export default SelectKontaktperson;