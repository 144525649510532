import request from "helpers/api/PrivateApiRequest";
import { action, observable, makeObservable } from "mobx";
import { BaseStore } from "./BaseStore";

export interface KundeInterface {
    "id": number,
    "erErhverv": boolean,
    "erUnderleverandoer": boolean,
    "firmanavn": string,
    "spedition": boolean
}

export class KundeStore extends BaseStore {
    @observable kunder: Array<KundeInterface> = [];

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.kunder = [];
    }

    @action
    getKunder() {
        return this.get(`kunder`, 'kunder', false);
    }
}

export const kundeStore = new KundeStore();