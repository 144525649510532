import { BaseStore } from "./BaseStore";
import { observable, action, makeObservable } from "mobx";
import request from "helpers/api/PrivateApiRequest";

export interface HolderTypeInterface {
    "id": number,
    "navn": string,
    "statisk": boolean,
    "inOutVareId": number
}

export class HoldertypeStore extends BaseStore {
    @observable holdertyper: Array<HolderTypeInterface> = [];

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.holdertyper = [];
    }

    @action
    getHoldertyper = () => {
        return this.get('holdertyper?typesinuse=true', 'holdertyper');
    }
}

export const holderTypeStore = new HoldertypeStore();