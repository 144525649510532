import { BaseStore } from "./BaseStore";
import { observable, action, makeObservable } from "mobx";
import LoadPromise from "helpers/LoadPromise";
import _ from 'lodash';
import request from 'helpers/api/PrivateApiRequest';

export interface AdresseInterface {
    "id"?: number,
    "navn": string,
    "adresseLinie": string,
    "postnummer": number,
    "by": string,
    "hovednummer"?: string,
    "att"?: string,
    "landekode"?: string
}

export class AdresseStore extends BaseStore {
    @observable adresser: Array<AdresseInterface> = [];
    @observable selectedAdresse: AdresseInterface | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.adresser = [];
        this.selectedAdresse = null;
    }

    @action
    getAdresser() {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await request.get('adresser');
                const adresser = res.data as Array<AdresseInterface>;

                if (adresser) {
                    this.adresser = _.orderBy(adresser, a => a.adresseLinie?.toLowerCase());
                    resolve(this.adresser);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    createAdresse(adresse: AdresseInterface) {
        return this.create(`adresser`, adresse, this.adresser);
    }

    @action
    deleteAdresse(adresseId: number) {
        return LoadPromise(async (resolve, reject) => {
            _.remove(this.adresser, (t: any) => t.id === adresseId);
            await request.delete(`adresser/${adresseId}`);
            resolve();
        })
    }

    @action
    setSelectedAdresse(value: AdresseInterface | null) {
        this.selectedAdresse = value;
    }
}

export const adresseStore = new AdresseStore();