import request from 'helpers/api/PrivateApiRequest';
import { action, makeObservable, observable } from 'mobx';
import { BaseStore } from './BaseStore';
import { bestillingStore } from './BestillingStore';
import { FileInterface } from './FileStore';
import { lageropgaveProduktStore, ProduktInterface } from './LageropgaveProduktStore';
import { lageropgaveStore, OpbevaringsholderInterface } from './LageropgaveStore';

export enum UdleveringsTypeEnum {
    NOT_SELECTED,
    OPBEVARINGSHOLDERE,
    PRODUKTER
}

class BestilUdleveringStore extends BaseStore {

    @observable udleveringsType: UdleveringsTypeEnum = UdleveringsTypeEnum.NOT_SELECTED;
    @observable opbevaringsholdere: Array<OpbevaringsholderInterface> = [];
    @observable produkter: Array<ProduktInterface> = [];
    @observable files: Array<FileInterface> = [];

    constructor() {
        super(request);

        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.udleveringsType = UdleveringsTypeEnum.NOT_SELECTED;
        this.opbevaringsholdere = [];
        this.produkter = [];
        this.files = [];
    }

    @action
    startFlow = (type: UdleveringsTypeEnum) => {
        this.resetStore();
        if (type === UdleveringsTypeEnum.NOT_SELECTED) return;

        this.udleveringsType = type;
        if (type === UdleveringsTypeEnum.OPBEVARINGSHOLDERE) {
            this.opbevaringsholdere = lageropgaveStore.getSelectedOpbevaringsholdere(true);
        }
        else {
            this.produkter = lageropgaveProduktStore.getSelectedProducts();
        }
    }

    @action
    setFiles = (files: Array<FileInterface>) => {
        this.files = files;
    }

    canStartOrderFlow = () => {
        switch (this.udleveringsType) {
            case UdleveringsTypeEnum.NOT_SELECTED: return false;
            case UdleveringsTypeEnum.OPBEVARINGSHOLDERE: return this.opbevaringsholdere.length > 0;
            case UdleveringsTypeEnum.PRODUKTER: return this.produkter.length > 0;
        }
    }

    createBestilling = async (data: any) => {
        switch (this.udleveringsType) {
            case UdleveringsTypeEnum.OPBEVARINGSHOLDERE:
                await bestillingStore.createBestilling(data);
                break;
            case UdleveringsTypeEnum.PRODUKTER:
                await bestillingStore.createProduktBestilling(data);
                break;
            case UdleveringsTypeEnum.NOT_SELECTED:
            default:
                break;
        }

        if (this.udleveringsType === UdleveringsTypeEnum.OPBEVARINGSHOLDERE) {

        }
    }

}

export const bestilUdleveringStore = new BestilUdleveringStore();