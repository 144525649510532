import React from 'react';
import { observer } from 'mobx-react';
import { navigationStore } from 'stores/NavigationStore';
import { Switch, Router, Route } from 'react-router-dom';
import LoginPage from 'components/LoginPage';
import DashboardPage from 'components/DashboardPage';
import OverviewPage from 'components/OverviewPage';
import BevaegelserPage from 'components/BevaegelserPage';
import OverblikPage from 'components/OverblikPage';
import BestilUdleveringPage from 'components/BestilUdleveringPage';
import BestilOpbevaringPage from 'components/BestilOpbevaringPage';
import UdleveringDonePage from 'components/UdleveringDonePage';
import IndleveringDonePage from 'components/IndleveringDonePage';
import Toast from 'components/Toast';
import ReactTooltip from 'react-tooltip';
import VarekatalogPage from 'components/VarekatalogPage';
import EditVarePage from 'components/EditVarePage';
import ImportVarekatalogPage from 'components/ImportVarekatalogPage';
import ExportBevaegelserPage from 'components/ExportBevaegelserPage';
import ProdukterPage from 'components/ProdukterPage';
import PublicApiSettingsPage from 'components/PublicApiSettingsPage';

@observer class App extends React.Component {

    render() {

        return (
            <Router history={navigationStore.history}>
                <Toast />
                <ReactTooltip />
                <Switch>
                    <Route exact path='/login' component={LoginPage} />

                    <DashboardPage>
                        <Switch>
                            <Route exact path='/' component={OverviewPage} />
                            <Route exact path='/produkter' component={ProdukterPage} />
                            <Route exact path='/bevaegelser' component={BevaegelserPage} />
                            <Route exact path='/export-bevaegelser' component={ExportBevaegelserPage} />
                            <Route exact path='/overblik' component={OverblikPage} />
                            <Route exact path='/udlevering/:isProduct?' component={BestilUdleveringPage} />
                            <Route exact path='/udlevering-done' component={UdleveringDonePage} />
                            <Route exact path='/indlevering' component={BestilOpbevaringPage} />
                            <Route exact path='/indlevering/done' component={IndleveringDonePage} />
                            <Route exact path='/varekatalog' component={VarekatalogPage} />
                            <Route exact path='/varekatalog/import' component={ImportVarekatalogPage} />
                            <Route exact path='/varekatalog/:id' component={EditVarePage} />

                            <Route exact path='/shopify' component={PublicApiSettingsPage} />
                        </Switch>
                    </DashboardPage>
                </Switch>
            </Router>
        )
    }
}

export default App;
