import { BearerTokenInterface } from "helpers/api/RequestFactory";
import { action, makeObservable, observable } from "mobx";
import SaveableStore from "./SaveableStore";

class PrivateApiTokenStore extends SaveableStore implements BearerTokenInterface {

    @observable bearerToken: string | null = null;

    constructor() {
        super('PrivateApiTokenStore');
        makeObservable(this);

        this.initSessionStorage(this, [
            'bearerToken'
        ])
    }

    @action
    setBearerToken = (bearerToken: string | null) => {
        this.bearerToken = bearerToken;
    }

}

export const privateApiTokenStore = new PrivateApiTokenStore();