import * as React from 'react';
import { observer } from 'mobx-react';
// import * as ReactDOM from 'react-dom';
import './style.css';

// const printRoot = document.getElementById('print');

interface Props {
    children: React.ReactNode
}

@observer class ShowOnPrint extends React.Component<Props> {

    render() {
        const { children } = this.props;

        return (
            <span className={`printable`}>
                {children}
            </span>
        )
    }

}

export default ShowOnPrint;