import { observable, computed, action, makeObservable } from "mobx";
import { Cache } from "helpers/api/Cache";
import { navigationStore } from "./NavigationStore";
import request from 'helpers/api/PrivateApiRequest';
import LoadPromise from 'helpers/LoadPromise';
import { lageropgaveStore } from "./LageropgaveStore";
import { BaseStore } from "./BaseStore";
import { authStore as publicAuthStore } from "stores/PublicApi/AuthStore";
import { privateApiTokenStore } from "./PrivateApiTokenStore";
import { publicApiTokenStore } from "./PublicApiTokenStore";

export interface UserTokenInterface {
    id: string | null,
    displayNavn: string | null,
    bearerToken?: string | null,
    erUnderleverandoer: boolean | null,
    user: UserInterface | null
}

export interface UserInterface {
    "id": number,
    "brugernavn": string,
    "betingelser": boolean,
    "kunde": {
        "id": number,
        "firmanavn": string,
        "spedition": boolean,
        "erUnderleverandoer": boolean,
        "erErhverv": boolean,
        "skjulBevisPaaWeb": boolean
    }
}

export class AuthStore extends BaseStore {

    @observable id: string | null = null;
    @observable displayNavn: string | null = null;
    @observable erUnderleverandoer: boolean | null = null;
    @observable downloadToken: string | null = null;
    @observable user: UserInterface | null = null;

    constructor() {
        super(request, 'AuthStore');
        makeObservable(this);

        this.initSessionStorage(this, [
            'id',
            'displayNavn',
            'erUnderleverandoer',
            'user'
        ])
    }

    @action
    sendPinCodeToEmail = (email: string) => {
        return LoadPromise(async (resolve, reject) => {
            try {
                await request.post('auth/login', {
                    email: email
                })

                resolve();
            }
            catch (error: any) {
                reject(error.error);
            }

        })
    }

    @action
    getToken = (email: string, pinCode: number) => {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await request.post('auth/token', {
                    email: email,
                    pinkode: pinCode
                })

                const data: UserTokenInterface = res.data;
                this.setSaveableData(data);
                privateApiTokenStore.setBearerToken(data.bearerToken!);

                if (!publicApiTokenStore.bearerToken) {
                    await publicAuthStore.getToken();
                }

                await this.getMe();

                resolve(data);
            }
            catch (error: any) {
                reject(error.error);
            }
        })
    }

    @action
    changeToken = (kundeId: number) => {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await request.post('auth/change-token', {
                    kundeId: kundeId
                });

                const data: UserTokenInterface = res.data;
                this.setSaveableData(data);
                privateApiTokenStore.setBearerToken(data.bearerToken!);

                await this.getMe();

                lageropgaveStore.resetStore();
                Cache.reset();

                resolve(data);
            }
            catch(error: any) {
                reject(error.error);
            }
        })
    }

    @action
    getMe = () => {
        return this.get(`brugere/me`, 'user');
    }

    @action
    updateUser = (user: UserInterface) => {
        return this.update(`brugere/${user.id}`, user, this.user);
    }

    @action
    getDownloadToken = () => {
        return this.get(`auth/download-token`, 'downloadToken');
    }

    @computed
    get isAuthenticated() {
        return !!this.id;
    }

    @action
    logout = () => {
        this.setSaveableData({
            id: null,
            displayNavn: null,
            erUnderleverandoer: null,
            user: null
        });
        privateApiTokenStore.setBearerToken(null);
        publicApiTokenStore.setBearerToken(null);
        lageropgaveStore.resetStore();
        Cache.reset();
        navigationStore.push('/login');
    }

    setSaveableData(data: UserTokenInterface): void {
        this.id = data.id;
        this.displayNavn = data.displayNavn;
        this.erUnderleverandoer = data.erUnderleverandoer;
        this.user = data.user;
    }

}

export const authStore = new AuthStore();