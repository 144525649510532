import React, { useEffect, useState } from "react";
import { lageropgaveProduktStore, ProduktInterface } from "stores/LageropgaveProduktStore";
import { lageropgaveStore } from "stores/LageropgaveStore";
import { Table } from 'rsuite';
import _ from 'lodash';
import { loadingStore } from "stores/LoadingStore";
import { navigationStore } from "stores/NavigationStore";
import { observer } from "mobx-react-lite";
import TopActions from "components/Table/TopActions";
import ProdukterTableAction from "./ProdukterTableAction";
import PageDescription from "components/PageDescription";

const { Column, HeaderCell, Cell } = Table;

const ProdukterPage = observer(() => {
    const [searchValue, setSearchValue] = useState('');
    const [katalogId, setKatalogId] = useState<number | null>(null);
    const [tableData, setTableData] = useState<Array<ProduktInterface>>([]);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

    useEffect(() => {
        async function getProdukter() {
            await lageropgaveProduktStore.getProdukter(lageropgaveStore.selectedLageropgave!.id);
            setTableData(_.orderBy(lageropgaveProduktStore.produkter, 'id', 'desc'));
            setIsTableLoading(false);
        }

        getProdukter();

    }, [])

    const onKatalogIdChanged = (value: number | null) => {
        setKatalogId(value);
    }

    const onSearchChanged = (value: string) => {
        setSearchValue(value);
    }

    const onLageropgaveIdChanged = async (value: number) => {
        await lageropgaveProduktStore.getProdukter(value);
        setTableData(_.orderBy(lageropgaveProduktStore.produkter, 'id', 'desc'));
    }

    const onPlukChanged = (produkt: ProduktInterface, value: number) => {
        if (!Number(value) && Number(value) !== 0) {
            return;
        }

        if (value > produkt.remainingAntal) {
            value = produkt.remainingAntal;
        }

        if (value === 0) {
            lageropgaveProduktStore.deleteSelectedId(produkt.identifikation);
        }
        else {
            console.log(produkt, value)
            lageropgaveProduktStore.setSelectedId(produkt, value);
        }
    }

    const renderMetaTag = (tag: string) => {
        return <span style={{ fontWeight: 600, fontSize: '10px', color: '#0096EB', paddingLeft: '10px' }}>({tag.toUpperCase()})</span>
    }

    const filterProdukterBySearchQuery = (allProducts: Array<ProduktInterface>) => {
        if (!searchValue && !katalogId) return allProducts;

        if (katalogId) {
            return _.filter(allProducts, (p) => p.katalog?.id === katalogId);
        }

        const results: Array<ProduktInterface> = [];
        if (searchValue.length < 3) return results;
        const loweredSearchQuery = searchValue.toLowerCase();

        return _.filter(allProducts,
            (d) => ((d.identifikation.toLowerCase().indexOf(loweredSearchQuery) !== -1)
                || (d.katalog && d.katalog.navn && d.katalog.navn.toLowerCase().indexOf(loweredSearchQuery) !== -1)
                || (d.katalog && d.katalog.varenr && d.katalog.varenr.toLowerCase().indexOf(loweredSearchQuery) !== -1)
            ))
    }

    const onGotoOverview = (e: React.MouseEvent) => {
        e.preventDefault();

        if (Object.keys(lageropgaveProduktStore.selectedIds).length > 0) {
            alert('Du kan ikke skifte visning med aktive produkter valgt til udlevering. Nulstil bestillingskurven først.');
            return;
        }

        navigationStore.push('/');
    }

    const isAnyAPICallsLoading = loadingStore.isLoading;
    const isLoading = isAnyAPICallsLoading || isTableLoading;
    const data = filterProdukterBySearchQuery(tableData);
    const selectedIds = lageropgaveProduktStore.selectedIds;

    return (
        <div>
            <PageDescription title='Grupperet oversigt'>
                <p>
                    Her kan du bestille varer til udlevering, uden at skulle tage stilling til den faktiske lokation på lageret.
                </p>
                <p>
                    <a href=" " onClick={(e) => onGotoOverview(e)}>Klik her for at gå tilbage til standard visning</a>
                </p>

            </PageDescription>
            <TopActions
                hideSearch={!!katalogId}
                onSearchChanged={onSearchChanged}
                onKatalogIdChanged={onKatalogIdChanged}
                onLageropgaveIdChanged={onLageropgaveIdChanged}
                disableSelectLageropgave={Object.keys(lageropgaveProduktStore.selectedIds).length > 0}
            />
            <div style={{ display: 'flex' }}>
                <Table
                    style={{ width: '100%' }}
                    data={data}
                    loading={isLoading}
                    renderEmpty={() => !isLoading && searchValue.length === 0 ? <div className='rs-table-body-info'>Ingen resultater.</div> : null}
                    renderLoading={() =>
                        <div className='rs-table-loader-wrapper'>
                            <div className='rs-table-loader'>
                                <i className='rs-table-loader-icon' />
                                <span className='rs-table-loader-text'>Henter lagerlinier...</span>
                            </div>
                        </div>
                    }
                    rowKey='id'
                    autoHeight>

                    <Column resizable={true} width={450}>
                        <HeaderCell>Identifikation</HeaderCell>
                        <Cell>
                            {(rowData: ProduktInterface, rowIndex: any) => {
                                return (
                                    <React.Fragment key={rowIndex}>
                                        <span>{rowData.identifikation}</span>
                                        {
                                            rowData.filUrls?.map((filUrl: string, index: number) => {
                                                return (
                                                    <img
                                                        key={index}
                                                        height={25}
                                                        src={filUrl}
                                                        alt='fil'
                                                        style={{ paddingLeft: '10px', cursor: 'pointer' }}
                                                        onClick={() => window.open(filUrl)}
                                                        data-tip='Vis billede'
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            rowData.metaOeko &&
                                            renderMetaTag('Øko')
                                        }
                                        {
                                            rowData.metaRetur &&
                                            renderMetaTag('Retur')
                                        }
                                        {
                                            rowData.metaSkade &&
                                            renderMetaTag('Skadet')
                                        }
                                    </React.Fragment>
                                )
                            }}
                        </Cell>
                    </Column>
                    <Column resizable={true} width={200}>
                        <HeaderCell>Type</HeaderCell>
                        <Cell>
                            {(rowData: ProduktInterface, rowIndex: any) => {
                                return rowData.holderNavn
                            }}
                        </Cell>
                    </Column>

                    <Column resizable={true} width={200}>
                        <HeaderCell>Katalog</HeaderCell>
                        <Cell>
                            {(rowData: ProduktInterface, rowIndex: any) => {
                                return rowData.katalog?.navn
                            }}
                        </Cell>
                    </Column>

                    <Column width={150} align='left' fixed='right'>
                        <HeaderCell><span style={{ fontWeight: 600 }}>Udlevér</span></HeaderCell>
                        <Cell>
                            {(rowData: ProduktInterface, rowIndex: number) => {
                                if (rowData.remainingAntal === 0) return '-';

                                return (
                                    <>
                                        <input
                                            placeholder='0'
                                            value={selectedIds[rowData.identifikation]?.antal ?? ''}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPlukChanged(rowData, Number(e.target.value))}
                                            style={{ width: '50px', textAlign: 'right' }} /> / {rowData.remainingAntal}
                                    </>
                                )
                            }}
                        </Cell>
                    </Column>

                </Table>
                <ProdukterTableAction />
            </div>
        </div>

    )
})

export default ProdukterPage;