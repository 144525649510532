import Dropdown from "components/Forms/Dropdown";
import { FormState } from "formstate";
import _ from 'lodash';
import { observer } from "mobx-react";
import { ProductServiceCodeEnum, ProductServiceInterface, shipmondoStore } from "stores/PublicApi/ShipmondoStore";
import Checkbox from 'components/Forms/Checkbox';
import { useEffect } from 'react';

interface Props {
    productCode: string,
    formState: FormState<any>,
    showEmail?: boolean,
    showSMS?: boolean
}

const SelectServices = observer((props: Props) => {
    const { formState, productCode } = props;

    useEffect(() => {
        return () => {
            formState.reset();
        }
    })

    const mapTimeOfDeliveryServices = (services: Array<ProductServiceInterface>) => {
        const servicesInDanish = services.map((service) => {
            switch (service.code) {
                case ProductServiceCodeEnum.EARLY_DAY:
                    service.name = 'Tidligt på dagen (08.00-14.00)';
                    break;
                case ProductServiceCodeEnum.END_DAY:
                    service.name = 'Sent på dagen (15.00-21.00)';
                    break;
                case ProductServiceCodeEnum.DAY_DELIVERY:
                    service.name = 'Hele dagen (08.00-16.00)';
                    break;
                case ProductServiceCodeEnum.EVE_DELIVERY:
                    service.name = 'Aften (17.00-21.00)';
                    break;
                case ProductServiceCodeEnum.TIMED_DELIVERY:
                    service.name = 'Levering i 4 timers interval';
                    break;
                case ProductServiceCodeEnum.MIDDLE_DAY:
                    service.name = 'Midt på dagen (11.00-17.00)';
                    break;
                case ProductServiceCodeEnum.SATURDAY_DELIVERY:
                    service.name = 'Lørdagslevering';
                    break;
                default:
                    service.name = '';
                    break;
            }

            return service;
        })

        return servicesInDanish.filter(s => s.name !== '');
    }

    const allServices: Array<ProductServiceInterface> = _.find(shipmondoStore.products, p => p.code === productCode)?.available_services || [];
    const showFlex = _.some(allServices, s => s.code === ProductServiceCodeEnum.FLEX);

    const timeOfDeliveryServices = mapTimeOfDeliveryServices(allServices);
    const fieldState = formState.$;

    return (
        <div style={{ marginTop: '8px' }}>

            {
                timeOfDeliveryServices.length > 0 &&
                <Dropdown
                    fieldState={fieldState.timeOfDelivery}
                    placeholder='Vælg service'
                    options={timeOfDeliveryServices.map((p) => ({
                        label: p!.name,
                        value: p!.code
                    }))}
                />
            }

            {
                props.showEmail &&
                <div style={{ marginTop: '12px' }}>
                    <Checkbox
                        id='shipmondoEmailNotifikation'
                        label='Send notifikationer på email'
                        fieldState={fieldState.email}
                    />
                </div>
            }

            {
                props.showSMS &&
                <div style={{ marginTop: '12px' }}>
                    <Checkbox
                        id='shipmondoSmsNotifikation'
                        label='Send notifikationer på SMS'
                        fieldState={fieldState.sms}
                    />
                </div>
            }

            {
                showFlex &&
                <div style={{ marginTop: '12px' }}>
                    <Checkbox
                        id='shipmondoFlex'
                        label='OK at stille pakke uden kvittering for modtagelse'
                        fieldState={fieldState.flex}
                    />
                </div>
            }
        </div>
    )
})

export default SelectServices;