import { OpbevaringsholderInterface } from 'stores/LageropgaveStore';

class OpbevaringsHolderHelper {

    static holderIdentifikation = (holder: OpbevaringsholderInterface) => {
        if (holder.katalog) {
            return holder.katalog.varenr;
        }
        else if (holder.identifikationstekst?.length > 0) {
            return holder.identifikationstekst;
        }
        else if (holder.holderId?.length > 0) {
            return holder.holderId;
        }
        else if (holder.lagerlinieIdentifikation?.length > 0) {
            return holder.lagerlinieIdentifikation;
        }
        else return '';
    }

    static holderAntal = (holder: OpbevaringsholderInterface) => {
        let antal: number;
        if (holder.antal === 0) {
            if (holder.reserveret === 1) {
                antal = 0;
            }
            else {
                antal = 1;
            }
        }
        else {
            antal = holder.antal - (holder.reserveret || 0)
        }

        return antal;
    }

}

export default OpbevaringsHolderHelper;