import * as React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import MyDock, { Row } from 'components/Dock';
import { FormState, FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import { isRequired } from 'helpers/FormValidation';
import Form from 'components/Forms/Form';
import { AdresseInterface, adresseStore } from 'stores/AdresseStore';
import SelectCountryCode from 'components/SelectCountryCode';

interface State {
    visible: boolean
}

interface Props {
    fieldState: FieldState<any>,
    label: string,
    isExpandedByDefault?: boolean,
    onAdresseCreated?: (adresse: AdresseInterface) => void
}

@observer class CreateAdresse extends React.Component<Props, State> {

    form = new FormState({
        navn: new FieldState('').validators(isRequired),
        adresseLinie: new FieldState('').validators(isRequired, (value) => value.length > 50 && 'Adresse må max være 50 tegn'),
        postnummer: new FieldState('').validators(isRequired),
        by: new FieldState('').validators(isRequired),
        landekode: new FieldState('DK').validators(isRequired)
    })

    state = { visible: this.props.isExpandedByDefault ?? false }

    onSubmit = async () => {
        const { fieldState, onAdresseCreated } = this.props;
        const form = this.form.$;

        const landekode = form.landekode.$;
        if (!landekode) {
            alert('ingen landekode');
            return;
        }
        
        const res: any = await adresseStore.createAdresse({
            navn: form.navn.$,
            adresseLinie: form.adresseLinie.$,
            postnummer: Number(form.postnummer.$),
            by: form.by.$,
            landekode: landekode
        })

        fieldState.onChange(res.id);
        adresseStore.setSelectedAdresse(res);
        this.form.reset();

        this.setState({ visible: false });
        
        if (onAdresseCreated) {
            onAdresseCreated(res);
        }
    }

    render() {
        const { label } = this.props;
        const { visible } = this.state;
        const form = this.form.$;

        return (
            <>
                <button
                    style={{ padding: 0, textDecoration: 'underline', color: '#004173', background: 'transparent', fontWeight: 600 }}
                    type='button'
                    onClick={() => this.setState({ visible: true })}>{label}</button>
                <MyDock position='right' isVisible={visible}>
                    <h2>
                        Opret adresse
                    </h2>

                    <Form onSubmit={this.onSubmit} formState={this.form}>
                        <Row label='Navn'>
                            <Input fieldState={form.navn} />
                        </Row>

                        <Row label='Adresse'>
                            <Input fieldState={form.adresseLinie} />
                        </Row>

                        <Row label='Postnummer'>
                            <Input fieldState={form.postnummer} />
                        </Row>

                        <Row label='By'>
                            <Input fieldState={form.by} />
                        </Row>

                        <Row label='Land'>
                            <SelectCountryCode fieldState={form.landekode} />
                        </Row>

                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => this.setState({ visible: false })}
                                label='Fortryd'
                                icon='cross'
                                type='button'
                                style={{ marginRight: '20px', marginTop: '100px' }}
                            />
                            <Button
                                primary={true}
                                label='Opret adresse'
                                icon='arrowDownBox'
                            />
                        </div>
                    </Form>

                </MyDock>
            </>
        )
    }
}

export default CreateAdresse;