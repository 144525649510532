import * as React from 'react';
import { observer } from 'mobx-react';
import Flatpickr from 'react-flatpickr';
import { FieldState } from 'formstate';
import moment from 'moment';
import 'flatpickr/dist/themes/light.css';

import './style.css';

interface Props {
    fieldState: FieldState<any>,
    placeholder?: string,
    allowPastDates?: boolean,
    enableTime?: boolean
}

@observer class DateInput extends React.Component<Props> {

    onChange = (value: any) => {
        const { fieldState } = this.props;
        const val = value[0];
        fieldState.onChange(val);
    }

    render() {
        const { fieldState, placeholder, enableTime } = this.props;
        let value = fieldState.value ? moment(fieldState.value).toISOString() : undefined;

        return (
            <>
                <Flatpickr
                    placeholder={placeholder}
                    options={{
                        enableTime: enableTime,
                        time_24hr: enableTime,
                        minuteIncrement: 30,
                        defaultMinute: 0,
                        disable: [
                            (selectedDate) => {
                                if (this.props.allowPastDates) return false;

                                var today = new Date();
                                today.setHours(0, 0, 0, 0);
                                return (selectedDate < today);
                            }
                        ],
                        locale: {
                            firstDayOfWeek: 1,
                            weekdays: {
                                shorthand: ['søn', 'man', 'tirs', 'ons', 'tors', 'fre', 'lør'],
                                longhand: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag']
                            },
                            months: {
                                shorthand: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                                longhand: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
                            }
                        },
                        dateFormat: enableTime ? 'd/m/Y H:i' : 'd/m/Y', 
                        allowInput: false,
                        onClose: (selectedDates, dateStr, instance) => {
                            if (!dateStr) {
                                fieldState.onChange(null);
                                return;
                            }

                            const newDate = moment(dateStr, 'DD/MM/YYYY HH:mm').toISOString(true);
                            this.onChange([newDate]);
                        }
                    }}
                    value={value} />
                {
                    fieldState.error &&
                    <div className='flatpickr-error'>{fieldState.error}</div>
                }
            </>
        )
    }
}

export default DateInput;