import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FormState, FieldState } from 'formstate';
import moment from 'moment';

interface Props {
    onChange: (value: number | string) => void
}

@observer class SelectYear extends React.Component<Props> {

    form = new FormState({
        value: new FieldState('')
    })

    render() {
        const { onChange } = this.props;
        const availableYears = [];
        const currentYear = moment().year();

        for (var i = currentYear; i > currentYear - 5; i--) {
            availableYears.push({
                value: i,
                label: i.toString()
            })
        }

        return (
            <Dropdown
                fieldState={this.form.$.value}
                options={availableYears}
                type='number'
                onChange={onChange}
            />
        )
    }
}

export default SelectYear;