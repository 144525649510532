import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { holderTypeStore } from 'stores/HoldertypeStore';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectHoldertype extends React.Component<Props> {

    async componentDidMount() {
        await holderTypeStore.getHoldertyper();
        const { fieldState } = this.props;
        if (!fieldState.value && holderTypeStore.holdertyper.length > 0) {
            fieldState.onChange(_.orderBy(holderTypeStore.holdertyper, 'navn')[0].id);
        }
    }

    render() {
        const { fieldState } = this.props;

        const options = _.orderBy(
            holderTypeStore.holdertyper.map((h) => ({
                label: h.navn,
                value: h.id
            })), 
            'label')

        return (
            <Dropdown
                options={options}
                type='number'
                fieldState={fieldState} />
        )
    }
}

export default SelectHoldertype;