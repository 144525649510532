import * as React from 'react';
import { observer } from 'mobx-react';
import PageDescription from 'components/PageDescription';
import { Table } from 'rsuite';
import { bestillingStore, VareInterface, BestillingInterface } from 'stores/BestillingStore';
import { lageropgaveStore, OpbevaringsholderInterface } from 'stores/LageropgaveStore';
import { BestillingTypeEnum } from 'stores/BestillingStore';
import Moment from 'moment';
import Button from 'components/Button';
import ShowOnPrint from 'components/ShowOnPrint';
import image from './girl-calendar.svg';
import { loadingStore } from 'stores/LoadingStore';
import SelectedbestillingOverlay from './SelectedBestillingOverlay';
import { navigationStore } from 'stores/NavigationStore';
import moment from 'moment';
const { Column, HeaderCell, Cell } = Table;

interface State {
    selectedBestilling: BestillingInterface | null
}

@observer class BevaegelserPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedBestilling: null
        }
    }

    componentDidMount() {
        bestillingStore.getBestillinger();
    }

    componentWillUnmount() {
        bestillingStore.resetStore();
    }

    onRowClicked = (bestilling: BestillingInterface) => {
        this.setState({ selectedBestilling: bestilling });
    }

    gotoExportBevaegelser = (e: React.MouseEvent) => {
        e.preventDefault();
        navigationStore.push('/export-bevaegelser');
    }

    render() {
        const { selectedBestilling } = this.state;
        const isLoading = loadingStore.isLoading;
        const data = bestillingStore.bestillinger?.results || [];
        const filteredData = data.filter((d) => d.opbevaringsholdere!.length > 0 || d.varer!.length > 0);



        return (
            <div>
                <PageDescription image={image} title='Bevægelser og historik'>
                    <p>
                        Her kan du hurtigt få et overblik over, hvor langt vi er kommet med behandlingen af dine bestillinger.
                        <br />
                        Ønsker du at eksportere historik? <a href=' ' onClick={this.gotoExportBevaegelser}>klik her</a>.
                    </p>
                </PageDescription>
                <ShowOnPrint>
                    <Table
                        style={{ cursor: 'pointer' }}
                        onRowClick={(rowData: any) => this.onRowClicked(rowData)}
                        data={filteredData}
                        isTree
                        defaultExpandAllRows
                        wordWrap={true}
                        rowKey='id'
                        loading={isLoading}
                        renderEmpty={() => !isLoading ? <div className='rs-table-body-info'>Der er endnu ingen bevægelser registreret</div> : null}
                        renderLoading={() =>
                            <div className='rs-table-loader-wrapper'>
                                <div className='rs-table-loader'>
                                    <i className='rs-table-loader-icon' />
                                    <span className='rs-table-loader-text'>Henter bevægelser...</span>
                                </div>
                            </div>
                        }
                        autoHeight>
                        <Column flexGrow={1}>
                            <HeaderCell>Indhold</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: any) => {
                                    if (rowData.opbevaringsholdere.length > 0) {
                                        return rowData.opbevaringsholdere.map((oh: OpbevaringsholderInterface, index: number) => {
                                            const identifier = [];
                                            if (oh.katalog) identifier.push(oh.katalog.varenr);
                                            else if (oh.identifikationstekst) identifier.push(oh.identifikationstekst);
                                            else if (oh.lagerlinieIdentifikation) identifier.push(oh.lagerlinieIdentifikation);

                                            if (oh.holderId) identifier.push(oh.holderId);

                                            return (
                                                <div key={index}>
                                                    {oh.pluk > 0 ? oh.pluk : oh.uspecificeretAntal > 0 ? oh.uspecificeretAntal + ' usp. på ' : 1} {oh.holderNavn} {identifier.length > 0 ? `- ${identifier.join(', ')}` : ''}
                                                    {
                                                        oh.bedstFoerDato &&
                                                        <span> ({moment(oh.bedstFoerDato).format('DD-MM-YYYY')})</span>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    else if (rowData.varer.length > 0) {
                                        return rowData.varer.map((vare: VareInterface, index: number) => (
                                            <div key={index}>
                                                {vare.antal} {vare.varenavn}
                                            </div>
                                        ))
                                    }
                                    else return null;
                                }}
                            </Cell>
                        </Column>
                        <Column width={130}>
                            <HeaderCell>Dato</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: any) => {
                                    if (rowData['dato']) {
                                        return Moment(rowData['dato']).format('DD-MM-YYYY')
                                    }
                                    else return 'Hurtigst muligt';
                                }}
                            </Cell>
                        </Column>
                        <Column>
                            <HeaderCell>Oprettet af</HeaderCell>
                            <Cell style={{ wordBreak: 'break-word' }}>
                                {(rowData: any, rowIndex: any) => rowData?.oprettetAf?.brugernavn}
                            </Cell>
                        </Column>
                        {
                            lageropgaveStore.selectedLageropgave!.visShipmondoIWebUI &&
                            <Column>
                                <HeaderCell>Transportør</HeaderCell>
                                <Cell style={{ wordBreak: 'break-word' }}>
                                    {(rowData: any, rowIndex: any) => rowData?.shipmondoTransportoerKode}
                                </Cell>
                            </Column>

                        }
                        <Column>
                            <HeaderCell>Type</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: any) => {
                                    switch (rowData['type']) {
                                        case BestillingTypeEnum.INDLEVERING: return 'Indlevering';
                                        case BestillingTypeEnum.UDLEVERING: return 'Udlevering';
                                    }
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Note</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: any) => <span style={{ wordBreak: 'break-word' }}>{rowData?.tekst}</span>}
                            </Cell>
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: any) => {

                                    const cancelBestilling = (e: React.MouseEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (window.confirm('Er du sikker på at du vil annullere denne bestilling?')) {
                                            bestillingStore.deleteBestilling(rowData.id);
                                        }
                                    }

                                    const openTrackTrace = (e: React.MouseEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        window.open(rowData.trackAndTrace);
                                    }

                                    const btnStyle = {
                                        display: 'block',
                                        color: '#d66161',
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                        marginLeft: '0',
                                        padding: '5px',
                                        background: 'white',
                                        marginTop: '5px'
                                    };

                                    return (
                                        <span>
                                            {rowData.status}

                                            {rowData.udlevering?.udleveretDato &&
                                                <div style={{ fontSize: '12px' }}>{Moment(rowData.udlevering?.udleveretDato).format('DD-MM-YYYY')}</div>
                                            }

                                            {
                                                rowData.status === 'Afventer' &&
                                                <button
                                                    style={btnStyle}
                                                    onClick={cancelBestilling}>[Slet bestilling]</button>
                                            }

                                            {
                                                rowData.trackAndTrace &&
                                                <button
                                                    style={btnStyle}
                                                    onClick={openTrackTrace}>[Track and trace]
                                                </button>
                                            }

                                        </span>
                                    )
                                }}
                            </Cell>
                        </Column>
                    </Table>
                </ShowOnPrint>

                {
                    bestillingStore.bestillinger?.hasNextPage &&
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button label='Vis flere...' onClick={bestillingStore.getBestillinger} />
                    </div>
                }

                <SelectedbestillingOverlay
                    selectedBestilling={selectedBestilling}
                    onClose={() => this.setState({ selectedBestilling: null })}
                />

            </div >
        )
    }
}

export default BevaegelserPage;