import * as React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import Dock from 'react-dock';
import DetailsRow from 'components/DetailsRow';

import css from './style.module.css';

const modalRoot = document.getElementById('modal-root');


export const Row = (props: any) => (
    <DetailsRow labelWidth='120px' label={props.label} alignLabel={props.alignSelf || 'center'} containerMargin='24px 0'>
        {props.children}
    </DetailsRow>
)

@observer class MyDock extends React.Component<any> {

    el: HTMLDivElement;

    constructor(props: any) {
        super(props);

        this.el = document.createElement('div');
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        modalRoot!.appendChild(this.el);
      }
    
      componentWillUnmount() {
        modalRoot!.removeChild(this.el);
      }

    render() {
        
        return ReactDOM.createPortal(
            <Dock {...this.props} zIndex={10}>
                <div className={css.container}>
                    {this.props.children}
                </div>
            </Dock>, this.el
        );
    }
}

export default MyDock;