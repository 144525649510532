import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PageDescription from 'components/PageDescription';
import image from './documents.svg';
import { katalogStore } from 'stores/KatalogStore';
import ShowOnPrint from 'components/ShowOnPrint';
import { Table } from 'rsuite';
import { loadingStore } from 'stores/LoadingStore';
import Button from 'components/Button';
import MyDock, { Row } from 'components/Dock';
import { FieldState, FormState } from 'formstate';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import { navigationStore } from 'stores/NavigationStore';
import { isRequired } from 'helpers/FormValidation';
import SearchInput from 'components/SearchInput';
import _ from 'lodash';
import { toastStore } from "stores/ToastStore";

const { Column, HeaderCell, Cell } = Table;

const VarekatalogPage = observer(() => {
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [includeArchived, setIncludeArchived] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const form = new FormState({
        navn: new FieldState('').validators(isRequired),
        varenr: new FieldState('').validators(isRequired)
    })

    useEffect(() => {
        katalogStore.getKataloger();
    }, [])

    const onCreateVare = async () => {
        const newVareNavn = form.$.navn.$;
        const newVareNr = form.$.varenr.$;

        if (_.find(katalogStore.kataloger, (k) => k.varenr?.toLowerCase() === newVareNr.toLowerCase())) {
            toastStore.addError('Der eksisterer allerede en vare med dette varenummer!');
            return;
        }

        const res = await katalogStore.createKatalog({
            varenr: newVareNr,
            navn: newVareNavn
        } as any) as any;
        navigationStore.push(`/varekatalog/${res.id}`)
        setCreateModalVisible(false);
    }

    const toggleArchived = () => {
        katalogStore.getKataloger(includeArchived);
        setIncludeArchived(!includeArchived);
    }

    const onSearchChanged = (value: string) => {
        setSearchQuery(value);
    }

    const kataloger = katalogStore.kataloger.filter((katalog) => {
        if (!searchQuery) return true;
        const lowerQuery = searchQuery.toLowerCase();
        return (
            (katalog.varenr && katalog.varenr?.toLowerCase().startsWith(lowerQuery))
            || (katalog.navn && katalog.navn.toLowerCase().indexOf(lowerQuery) !== -1)
        )
    })

    const isLoading = loadingStore.isLoading;

    return (
        <div>
            <PageDescription image={image} title='Varekatalog'>
                <p>
                    Her kan du oprette dit eget varekatalog. Når du opretter dine varer i systemet, giver du JD Logistik mulighed for at kategorisere dem rigtigt ved indlevering.
                </p>

                <Button
                    onClick={() => setCreateModalVisible(true)}
                    label='Opret vare'
                    primary={true}
                    style={{ marginTop: '20px' }}
                />

                <Button
                    onClick={() => navigationStore.push('/varekatalog/import')}
                    label='Importér fra skabelon'
                    style={{ marginTop: '20px', marginLeft: '10px' }}
                />

            </PageDescription>
            <ShowOnPrint>
                <div style={{ marginBottom: '20px' }}>
                    <SearchInput onChange={onSearchChanged} placeholder='Søg på varenummer eller navn...' />
                </div>
                <Table
                    style={{ cursor: 'pointer' }}
                    data={kataloger}
                    autoHeight
                    loading={isLoading}
                    onRowClick={(rowData: any) => navigationStore.push(`/varekatalog/${rowData.id}`)}
                    renderEmpty={() => !isLoading ? <div className='rs-table-body-info'>Der er ingen varer tilgængelige...</div> : null}
                    renderLoading={() =>
                        <div className='rs-table-loader-wrapper'>
                            <div className='rs-table-loader'>
                                <i className='rs-table-loader-icon' />
                                <span className='rs-table-loader-text'>Henter varekatalog...</span>
                            </div>
                        </div>
                    }
                >
                    <Column>
                        <HeaderCell>Varenummer</HeaderCell>
                        <Cell dataKey='varenr' />
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Navn</HeaderCell>
                        <Cell dataKey='navn' />
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Beskrivelse</HeaderCell>
                        <Cell dataKey='beskrivelse' />
                    </Column>
                </Table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button label={includeArchived ? 'Skjul arkiverede varer' : 'Inkludér arkiverede varer'} onClick={toggleArchived} />
                </div>
            </ShowOnPrint>

            <MyDock position='right' isVisible={createModalVisible}>
                <h2>
                    Opret ny vare
                </h2>

                <Form onSubmit={onCreateVare} formState={form}>
                    <Row label='Navn'>
                        <Input
                            fieldState={form.$.navn}
                        />
                    </Row>

                    <Row label='Varenr.'>
                        <Input
                            fieldState={form.$.varenr}
                        />
                    </Row>

                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => setCreateModalVisible(false)}
                            label='Luk'
                            icon='cross'
                            type='button'
                            style={{ marginRight: '20px', marginTop: '100px' }}
                        />
                        <Button
                            primary={true}
                            label='Opret vare'
                            icon='arrowDownBox'
                        />
                    </div>
                </Form>

            </MyDock>
        </div>
    )
})

export default VarekatalogPage;