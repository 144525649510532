import React from 'react';
import { observer } from 'mobx-react';
import { BestillingInterface, BestillingTypeEnum, VareInterface } from 'stores/BestillingStore';
import { OpbevaringsholderInterface } from 'stores/LageropgaveStore';
import MyDock, { Row } from 'components/Dock';
import Button from 'components/Button';
import moment from 'moment';

interface Props {
    selectedBestilling: BestillingInterface | null,
    onClose: () => void
}

@observer class SelectedbestillingOverlay extends React.Component<Props> {

    render() {
        const { selectedBestilling, onClose } = this.props;

        return (
            <MyDock position='right' isVisible={selectedBestilling !== null}>
                <h2>
                    {selectedBestilling?.type === BestillingTypeEnum.INDLEVERING ? 'Indlevering' : 'Udlevering'}
                </h2>

                <Row label='Indhold' alignSelf='start'>
                    {
                        selectedBestilling?.opbevaringsholdere?.map((oh: OpbevaringsholderInterface, index: number) => {
                            const identifier = [];
                            if (oh.katalog) identifier.push(oh.katalog.varenr);
                            else if (oh.identifikationstekst) identifier.push(oh.identifikationstekst);
                            else if (oh.lagerlinieIdentifikation) identifier.push(oh.lagerlinieIdentifikation);

                            if (oh.holderId) identifier.push(oh.holderId);

                            return (
                                <div key={index}>
                                    {oh.pluk > 0 ? oh.pluk : oh.uspecificeretAntal > 0 ? oh.uspecificeretAntal + ' usp. på ' : 1} {oh.holderNavn} {identifier.length > 0 ? `- ${identifier.join(', ')}` : ''}
                                    {
                                        oh.bedstFoerDato &&
                                        <span> ({moment(oh.bedstFoerDato).format('DD-MM-YYYY')})</span>
                                    }
                                </div>
                            )

                        })
                    }

                    {
                        selectedBestilling?.varer?.map((vare: VareInterface, index: number) => (
                            <div key={index}>
                                {vare.antal}x {vare.varenavn}
                            </div>
                        ))
                    }
                </Row>

                <Row label='Dato' alignSelf='start' >
                    <p>
                        {
                            selectedBestilling?.dato &&
                            moment(selectedBestilling?.dato).format('DD-MM-YYYY')
                        }

                        {
                            !selectedBestilling?.dato &&
                            'Hurtigst muligt'
                        }
                    </p>
                </Row>

                <Row label='Oprettet af' alignSelf='start' >
                    <p>
                        {selectedBestilling?.oprettetAf.brugernavn}
                    </p>
                </Row>

                <Row label='Status' alignSelf='start'>
                    <p>
                        {selectedBestilling?.status}
                        {
                            selectedBestilling?.trackAndTrace &&
                            <a href={selectedBestilling?.trackAndTrace} target='blank' style={{ marginLeft: '5px' }}>[Track]</a>
                        }
                    </p>

                </Row>

                {
                    selectedBestilling?.shipmondoTransportoerKode &&
                    <Row label='Transportør' alignSelf='start'>
                        <p>
                            {selectedBestilling?.shipmondoTransportoerKode}
                        </p>
                    </Row>
                }

                <Row label='Adresse' alignSelf='start' >
                    <p>
                        {selectedBestilling?.indUdAdresse?.adresseLinie}
                    </p>
                    <p>
                        {selectedBestilling?.indUdAdresse?.postnummer} {selectedBestilling?.indUdAdresse?.by}
                    </p>
                </Row>

                <Row label='Kontaktperson' alignSelf='start' >
                    <p>
                        {selectedBestilling?.indUdKontakt?.navn}
                    </p>
                </Row>

                <Row label='Bemærkninger' alignSelf='start' >
                    <p>
                        {selectedBestilling?.tekst || '-'}
                    </p>
                </Row>

                <Row label='Intern note' alignSelf='start' >
                    <p>
                        {selectedBestilling?.sporingsNote || '-'}
                    </p>
                </Row>

                {
                    selectedBestilling?.udlevering?.fil?.url &&
                    <Row label='Dokumentation' alignSelf='start'>
                        <a href={selectedBestilling.udlevering.fil.url} target='_blank' rel='noreferrer'>Vis underskrift</a>
                    </Row>
                }

                <div style={{ textAlign: 'center' }}>
                    <Button
                        onClick={() => onClose()}
                        label='Luk'
                        icon='cross'
                        type='button'
                        style={{ marginTop: '100px' }}
                    />
                </div>
            </MyDock>
        )
    }
}

export default SelectedbestillingOverlay;