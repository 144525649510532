import * as React from 'react';
import { observer } from 'mobx-react';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, Tooltip, YAxis, Bar } from 'recharts';
import { statistikStore, BeholdningInterface } from 'stores/StatistikStore';
import _ from 'lodash';

interface Props {
    searchQuery: string,
    katalogId: number | null
}

@observer class Beholdning extends React.Component<Props> {

    mapBeholdning = (beholdning: Array<BeholdningInterface>) => {
        const { searchQuery, katalogId } = this.props;
        const data: Array<any> = [];

        for (const b of beholdning) {
            if (katalogId && (!b.katalog || b.katalog?.id !== katalogId)) {
                continue;
            }
            else if (searchQuery.length > 0 && b.identifikation.toLowerCase().indexOf(searchQuery) === -1) {
                continue;
            }

            const item = _.find(data, { navn: b.holdertypeNavn });
            if (item) {
                item['antal'] += b.antal;
            }
            else {
                data.push({ antal: b.antal, navn: b.holdertypeNavn });
            }
        }

        return _.orderBy(data, 'antal', 'desc');
    }

    render() {
        const beholdning = statistikStore.beholdning || [];
        const data = this.mapBeholdning(beholdning);

        return (
            <ResponsiveContainer width='100%' height={280}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray='6 6' stroke='#0096EB' opacity={0.2} />
                    <XAxis dataKey='navn' />
                    <Tooltip />
                    <YAxis />
                    <Bar dataKey='antal' fill='#004173' />
                </BarChart>
            </ResponsiveContainer>
        )
    }
}

export default Beholdning;