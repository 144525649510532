export enum LocationEnum {
    JD_LOGISTIK,
    DOEGNTERMINAL,
    ANDEN_ADRESSE
}

interface Props {
    location: LocationEnum,
    onChange: (location: LocationEnum) => void
}

const SetLocation = (props: Props) => {

    return (
        <>
            <div>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='radio'
                        checked={props.location === LocationEnum.JD_LOGISTIK}
                        onChange={() => props.onChange(LocationEnum.JD_LOGISTIK)}
                    />
                    JD Logistik
                </label>
            </div>

            <div style={{ marginTop: '8px' }}>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='radio'
                        checked={props.location === LocationEnum.DOEGNTERMINAL}
                        onChange={() => props.onChange(LocationEnum.DOEGNTERMINAL)}
                    />
                    Døgnterminal (hverdage fra kl. 17, weekender, helligdage)
                </label>
            </div>

            <div style={{ marginTop: '8px', marginBottom: '12px' }}>
                <label>
                    <input
                        style={{ marginRight: '8px' }}
                        type='radio'
                        checked={props.location === LocationEnum.ANDEN_ADRESSE}
                        onChange={() => props.onChange(LocationEnum.ANDEN_ADRESSE)}
                    />
                    Anden adresse
                </label>
            </div>
        </>
    )

}

export default SetLocation;