import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

import css from './style.module.css';

interface Props {
    fieldState: FieldState<any>
    label: string,
    id?: string
}

@observer class Input extends React.Component<Props> {

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { fieldState } = this.props;
        fieldState!.onChange(e.target.checked);
    }

    render() {
        const { fieldState, label, id } = this.props;

        return (
            <span style={{ position: 'relative' }}>
                <div className={css.error}>
                    {fieldState.error}
                </div>

                <input
                    className={css.input}
                    id={id} type='checkbox'
                    checked={!fieldState || fieldState!.value === null ? '' : fieldState!.value}
                    onChange={(e) => this.onChange(e)}
                />

                <label
                    className={css.label}
                    style={{ display: 'inline-block', paddingLeft: '10px' }}
                    htmlFor={id}>
                    {label}
                </label>

            </span>
        )
    }
}

export default Input;