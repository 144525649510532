import React from 'react';
import { observer } from 'mobx-react';
import PageDescription from 'components/PageDescription';
import Table from 'components/Table';
import image from './man-with-boxes.svg';
import { lageropgaveStore } from 'stores/LageropgaveStore';
import { loadingStore } from 'stores/LoadingStore';
import { navigationStore } from 'stores/NavigationStore';
import { authStore } from 'stores/AuthStore';
import { lageropgaveProduktStore } from 'stores/LageropgaveProduktStore';
import DownloadOpbevaringsbevisOverlay from 'components/DownloadOpbevaringsbevisOverlay';

interface State {
    showOpbevaringsbevisOverlay: boolean
}

@observer class OverviewPage extends React.Component<any, State> {

    state = {
        showOpbevaringsbevisOverlay: false
    }

    componentDidMount() {
        if (Object.keys(lageropgaveProduktStore.selectedIds).length > 0) {
            navigationStore.push('/produkter');
        }
    }

    onGotoProdukter = (e: React.MouseEvent) => {
        e.preventDefault();

        if (Object.keys(lageropgaveStore.selectedIds).length > 0) {
            alert('Du kan ikke skifte visning med aktive varer valgt til udlevering. Nulstil bestillingskurven først.');
            return;
        }

        navigationStore.push('/produkter');
    }

    showOpbevaringsbevisOverlay = (e: React.MouseEvent) => {
        e.preventDefault();
        this.setState({ showOpbevaringsbevisOverlay: true });
    }

    render() {
        const { showOpbevaringsbevisOverlay } = this.state;

        return (
            <div>
                <PageDescription image={image} title='Din opbevaring'>
                    <p>
                        På denne side kan du nemt se din aktuelle opbevaring hos JD Logistik, bestille yderligere opbevaring, samt håndtere udleveringer.
                        {
                            !authStore.user?.kunde.skjulBevisPaaWeb &&
                            <span> Du kan desuden downloade et opbevaringsbevis, ved at <a href=' ' onClick={(e) => this.showOpbevaringsbevisOverlay(e)}>klikke her</a>.</span>
                        }
                    </p>

                    <p>
                        <a href=" " onClick={(e) => this.onGotoProdukter(e)}>Gå til grupperet oversigt</a>.
                    </p>

                </PageDescription>
                <Table />
                {
                    showOpbevaringsbevisOverlay &&
                    <DownloadOpbevaringsbevisOverlay onClose={() => this.setState({ showOpbevaringsbevisOverlay: false })} isVisible={true} />
                }

            </div>
        )
    }
}

export default OverviewPage