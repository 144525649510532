import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import _ from 'lodash';
import { lageropgaveStore, OpbevaringsholderInterface, LagerlinieInterface, MappedLagerlinieInterface } from 'stores/LageropgaveStore';
import TopActions from './TopActions';
import unspecifiedImgSrc from './unspecified.svg';
import ShowOnPrint from 'components/ShowOnPrint';
import { loadingStore } from 'stores/LoadingStore';
import moment from 'moment';
import { navigationStore } from 'stores/NavigationStore';
import ReactTooltip from 'react-tooltip';
import thumbnailSrc from './camera.svg';
import OpbevaringsHolderHelper from 'helpers/OpbevaringsHolderHelper';
import PlukTableAction from 'components/PlukTableAction';
const { Column, HeaderCell, Cell } = Table;

interface State {
    searchValue: string,
    katalogId: number | null,
    tableData: Array<MappedLagerlinieInterface>,
    isTableLoading: boolean,
    sortColumn: string,
    sortType: string
}

@observer class MyTable extends React.Component<any, State> {

    state = { searchValue: '', katalogId: null, tableData: [], isTableLoading: true, sortColumn: 'id', sortType: 'desc' }

    async componentDidMount() {
        await lageropgaveStore.getLagerlinier(lageropgaveStore.selectedLageropgave!.id);
        this.setState({
            tableData: lageropgaveStore.mappedLagerlinier,
            // tableData: _.orderBy(lageropgaveStore.mappedLagerlinier, 'id', 'desc'),
            isTableLoading: false
        })
    }

    handleSortColumn = (sortColumn: string, sortType: string) => {
        this.setState({
            sortColumn: sortColumn,
            sortType: sortType
        });
    }

    getData = () => {
        const { tableData, sortColumn, sortType } = this.state;
        return _.orderBy(tableData, sortColumn, sortType as any);
    }

    onSearchChanged = (value: string) => {
        lageropgaveStore.filterLagerlinierBySearchQuery(value);
        this.setState({
            searchValue: value,
            // tableData: _.orderBy(lageropgaveStore.mappedLagerlinier, 'id', 'desc')
            tableData: lageropgaveStore.mappedLagerlinier
        });
    }

    onKatalogIdChanged = (value: number | null) => {
        lageropgaveStore.filterLagerlinierByKatalog(value);
        this.setState({
            katalogId: value,
            searchValue: '',
            // tableData: _.orderBy(lageropgaveStore.mappedLagerlinier, 'id', 'desc')
            tableData: lageropgaveStore.mappedLagerlinier
        });

    }

    onLageropgaveIdChanged = async (value: number) => {
        await lageropgaveStore.getLagerlinier(value);
        // this.setState({ tableData: _.orderBy(lageropgaveStore.mappedLagerlinier, 'id', 'desc') });
        this.setState({ tableData: lageropgaveStore.mappedLagerlinier });
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    deleteChildren = (children: any) => {
        children.forEach((child: any) => {
            lageropgaveStore.deleteSelectedId(child.id);
            if (child.children) this.deleteChildren(child.children);
        })
    }

    onPlukChanged = (id: number, value: number, maxValue: number, children: Array<OpbevaringsholderInterface>) => {
        if (!Number(value) && Number(value) !== 0) {
            return;
        }

        this.deleteChildren(children);

        if (value > maxValue) {
            value = maxValue;
        }

        if (value === 0) {
            lageropgaveStore.deleteSelectedId(id);
        }
        else {
            lageropgaveStore.setSelectedId(id, {
                antal: value,
                uspecificeretAntal: 0
            });
        }

        if (value === maxValue) {
            const holdere = lageropgaveStore.lagerlinier.map((linie: LagerlinieInterface) => linie.opbevaringsholdere).flat();
            let holder = _.find(holdere, { id: id });

            while (holder && holder.parentId) {
                const holdereWithSameParent = _.filter(holdere, { parentId: holder.parentId });

                // If this is the only child in tree, we should select the parent
                if (holdereWithSameParent.length === 1) {
                    // Remove every selected child element
                    const p = _.find(lageropgaveStore.mappedLagerlinier, { id: holder.parentId }) as any;
                    if (p?.children) {
                        this.deleteChildren(p?.children);
                    }

                    // Set parent
                    lageropgaveStore.setSelectedId(holder.parentId, {
                        antal: 1,
                        uspecificeretAntal: 0
                    });

                    lageropgaveStore.deleteSelectedId(holder.id);
                }
                else {
                    let shouldSetParent = true;

                    // Loop through every holder with same parent, and see if every item is maxed out (fully selected)
                    for (const h of holdereWithSameParent) {
                        const totalAntal = OpbevaringsHolderHelper.holderAntal(h);
                        const selected = lageropgaveStore.selectedIds[h.id];
                        const selectedAntal = selected?.antal ?? 0;

                        if (selectedAntal < totalAntal) {
                            shouldSetParent = false;
                            break;
                        }
                    }

                    if (shouldSetParent) {
                        // Remove every selected child element
                        const p = _.find(lageropgaveStore.mappedLagerlinier, { id: holder.parentId }) as any;
                        if (p?.children) {
                            this.deleteChildren(p?.children);
                        }

                        // Set parent
                        lageropgaveStore.setSelectedId(holder.parentId, {
                            antal: 1,
                            uspecificeretAntal: 0
                        });

                        // Delete current active selected element. Not needed when parent is set.
                        lageropgaveStore.deleteSelectedId(holder.id);
                    }
                    else {
                        return;
                    }

                }

                holder = _.find(holdere, { id: holder.parentId });
            }
        }

    }

    setUnspecified = (row: any) => {
        const maxAntal = row.children.length - (row.reserveret || 0) - (row.iBrug || 0);
        const val = window.prompt(`Indtast uspecificeret antal. Max ${maxAntal}: `);

        ReactTooltip.hide();

        if (Number(val) === 1) {
            alert('For at benytte uspecificeret antal, skal du minimum plukke 2 stk. Hvis du kun har for brug for én, skal du i stedet vælge direkte på pallen.');
            return;
        }

        if (Number(val) > 0 && Number(val) <= maxAntal) {
            lageropgaveStore.setSelectedId(row.id, {
                antal: 0,
                uspecificeretAntal: Number(val)
            })
        }
        else {
            lageropgaveStore.deleteSelectedId(row.id);
        }

        row.children?.forEach((child: any) => {
            lageropgaveStore.deleteSelectedId(child.id);
        })
    }

    renderMetaTag(tag: string) {
        return <span style={{ fontWeight: 600, fontSize: '10px', color: '#0096EB', paddingRight: '10px' }}>({tag.toUpperCase()})</span>
    }

    render() {
        const { searchValue, katalogId, tableData, isTableLoading, sortColumn, sortType } = this.state;
        const selectedIds = lageropgaveStore.selectedIds;
        const isLoading = loadingStore.isLoading || isTableLoading;

        return (
            <div>
                <ShowOnPrint>
                    <TopActions
                        hideSearch={!!katalogId}
                        onSearchChanged={this.onSearchChanged}
                        onKatalogIdChanged={this.onKatalogIdChanged}
                        onLageropgaveIdChanged={this.onLageropgaveIdChanged}
                        disableSelectLageropgave={Object.keys(lageropgaveStore.selectedIds).length > 0 || !!this.state.katalogId}
                    />
                    <div style={{ display: 'flex' }}>
                        <Table
                            sortColumn={sortColumn}
                            sortType={sortType as any}
                            onSortColumn={this.handleSortColumn}
                            style={{ width: '100%' }}
                            // data={_.orderBy(tableData, 'id', 'desc')}
                            data={this.getData()}
                            isTree
                            loading={isLoading}
                            renderEmpty={() => !isLoading && searchValue.length === 0 ? <div className='rs-table-body-info'>Der er ingen lagerlinier på denne sag endnu. Tryk på <a style={{ cursor: 'pointer' }} onClick={() => navigationStore.push('/indlevering')}>bestil opbevaring</a>, for at komme igang.</div> : null}
                            renderLoading={() =>
                                <div className='rs-table-loader-wrapper'>
                                    <div className='rs-table-loader'>
                                        <i className='rs-table-loader-icon' />
                                        <span className='rs-table-loader-text'>Henter lagerlinier...</span>
                                    </div>
                                </div>
                            }
                            renderTreeToggle={(icon: any, rowData: any) => {
                                return rowData.children.length > 0 ? icon : undefined;
                            }}
                            rowKey='id'
                            autoHeight>
                            <Column resizable={true} width={200} fixed='left' sortable={true}>
                                <HeaderCell>Opbevaring</HeaderCell>
                                <Cell dataKey='holderId'>
                                    {(rowData: any, rowIndex: any) => {
                                        const hasChildren = rowData.children && rowData.children.length > 0;
                                        let sum = _.sumBy(rowData.children, (c: any) => OpbevaringsHolderHelper.holderAntal(c));
                                        if (sum === 0) {
                                            sum = rowData.children?.length;
                                        }

                                        return (
                                            <>
                                                <span style={
                                                    hasChildren ?
                                                        { fontWeight: 'bold' }
                                                        : undefined}>
                                                    {rowData.holderNavn}
                                                    {
                                                        rowData.holderId &&
                                                        <span style={{ fontSize: '12px', fontWeight: 300, marginLeft: '6px', color: '#98ACB8' }}>{rowData.holderId}</span>
                                                    }
                                                </span>
                                                {
                                                    hasChildren &&
                                                    <span style={{ marginLeft: '4px', fontSize: '12px' }}>
                                                        ({sum})
                                                    </span>
                                                }
                                            </>
                                        )
                                    }}
                                </Cell>
                            </Column>

                            <Column resizable={true} width={130} sortable={true}>
                                <HeaderCell>Modtaget</HeaderCell>
                                <Cell dataKey='modtagetDato'>
                                    {(rowData: any, rowIndex: any) => {
                                        if (rowData.modtagetDato) {
                                            return moment(rowData.modtagetDato).format('DD-MM-YYYY')
                                        }
                                        else return null;
                                    }}
                                </Cell>
                            </Column>

                            <Column resizable={true} sortable={true}>
                                <HeaderCell>ID</HeaderCell>
                                <Cell dataKey='prettyHolderName' />
                            </Column>

                            <Column flexGrow={1} sortable={true}>
                                <HeaderCell>Beskrivelse</HeaderCell>
                                <Cell dataKey='beskrivelse' data='beskrivelse'>
                                    {(rowData: any, rowIndex: any) => {
                                        return (
                                            <React.Fragment key={rowIndex}>
                                                {
                                                    rowData.fil &&
                                                    <img src={thumbnailSrc} alt='fil' style={{ paddingRight: '10px', cursor: 'pointer' }} onClick={() => window.open(rowData.fil.url)} data-tip='Vis billede' />
                                                }
                                                {
                                                    rowData.metaOeko &&
                                                    this.renderMetaTag('Øko')
                                                }
                                                {
                                                    rowData.metaRetur &&
                                                    this.renderMetaTag('Retur')
                                                }
                                                {
                                                    rowData.metaSkade &&
                                                    this.renderMetaTag('Skadet')
                                                }
                                                {
                                                    rowData.bedstFoerDato &&
                                                    this.renderMetaTag(moment(rowData.bedstFoerDato).format('DD-MM-YYYY'))
                                                }
                                                {
                                                    <span>{rowData.beskrivelse}</span>
                                                }
                                            </React.Fragment>
                                        )
                                    }}
                                </Cell>
                            </Column>

                            <Column width={120} align='left' fixed='right'>
                                <HeaderCell><span style={{ fontWeight: 600 }}>Udlevér</span></HeaderCell>
                                <Cell>
                                    {(rowData: any, rowIndex: any) => {
                                        if (rowData.iBrug) {
                                            return 'I brug';
                                        }

                                        const antal = OpbevaringsHolderHelper.holderAntal(rowData);
                                        if (antal === 0) {
                                            return 'Optaget';
                                        }

                                        let parentId = rowData.parentId;
                                        let parent: OpbevaringsholderInterface | undefined;
                                        let lagerlinie = _.find(lageropgaveStore.lagerlinier, (l: LagerlinieInterface) => l.id === rowData.lagerlinieId) as LagerlinieInterface;

                                        const holdere = lagerlinie.opbevaringsholdere;
                                        let canSelectAntal = true;

                                        if (parentId) {
                                            while (parentId) {
                                                parent = _.find(holdere, { id: parentId });
                                                if (parent && parent.iBrug) {
                                                    return 'I brug';
                                                }

                                                const selectedParent = lageropgaveStore.selectedIds[parentId];
                                                if (selectedParent) {
                                                    if (selectedParent.antal > 0) {
                                                        return 'Alt udl.';
                                                    }
                                                    else if (selectedParent.uspecificeretAntal > 0) {
                                                        return null;
                                                    }
                                                }

                                                // Maybe the parent has a parent?
                                                const parentHolder = _.find(holdere, { id: parent?.parentId });
                                                parentId = parentHolder?.id || null;
                                            }

                                        }
                                        else {
                                            if (rowData.children?.length > 0 &&
                                                _.every(rowData.children, (c) => c !== null && OpbevaringsHolderHelper.holderAntal(c) === 0)) {
                                                canSelectAntal = false;
                                            }
                                        }

                                        const topHolder = _.find(holdere as any, { parentId: null, lagerlinieId: rowData.lagerlinieId }) as any;

                                        if (topHolder && topHolder.reserveret > 0) {
                                            canSelectAntal = false;
                                        }

                                        let uspecificeretAntalAllowed = false;
                                        // Der skal være opbevaringsholdere, antal skal være nul og 
                                        // uspecificeret skal kun vises hvis antallet af childrens med stregkode er større end en. Dette er pga. pallen også ligger i opbevaringsholder
                                        if (rowData.children?.length > 0 && !!_.find(rowData.children, { antal: 0 }) && rowData.children.filter((c: OpbevaringsholderInterface) => c.holderId?.length > 0).length > 1) {
                                            uspecificeretAntalAllowed = true;
                                        }
                                        const uspecificeretAntal = selectedIds[rowData.id]?.uspecificeretAntal ?? 0;
                                        let available = 0;
                                        if (rowData.children) {
                                            available = rowData.children.length - (rowData.reserveret || 0) - (rowData.iBrug || 0);
                                        }

                                        return (
                                            <span>

                                                {
                                                    (!canSelectAntal && uspecificeretAntal === 0) &&
                                                    <span>{uspecificeretAntalAllowed && available > 0 ? `${available} ledige` : 'Optaget'}</span>
                                                }

                                                {
                                                    (canSelectAntal && uspecificeretAntal === 0) &&
                                                    <>
                                                        <input
                                                            placeholder='0'
                                                            value={selectedIds[rowData.id]?.antal ?? ''}
                                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onPlukChanged(rowData.id, Number(e.target.value), antal, rowData.children)}
                                                            style={{ width: '30px', textAlign: 'right' }} /> / {antal}
                                                    </>
                                                }

                                                {
                                                    (uspecificeretAntalAllowed && uspecificeretAntal === 0 && !!!selectedIds[rowData.id]?.antal) &&
                                                    <img
                                                        data-tip='Pluk uspecificeret antal'
                                                        onClick={() => this.setUnspecified(rowData)}
                                                        src={unspecifiedImgSrc}
                                                        alt='Udlever uspecificeret'
                                                        style={{ paddingLeft: '8px', cursor: 'pointer' }}
                                                    />
                                                }

                                                {
                                                    uspecificeretAntal > 0 &&
                                                    <span
                                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                        onClick={() => this.setUnspecified(rowData)}>{uspecificeretAntal}x usp.</span>
                                                }

                                            </span>
                                        )
                                    }}

                                </Cell>
                            </Column>

                        </Table>
                        <PlukTableAction />

                    </div>

                </ShowOnPrint>
            </div>
        )

    }
}

export default MyTable;