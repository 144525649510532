import React from 'react';
import { observer } from 'mobx-react';
import { FormState } from 'formstate';

interface Props {
    children: React.ReactNode,
    onSubmit: (data: any) => void,
    formState: FormState<any>
}

@observer class Form extends React.Component<Props> {

    onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const { onSubmit, formState } = this.props;
        
        const res = await formState.validate();

        if (res.hasError) {
            return;
        }

        let data = {} as any;
        Object.keys(formState.$).forEach((name) => {
            const form = formState.$ as any;
            data[name] = !!form[name].$ ? form[name].$ : null; // If empty string, set null
        })

        onSubmit(data);
    }

    render() {
        const { children } = this.props;

        return (
            <form onSubmit={this.onSubmit}>
                {children}
            </form>
        )
    }
}

export default Form;