import { observer } from "mobx-react-lite";
import { lageropgaveStore, OpbevaringsholderInterface, SelectedIdInterface } from "stores/LageropgaveStore";
import { Table } from 'rsuite';
import OpbevaringsHolderHelper from "helpers/OpbevaringsHolderHelper";
import moment from 'moment';
import { bestilUdleveringStore } from 'stores/BestilUdleveringStore';
const { Column, HeaderCell, Cell } = Table;

const BestilLagerlinierTable = observer(() => {

    const data = bestilUdleveringStore.opbevaringsholdere.filter((oh) => !oh._isChild);

    return (
        <Table
            data={data}
            rowKey='id'
            autoHeight>

            <Column flexGrow={1}>
                <HeaderCell>Gods</HeaderCell>
                <Cell>
                    {
                        (oh: OpbevaringsholderInterface, rowIndex: any) => {
                            const key = oh.id;
                            const uspecificeret = oh.uspecificeretAntal > 0;

                            let antal = 0;
                            if (uspecificeret) {
                                antal = oh.uspecificeretAntal;
                            }
                            else {
                                antal = oh.pluk;
                            }

                            return (
                                <span>
                                    {`
                                        ${antal === 0 ? 1 : antal}x 
                                        ${uspecificeret ? 'usp. på ' : ''} 
                                        ${oh.holderNavn || ''} ${oh.holderId || ''}
                                    `}
                                </span>
                            )
                        }
                    }
                </Cell>
            </Column>

            <Column flexGrow={1}>
                <HeaderCell>Identifikation</HeaderCell>
                <Cell>
                    {(rowData: any, rowIndex: number) => {
                        return (
                            <>
                                {OpbevaringsHolderHelper.holderIdentifikation(rowData)}
                                {
                                    rowData.bedstFoerDato &&
                                    <span> ({moment(rowData.bedstFoerDato).format('DD-MM-YYYY')})</span>
                                }
                            </>
                        )
                    }}
                </Cell>
            </Column>

            <Column flexGrow={1}>
                <HeaderCell>Note</HeaderCell>
                <Cell dataKey='note' />
            </Column>
        </Table>
    )
})

export default BestilLagerlinierTable;