import { BaseStore } from "./BaseStore";
import { observable, action, makeObservable } from "mobx";
import { FilInterface } from "./LageropgaveStore";
import { AdresseInterface } from "./AdresseStore";
import request from "helpers/api/PrivateApiRequest";

export interface VareInterface {
    "vareKoereseddelId": number,
    "id": number,
    "antal": number,
    "estimeretAntal": number,
    "navn": string,
    "stregkoder": string,
    "overgruppe": string,
    "beskrivelse": string,
    "m3": number,
    "m2": number,
    "fil": FilInterface,
    "adresser": Array<AdresseInterface>
}

export class VareStore extends BaseStore {
    @observable varer: Array<VareInterface> = [];
    @observable selectedIds: { [key: string]: number } = {};

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(request);

        makeObservable(this);
    }

    @action
    resetStore() {
        this.varer = [];
        this.selectedIds = {};
    }

    @action
    resetSelectedIds() {
        this.selectedIds = {};
    }

    @action
    getVarer(ordreId: number) {
        return this.get(`varer?ordreId=${ordreId}`, 'varer');
    }

    @action
    setSelectedId = (id: number, value: number, adresseId?: number) => {
        let ids = this.selectedIds;

        let savedId = '' + id;
        if (adresseId) {
            savedId = `${id}_${adresseId}`;
        }

        if (!Number(value) || Number(value) < 1) {
            delete (ids[savedId]);
        }
        else {
            ids[savedId] = Number(value);
        }

        this.selectedIds = Object.assign({}, ids);
    }

    getSelectedId = (selectedIds: { [key: string]: number }, id: number, adresseId?: number) => {

        if (adresseId) {
            const matchingId = Object.keys(selectedIds).filter((k) => k.indexOf(`${id}_${adresseId}`) !== -1);
            if (matchingId) {
                return selectedIds[matchingId[0]];
            }
            else return null;
        }
        else return selectedIds[id] ?? null;
    }

    splitSelectedId = (id: string): {vareId: number | null, adresseId: number | null, antal: number | null} => {
        const val = id.split('_');
        return {
            vareId: val[0] ? Number(val[0]) : null,
            adresseId: val[1] ? Number(val[1]) : null,
            antal: this.selectedIds[id] ?? null
        }
    }

    getSelectedIds = () => {
        return Object.keys(this.selectedIds).map((id) => this.splitSelectedId(id));
    }
}

export const vareStore = new VareStore();