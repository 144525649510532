import React from 'react';
import { observer } from 'mobx-react';
import css from './style.module.css';
import Button from 'components/Button';
import { authStore } from 'stores/AuthStore';
import UploadFile from 'components/UploadFile';
import { FileInterface, fileStore } from 'stores/FileStore';
import { toastStore } from 'stores/ToastStore';
import { navigationStore } from 'stores/NavigationStore';

interface State {
    isUploading: boolean
}

@observer class ImportVarekatalogPage extends React.Component<any, State> {

    state = { isUploading: false }

    downloadTemplate = async () => {
        await authStore.getDownloadToken();
        const token = authStore.downloadToken;
        window.open(`
            ${process.env.REACT_APP_API_BASEURL}katalog/excel-template?clientKey=${process.env.REACT_APP_CLIENT_KEY}&token=${token}`
        );
    }

    uploadFile = async (file: FileInterface) => {
        try {
            this.setState({ isUploading: true })
            await fileStore.uploadFileToAPI(`${process.env.REACT_APP_API_BASEURL}katalog/from-excel-template`, file);
            this.setState({ isUploading: false })
            navigationStore.push('/varekatalog');
        }
        catch (error) {
            toastStore.addError("Der skete en fejl ved indlæsning af dokumentet.");
            this.setState({ isUploading: false })
        }

    }

    render() {
        const { isUploading } = this.state;

        if (isUploading) {
            return (
                <div className={css.container}>
                    <div className={css.content}>
                        <h1>Indlæser. Vent venligst...</h1>
                    </div>
                </div>
            )
        }

        return (
            <div className={css.container}>
                <div className={css.content}>
                    <h1>Upload dit varekatalog</h1>
                    <p>
                        Du kan herunder downloade en skabelon, som du kan udfylde og benytte til at importere hele dit varekatalog i systemet nemt og hurtigt.
                    </p>

                    <Button
                        onClick={this.downloadTemplate}
                        label='Download skabelon til udfyldning'
                        style={{ marginTop: '50px' }}
                    />

                    <br />

                    <UploadFile onSubmit={this.uploadFile} customUpload={true} allowedFileTypes={['xlsx']}>
                        <Button
                            label='Upload og indlæs udfyldt skabelon'
                            type='button'
                            primary={true}
                            style={{ marginTop: '20px' }}
                        />
                    </UploadFile>
                </div>
            </div>
        )
    }
}

export default ImportVarekatalogPage;