import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { katalogStore, StregkodeInterface } from 'stores/KatalogStore';
import Breadcrumb from 'components/Breadcrumb';
import { navigationStore } from 'stores/NavigationStore';
import DetailsRow from 'components/DetailsRow';
import Input from 'components/Forms/Input';
import { FieldState, FormState } from 'formstate';
import Button from 'components/Button';
import { loadingStore } from 'stores/LoadingStore';
import { isRequired } from 'helpers/FormValidation';
import css from './style.module.css';
import { FileInterface } from 'stores/FileStore';
import UploadFile from 'components/UploadFile';
import Form from 'components/Forms/Form';
import { toastStore } from 'stores/ToastStore';
import Checkbox from 'components/Forms/Checkbox';

@observer class EditVarePage extends React.Component<RouteComponentProps<any>> {

    form = new FormState({
        navn: new FieldState('').validators(isRequired),
        varenr: new FieldState('').validators(isRequired),
        beskrivelse: new FieldState(''),
        metaOeko: new FieldState(false)
    })

    getVareIdByParams = () => {
        return this.props.match.params.id;
    }

    async componentDidMount() {
        await katalogStore.getKatalog(this.getVareIdByParams());
        const form = this.form.$;
        const katalog = katalogStore.katalog;

        form.navn.reset(katalog!.navn);
        form.varenr.reset(katalog!.varenr);
        form.beskrivelse.reset(katalog!.beskrivelse);
        form.metaOeko.reset(katalog?.metaOeko);
    }

    componentWillUnmount() {
        katalogStore.resetStore();
    }

    onSubmit = async () => {
        const form = this.form.$;

        await katalogStore.updateKatalog(this.getVareIdByParams(), {
            navn: form.navn.$,
            varenr: form.varenr.$,
            beskrivelse: form.beskrivelse.$,
            metaOeko: form.metaOeko.$
        } as any);

        toastStore.addToast({
            title: 'Ændringer gemt',
            message: 'Dine ændringer blev gemt',
            level: 'success'
        })

        navigationStore.push('/varekatalog');
    }

    deleteVare = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (!window.confirm("Er du sikker på at du vil slette denne vare?\n\nNår du sletter en vare forsvinder den fra oversigten. Ønsker du at beholde historikken, skal du arkivere varen i stedet.\n\nTryk OK hvis du ønsker at slette.")) return;

        await katalogStore.deleteKatalog(this.getVareIdByParams());
        navigationStore.push('/varekatalog')
    }

    archiveVare = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (!window.confirm("Er du sikker på at du vil arkivere denne vare?\n\nNår du arkiverer en vare, beholder du historikken i oversigten. Ønsker du at fjerne al historik, skal du slette varen i stedet.\n\nTryk OK hvis du ønsker at arkivere.")) return;

        await katalogStore.archiveKatalog(this.getVareIdByParams());
        navigationStore.push('/varekatalog');
    }

    addBarcode = () => {
        const stregkode = window.prompt("Indtast stregkode");
        if (!stregkode) return;

        katalogStore.createStregkode(this.getVareIdByParams(), stregkode);
    }

    updateBarcode = (e: React.MouseEvent, stregkode: StregkodeInterface) => {
        e.preventDefault();

        const nyStregkode = window.prompt("Redigér stregkode", stregkode.stregkode);
        if (!nyStregkode || nyStregkode === stregkode.stregkode) return;

        stregkode.stregkode = nyStregkode;
        katalogStore.updateStregkode(this.getVareIdByParams(), stregkode);
    }

    removeBarcode = (barcodeId: number) => {
        if (!window.confirm("Er du sikker på at du vil slette denne stregkode?")) return;

        katalogStore.deleteStregkode(this.getVareIdByParams(), barcodeId);
    }

    addImage = (file: any) => {
        katalogStore.createFile(this.getVareIdByParams(), file);
    }

    removeImage = (fileId: number) => {
        if (!window.confirm("Er du sikker på at du vil slette dette billede?")) return;

        katalogStore.deleteFile(this.getVareIdByParams(), fileId);
    }

    render() {
        const katalog = katalogStore.katalog;
        if (!katalog) return null;

        const form = this.form.$;
        const isLoading = loadingStore.isLoading;

        return (
            <div>
                <Breadcrumb
                    onRootClicked={() => navigationStore.push('/varekatalog')}
                    rootLabel='Varekatalog'
                    label='Redigér vare'
                />
                <Form onSubmit={this.onSubmit} formState={this.form}>
                    <DetailsRow label='Navn på vare'>
                        <Input fieldState={form.navn} />
                    </DetailsRow>

                    <DetailsRow label='Varenummer'>
                        <Input fieldState={form.varenr} />
                    </DetailsRow>

                    <DetailsRow label='Stregkode(r)'>
                        <div>
                            <button type='button' onClick={this.addBarcode} className={css.AddButton}>Tilføj stregkode</button>
                        </div>
                        <ul className={css.List}>
                            {
                                katalog.stregkoder.map((item, index) => (
                                    <li
                                        key={index}><a href=' ' onClick={(e) => this.updateBarcode(e, item)}>{item.stregkode}</a> <button type='button' onClick={() => this.removeBarcode(item.id!)} className={css.DeleteButton}>[slet]</button></li>
                                ))
                            }
                        </ul>
                    </DetailsRow>

                    <DetailsRow label='Kort beskrivelse'>
                        <Input fieldState={form.beskrivelse} type='textarea' />
                    </DetailsRow>

                    <DetailsRow label='Diverse'>
                        <Checkbox
                            id='metaOeko'
                            label='Denne vare er økologisk'
                            fieldState={form.metaOeko}
                        />
                    </DetailsRow>

                    <DetailsRow label='Billeder'>
                        <div>
                            <UploadFile onSubmit={this.addImage} allowedFileTypes={['.jpg,.png,.jpeg']}>
                                <button type='button' className={css.AddButton}>Tilføj billede</button>
                            </UploadFile>
                            {
                                katalog.filer.map((item: FileInterface, index) => (
                                    <li key={index}><a href={item.url} target='_blank' rel='noreferrer'>{item.filnavn}</a> <button type='button' onClick={() => this.removeImage(item.id!)} className={css.DeleteButton}>[slet]</button></li>
                                ))
                            }
                        </div>
                    </DetailsRow>

                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => navigationStore.push('/varekatalog')}
                            label='Tilbage'
                            type='button'
                            icon='cross'
                            style={{ marginRight: '20px', marginTop: '40px' }}
                        />
                        <Button
                            primary={true}
                            label='Gem og luk'
                            icon='arrowDownBox'
                            disabledLabel='Øjeblik...'
                            disabled={isLoading}
                        />

                        <div style={{ marginTop: '80px' }}>
                            <a href=" " onClick={(e) => this.deleteVare(e)} style={{ color: '#545454', fontSize: '12px', textDecoration: 'underline' }}>Slet vare</a>
                            <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>
                            <a href=" " onClick={(e) => this.archiveVare(e)} style={{ color: '#545454', fontSize: '12px', textDecoration: 'underline' }}>Arkivér vare</a>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default EditVarePage;