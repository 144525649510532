import { fileStore } from 'stores/FileStore';

export const MAX_FILE_SIZE = 94371840; // 90 mb

export function humanFileSize(size: number) {
    var i = Math.floor(Math.log(size) / Math.log(1024)) as number;
    const x = (size / Math.pow(1024, i)).toFixed(2) as any;
    return x * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export async function uploadFile(file: File) {
    return new Promise(async (resolve, reject) => {
        const maxFileSize = MAX_FILE_SIZE;
        if (file.size > maxFileSize) {
            reject(`Den fil du prøver at vedhæfte fylder for meget. Max filstørrelse er ${humanFileSize(maxFileSize)}.`);
        }
        else {
            const uploadInfo = await fileStore.createFile({
                displaynavn: file.name,
                filnavn: file.name
            } as any);

            await fileStore.uploadFileToAzureBlob(uploadInfo.presignedUrl, file);
            resolve(uploadInfo.fil);
        }
    })

}