import { action, observable, makeObservable } from 'mobx';
const createBrowserHistory = require('history').createBrowserHistory;

export enum HeaderEnum {
    LAGERLINIER = 'LAGERLINIER',
    BEVAEGELSER = 'BEVAEGELSER',
    OVERBLIK = 'OVERBLIK',
    VAREKATALOG = 'VAREKATALOG'
}

export class NavigationStore {

    @observable activeHeader: HeaderEnum | null = HeaderEnum.LAGERLINIER;
    
    history = createBrowserHistory();

    constructor() {
        makeObservable(this);
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    push(location: string, callback: Function | undefined = undefined) {
        this.history.push(location);
        this.setActiveHeaderByCurrentBrowserLocation();

        if (callback) {
            callback();
        }
    }

    @action
    replace(location: string) {
        this.history.replace(location);
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    goBack() {
        this.history.goBack();
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    goForward() {
        this.history.goForward();
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    setActiveHeader(header: HeaderEnum | null) {
        if (header !== this.activeHeader) {
            this.activeHeader = header;
        }
    }

    setActiveHeaderByCurrentBrowserLocation() {
        if (window.location.pathname === '/' || window.location.pathname === '' || window.location.pathname === '/produkter')  this.setActiveHeader(HeaderEnum.LAGERLINIER);
        else if (window.location.pathname === '/bevaegelser') this.setActiveHeader(HeaderEnum.BEVAEGELSER);
        else if (window.location.pathname === '/overblik') this.setActiveHeader(HeaderEnum.OVERBLIK);
        else if (window.location.pathname === '/varekatalog') this.setActiveHeader(HeaderEnum.VAREKATALOG);
        else this.setActiveHeader(null)
    }

}

export const navigationStore = new NavigationStore();