import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'rsuite';
import _ from 'lodash';
import { FieldState } from 'formstate';
import SelectableCell from 'components/SelectableCell';
import { ordreStore } from 'stores/OrdreStore';
import css from './style.module.css';
import DetailsRow from 'components/DetailsRow';
const { Column, HeaderCell } = Table;

interface Props {
    fieldState: FieldState<any>,
    onChange?: (ordreId: number) => void,
    title: string,
    lageropgaveId?: number
}

@observer class SelectOrdre extends React.Component<Props> {

    async componentDidMount() {
        const { lageropgaveId, onChange } = this.props;

        await ordreStore.getOrdrer(lageropgaveId);
        if (ordreStore.ordrer.length === 1) {
            const { fieldState } = this.props;
            const ordreId = ordreStore.ordrer[0].id;
            fieldState.onChange(ordreId);

            if (onChange) {
                onChange(ordreId);
            }
        }
    }

    componentWillUnmount() {
        ordreStore.setSelectedId(null);
    }

    onChange = (id: number | null) => {
        const { fieldState, onChange } = this.props;

        ordreStore.setSelectedId(id);
        fieldState.onChange(id);
        if (onChange) {
            onChange(id!);
        }
    }

    render() {
        const { fieldState, title } = this.props;
        const ordrer = _.orderBy(ordreStore.ordrer || [], 'navn');
        const selectedId = ordreStore.selectedId;

        if (ordrer.length === 1) return null;

        return (
            <DetailsRow
                label={title}>
                <div>
                    <div className={css.error}>
                        {fieldState.error}
                    </div>
                    <Table
                        onRowClick={(rowData: any) => this.onChange(rowData.id)}
                        data={ordrer}
                        rowKey='id'
                        renderEmpty={() => null}
                        autoHeight>

                        <Column flexGrow={1}>
                            <HeaderCell>Navn</HeaderCell>
                            <SelectableCell dataKey='navn' selectedid={selectedId} />
                        </Column>

                        <Column flexGrow={1}>
                            <HeaderCell>Kontaktperson</HeaderCell>
                            <SelectableCell selectedid={selectedId}>
                                {(rowData: any) => <div>{rowData?.kundensKontaktperson?.navn}</div>}
                            </SelectableCell>
                        </Column>

                        <Column flexGrow={1}>
                            <HeaderCell>Rekv. nr</HeaderCell>
                            <SelectableCell dataKey='rekvisitionsNummer' selectedid={selectedId} />
                        </Column>

                        <Column flexGrow={1}>
                            <HeaderCell>EAN</HeaderCell>
                            <SelectableCell dataKey='eanNummer' selectedid={selectedId} />
                        </Column>
                    </Table>
                </div>
            </DetailsRow>

        )
    }
}

export default SelectOrdre;