import DetailsRow from 'components/DetailsRow';
import { observer } from 'mobx-react-lite';
import { bestilUdleveringStore, UdleveringsTypeEnum } from 'stores/BestilUdleveringStore';
import BestilLagerlinierTable from '../BestilLagerlinierTable';
import BestilProdukterTable from '../BestilProdukterTable';

const InventoryTable = observer(() => {

    return (
        <DetailsRow label='Gods til udlevering'>
            {
                bestilUdleveringStore.udleveringsType === UdleveringsTypeEnum.OPBEVARINGSHOLDERE &&
                <BestilLagerlinierTable />
            }

            {
                bestilUdleveringStore.udleveringsType === UdleveringsTypeEnum.PRODUKTER &&
                <BestilProdukterTable />
            }

        </DetailsRow>
    )

})

export default InventoryTable;