import * as React from 'react';
import { observer } from 'mobx-react';
import Breadcrumb from 'components/Breadcrumb';
import { navigationStore } from 'stores/NavigationStore';
import DetailsRow from 'components/DetailsRow';
import DateInput from 'components/Forms/DateInput';
import { FormState, FieldState } from 'formstate';
import SelectAdresse from './SelectAdresse';
import { lageropgaveStore } from 'stores/LageropgaveStore';
import SelectKontaktperson from 'components/SelectKontaktperson';
import Form from 'components/Forms/Form';
import Button from 'components/Button';
import { BestillingTypeEnum } from 'stores/BestillingStore';
import _ from 'lodash';
import { OrdreInterface, ordreStore } from 'stores/OrdreStore';
import Input from 'components/Forms/Input';
import { loadingStore } from 'stores/LoadingStore';
import FileList from 'components/FileList';
import { RouteComponentProps } from 'react-router-dom';
import { lageropgaveProduktStore } from 'stores/LageropgaveProduktStore';
import { getLocalAdresseIdByOrdre } from 'helpers/AdresseHelper';
import SetLocation, { LocationEnum } from 'components/SetLocation';
import Shipmondo from 'components/Shipmondo';
import { adresseStore } from 'stores/AdresseStore';
import { ProductServiceCodeEnum, shipmondoStore } from 'stores/PublicApi/ShipmondoStore';
import { KontaktpersonInterface, kontaktpersonStore } from 'stores/KontaktpersonStore';
import { bestilUdleveringStore, UdleveringsTypeEnum } from 'stores/BestilUdleveringStore';
import InventoryTable from './InventoryTable';
import SelectOrdre from 'components/SelectOrdre';
import { checkEmailValidityWithRegEx, checkPhoneNumberValidityWithRegEx } from 'helpers/FormValidation';

interface State {
    location: LocationEnum
}

export interface ShipmondoProductServicesInterface {
    timeOfDelivery: FieldState<string>,
    sms: FieldState<boolean>,
    email: FieldState<boolean>,
    flex: FieldState<boolean>,
}

@observer class BestilUdleveringPage extends React.Component<RouteComponentProps<any>, State> {

    state = { location: LocationEnum.JD_LOGISTIK }

    form = new FormState({
        date: new FieldState(''),
        adresse: new FieldState<null | number>(null),
        ordre: new FieldState<null | number>(null),
        kontaktperson: new FieldState<null | number>(null),
        tekst: new FieldState<null | string>(null),
        sporingsNote: new FieldState<null | string>(null),

        //Shipmondo,
        shipmondoCarrier: new FieldState(''),
        shipmondoProduct: new FieldState(''),
        shipmondoPickupPoint: new FieldState(''),
        shipmondoTransportoerInstruks: new FieldState(''),
        shipmondoProductServicesFieldState: new FormState({
            timeOfDelivery: new FieldState<string>(''),
            sms: new FieldState(false),
            email: new FieldState(false),
            flex: new FieldState(false)
        })
    })

    componentDidMount() {
        const type = this.props.match.params.isProduct
            ? UdleveringsTypeEnum.PRODUKTER
            : UdleveringsTypeEnum.OPBEVARINGSHOLDERE;
        bestilUdleveringStore.startFlow(type);

        if (!bestilUdleveringStore.canStartOrderFlow) {
            navigationStore.push('');
            return;
        }
    }

    componentWillUnmount() {
        bestilUdleveringStore.resetStore();
    }

    getAdresse = (ordre?: OrdreInterface, adresseId?: number): { id: number } | undefined => {
        const { location } = this.state;
        switch (location) {
            case LocationEnum.JD_LOGISTIK:
                return { id: getLocalAdresseIdByOrdre(ordre) }
            case LocationEnum.DOEGNTERMINAL:
                return { id: Number(process.env.REACT_APP_ADRESSE_DOEGNTERMINAL_ID) }
            case LocationEnum.ANDEN_ADRESSE:
                if (adresseId) {
                    return { id: adresseId }
                }
                else return undefined;
            default:
                return undefined;
        }
    }

    onSubmit = async () => {

        const { location } = this.state;
        const form = this.form.$;
        const ordre = _.find(ordreStore.ordrer, { id: form.ordre.$! }) as OrdreInterface;

        const adresse = this.getAdresse(ordre, form.adresse.$ ? Number(form.adresse.$) : undefined);
        if (!adresse) {
            alert('Adresse er påkrævet. Du mangler at vælge et udleveringssted');
            return;
        }

        const isPalleFragt = !!!form.shipmondoCarrier.$;
        if (lageropgaveStore.selectedLageropgave!.visShipmondoIWebUI && location === LocationEnum.ANDEN_ADRESSE && !isPalleFragt) {
            try {
                await shipmondoStore.checkValidSubmit(
                    lageropgaveStore.selectedLageropgave!.id,
                    adresse.id,
                    form.shipmondoCarrier.$,
                    form.shipmondoProduct.$,
                    form.shipmondoPickupPoint.$
                )
            }
            catch (error) {
                alert('Et eller flere felter under fragt mangler at blive udfyldt');
                return;
            }
        }

        const data = {
            indUdAdresse: adresse,
            indUdKontakt: form.kontaktperson.$
                ? { id: form.kontaktperson.$! }
                : { id: process.env.REACT_APP_DRIFT_KONTAKTPERSON_ID },
            ordre: ordre,
            opbevaringsholdere: bestilUdleveringStore.opbevaringsholdere,
            produkter: bestilUdleveringStore.produkter,
            type: BestillingTypeEnum.UDLEVERING,
            dato: (form.date.$ === null || form.date.$.length === 0) ? null : form.date.$,
            tekst: form.tekst.$,
            sporingsNote: form.sporingsNote.$,
            filer: bestilUdleveringStore.files,
        } as any;

        if (isPalleFragt && location === LocationEnum.ANDEN_ADRESSE) {
            data.tekst = 'PALLE FRAGT' + (data.tekst ? ` - ${data.tekst}` : '');
        }

        if (lageropgaveStore.selectedLageropgave!.visShipmondoIWebUI && !isPalleFragt) {
            data.shipmondoTransportoerKode = form.shipmondoCarrier.$;
            data.shipmondoProduktKode = form.shipmondoProduct.$;
            data.shipmondoPickupPointId = form.shipmondoPickupPoint.$;
            data.shipmondoTransportoerInstruks = form.shipmondoTransportoerInstruks.$;

            const shipmondoProduktServices: Array<string> = [];

            const formServices = this.form.$.shipmondoProductServicesFieldState.$;
            if (formServices.timeOfDelivery.$) {
                shipmondoProduktServices.push(formServices.timeOfDelivery.$);
            }

            if (formServices.sms.$) {
                shipmondoProduktServices.push('SMS_NT');
            }

            if (formServices.email.$) {
                shipmondoProduktServices.push('EMAIL_NT');
            }

            if (formServices.flex.$) {
                shipmondoProduktServices.push('FLEX');
            }

            data.shipmondoProduktServices = shipmondoProduktServices;

            if (location === LocationEnum.ANDEN_ADRESSE) {
                const kontaktperson = _.find(kontaktpersonStore.kontaktpersoner, k => k.id === form.kontaktperson.$) as KontaktpersonInterface | null;
                if (!kontaktperson?.telefonMobil || !kontaktperson.email) {
                    alert('Der mangler at blive sat telefonnummer (mobil) eller email på kontaktperson. Tryk på "Vis detaljer" under kontaktpersonen og gem disse informationer, før du fortsætter.');
                    return;
                }
                if (!checkEmailValidityWithRegEx(kontaktperson.email)) {
                    alert('Ugyldig email på kontaktperson.');
                    return;
                }

                if (!checkPhoneNumberValidityWithRegEx(kontaktperson.telefonMobil)) {
                    alert('Ugyldigt telefonnummer på kontaktperson.');
                }
            }
        }

        try {
            await bestilUdleveringStore.createBestilling(data);

            lageropgaveStore.resetSelectedIds();
            lageropgaveProduktStore.resetSelectedIds();
            navigationStore.replace('/udlevering-done');
        }
        catch (error) {
            console.log(error);
        }
    }

    onCancel = () => {
        switch (bestilUdleveringStore.udleveringsType) {
            case UdleveringsTypeEnum.OPBEVARINGSHOLDERE:
                navigationStore.push('/');
                break;
            case UdleveringsTypeEnum.PRODUKTER:
                navigationStore.push('/produkter');
                break;
            default: break;
        }

        bestilUdleveringStore.resetStore();
    }

    onLocationChanged = (location: LocationEnum) => {
        const form = this.form.$;
        if (location !== LocationEnum.ANDEN_ADRESSE) {
            form.adresse.onChange(null);
        }

        this.setState({ location: location });
    }

    getCountryCodeBySelectedAddress = () => {
        const form = this.form.$;
        const selectedAddress = form.adresse.$;
        if (!selectedAddress) return undefined;

        return _.find(adresseStore.adresser, a => a.id === selectedAddress)?.landekode;
    }

    render() {
        const { location } = this.state;
        const form = this.form.$;
        const isLoading = loadingStore.isLoading;
        let lageropgaveId = lageropgaveStore.selectedLageropgave?.id || undefined;
        const kontaktperson = _.find(kontaktpersonStore.kontaktpersoner, k => k.id === form.kontaktperson.value) as KontaktpersonInterface | undefined;
        const enableTime = form.shipmondoProductServicesFieldState.$.timeOfDelivery.value === ProductServiceCodeEnum.TIMED_DELIVERY;

        return (
            <div>
                <Breadcrumb
                    onRootClicked={() => navigationStore.push('/')}
                    rootLabel='Aktive lagerlinier'
                    label='Bestil udlevering'
                />

                <InventoryTable />

                <Form onSubmit={this.onSubmit} formState={this.form}>

                    <DetailsRow label='Udleveringssted'>
                        <SetLocation
                            location={location}
                            onChange={this.onLocationChanged}
                        />

                        {
                            location === LocationEnum.ANDEN_ADRESSE &&
                            <SelectAdresse
                                fieldState={form.adresse}
                                isShipmondo={lageropgaveStore.selectedLageropgave!.visShipmondoIWebUI}
                            />
                        }

                    </DetailsRow>

                    {
                        (form.adresse.$ !== null && form.adresse.$ !== Number(process.env.REACT_APP_ADRESSE_DOEGNTERMINAL_ID)) &&
                        <DetailsRow
                            label='Kontaktperson'
                            subLabel='Ansvarlig på adressen'
                            alignLabel='center'>
                            <SelectKontaktperson
                                fieldState={form.kontaktperson}
                            />
                        </DetailsRow>
                    }

                    {
                        (
                            location === LocationEnum.ANDEN_ADRESSE
                            && lageropgaveStore.selectedLageropgave!.visShipmondoIWebUI
                            && this.getCountryCodeBySelectedAddress()
                        ) &&
                        <Shipmondo
                            carrierFieldState={form.shipmondoCarrier}
                            productFieldState={form.shipmondoProduct}
                            productServicesFieldState={form.shipmondoProductServicesFieldState}
                            pickupPointFieldState={form.shipmondoPickupPoint}
                            transportoerInstruksFieldState={form.shipmondoTransportoerInstruks}
                            kontaktperson={kontaktperson}
                            countryCode={this.getCountryCodeBySelectedAddress()!}
                        />
                    }

                    <DetailsRow
                        label={enableTime ? 'Dato og tidspunkt for udlevering' : 'Dato for udlevering'}
                        subLabel={enableTime ? '4-timers interval fra valgt klokkeslæt.' : undefined}
                        alignLabel='center'>
                        {
                            enableTime &&
                            <DateInput
                                enableTime={true}
                                fieldState={form.date}
                                placeholder='Vælg dato og tidspunkt'
                            />
                        }

                        {
                            !enableTime &&
                            <DateInput
                                fieldState={form.date}
                                placeholder='Hurtigst muligt'
                            />
                        }

                    </DetailsRow>

                    <DetailsRow label='Bemærkning' subLabel='Til JD Logistik ifm. bestillingen'>
                        <Input
                            fieldState={form.tekst}
                            type='textarea' />
                        <FileList
                            onChange={(files) => bestilUdleveringStore.setFiles(files)}
                        />
                    </DetailsRow>

                    <DetailsRow label='Intern note' subLabel='Til eget brug (synlig på følgeseddel)'>
                        <Input fieldState={form.sporingsNote} />
                    </DetailsRow>

                    <SelectOrdre
                        lageropgaveId={lageropgaveId}
                        title='Vælg ordre til fakturering'
                        fieldState={form.ordre}
                    />

                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={this.onCancel}
                            label='Fortryd'
                            type='button'
                            icon='cross'
                            style={{ marginRight: '20px', marginTop: '40px' }}
                        />
                        <Button
                            disabledLabel='Øjeblik...'
                            disabled={isLoading}
                            primary={true}
                            label='Bestil udlevering'
                            icon='arrowUpBox'
                        />
                    </div>

                </Form>

            </div>
        )
    }
}

export default BestilUdleveringPage;