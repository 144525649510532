import request from "helpers/api/PrivateApiRequest";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "./BaseStore";
import { KatalogInterface } from "./KatalogStore";

export interface ProduktOriginInterface {
    "opbevaringsholderId": number,
    "parentOpbevaringsholderId": number,
    "antal": number
}

export interface ProduktInterface {
    "antal": number,
    "filUrls": Array<string>,
    "holderId": string,
    "holderNavn": string,
    "identifikation": string,
    "katalog": KatalogInterface,
    "metaOeko": boolean,
    "metaRetur": boolean,
    "metaSkade": boolean,
    "origins": Array<ProduktOriginInterface>,
    "pluk": number,
    "remainingAntal": number,
    "reserveret": number
}

export interface SelectedIdInterface {
    antal: number,
    produkt: ProduktInterface
}

export class LageropgaveProduktStore extends BaseStore {

    @observable produkter: Array<ProduktInterface> = [];
    @observable selectedIds: { [key: string]: SelectedIdInterface } = {};

    constructor() {
        super(request, 'LageropgaveProduktStore');
        makeObservable(this);

        this.initSessionStorage(this, [
            'produkter',
            'selectedIds'
        ])
    }

    @action
    resetStore = () => {
        this.produkter = [];
        this.selectedIds = {};
    }

    @action
    resetSelectedIds = () => {
        this.selectedIds = {};
    }

    @action
    getProdukter = (lageropgaveId: number) => {
        return this.get(`lageropgaver/${lageropgaveId}/produkter`, 'produkter');
    }

    @action
    setSelectedId = (produkt: ProduktInterface, value: number) => {
        let ids = this.selectedIds;
        ids[produkt.identifikation] = {
            antal: Number(value) || 0,
            produkt: produkt
        };
        this.selectedIds = Object.assign({}, ids);
    }

    @action
    deleteSelectedId(produktIdentifikation: string) {
        let ids = this.selectedIds;
        if (!ids[produktIdentifikation]) return;

        delete ids[produktIdentifikation];
        this.selectedIds = Object.assign({}, ids);
    }

    getSelectedProducts = () => {
        const resultArr: Array<ProduktInterface> = [];

        Object.keys(this.selectedIds).forEach((id) => {
            const entity = this.selectedIds[id];

            entity.produkt.pluk = entity.antal;
            resultArr.push(entity.produkt);
        })

        return resultArr;

    }

}

export const lageropgaveProduktStore = new LageropgaveProduktStore();