import DetailsRow from "components/DetailsRow";
import { FieldState, FormState } from "formstate";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { AdresseInterface, adresseStore } from "stores/AdresseStore";
import { ProductServiceCodeEnum, ProductServiceInterface, shipmondoStore } from "stores/PublicApi/ShipmondoStore";
import SelectCarrier from "./SelectCarrier";
import SelectPickupPoint from "./SelectPickupPoint";
import SelectProduct from "./SelectProduct";
import TransportInstructions from "./TransportInstructions";
import SelectServices from './SelectServices';
import { KontaktpersonInterface } from 'stores/KontaktpersonStore';

interface Props {
    carrierFieldState: FieldState<any>,
    productServicesFieldState: FormState<any>,
    productFieldState: FieldState<any>,
    pickupPointFieldState: FieldState<any>,
    transportoerInstruksFieldState: FieldState<any>,
    countryCode: string,
    kontaktperson?: KontaktpersonInterface
}

const Shipmondo = observer((props: Props) => {
    const [isServicePointRequired, setIsServicePointRequired] = useState(false);

    const productFieldStateValue = props.productFieldState.value;
    const carrierFieldStateValue = props.carrierFieldState.value;

    useEffect(() => {
        if (productFieldStateValue) {
            const product = _.find(shipmondoStore.products, p => p.code === productFieldStateValue);
            if (product) {
                setIsServicePointRequired(product.service_point_available);
            }
            else {
                setIsServicePointRequired(false);
                props.pickupPointFieldState.onChange(null);
            }
        }

        return () => {
            setIsServicePointRequired(false);
            props.pickupPointFieldState.onChange(null);
        }
    }, [productFieldStateValue])

    useEffect(() => {
        setIsServicePointRequired(false);
    }, [carrierFieldStateValue])

    let adresse;
    if (isServicePointRequired) {
        adresse = adresseStore.selectedAdresse as AdresseInterface;
    }

    const allServices: Array<ProductServiceInterface> = _.find(shipmondoStore.products, p => p.code === props.productFieldState.value)?.available_services || [];
    const showEmail = !!(props.kontaktperson?.email && _.some(allServices, s => s.code === ProductServiceCodeEnum.EMAIL_NT))
    const showSMS = !!(props.kontaktperson?.telefonMobil && _.some(allServices, s => s.code === ProductServiceCodeEnum.SMS_NT));

    return (
        <>
            <DetailsRow label="Fragt">
                <SelectCarrier
                    fieldState={props.carrierFieldState}
                    countryCode={props.countryCode}
                />

                {
                    props.carrierFieldState.value &&
                    <SelectProduct
                        fieldState={props.productFieldState}
                        carrierCode={props.carrierFieldState.value}
                        countryCode={props.countryCode}
                    />
                }

                {
                    props.productFieldState.value &&
                    <SelectServices
                        formState={props.productServicesFieldState}
                        productCode={props.productFieldState.value}
                        showEmail={showEmail}
                        showSMS={showSMS}
                    />
                }

                {
                    (isServicePointRequired && props.productFieldState.value && props.carrierFieldState.value && adresse) &&
                    <SelectPickupPoint
                        fieldState={props.pickupPointFieldState}
                        carrierCode={props.carrierFieldState.value}
                        countryCode={adresse.landekode!}
                        zipCode={adresse.postnummer?.toString()}
                    />
                }
            </DetailsRow>

            {
                (props.carrierFieldState.value === 'bft' ||
                    props.carrierFieldState.value === 'dfm') &&

                <DetailsRow label='Kort besked til transportøren' subLabel='Eks. "Stilles under trappen" el. lign.'>
                    <TransportInstructions
                        fieldState={props.transportoerInstruksFieldState}
                    />
                </DetailsRow>

            }
        </>
    )

})

export default Shipmondo;