import * as React from 'react';
import { observer } from 'mobx-react';
import css from './style.module.css';
import Button from 'components/Button';

import imageSrc from './illustration.svg';
import { navigationStore } from 'stores/NavigationStore';

@observer class DoneDescription extends React.Component {

    render() {
        return (
            <div className={css.container}>
                <div className={css.content}>
                    <img src={imageSrc} alt='Tak for din bestilling' />
                    <h1>
                        Tak. Vi har modtaget din bestilling.
                    </h1>

                    <Button
                        primary={true}
                        style={{ marginTop: '50px' }}
                        label='Gå til bevægelser'
                        icon='arrowUpBox' onClick={() => navigationStore.replace('/bevaegelser')}
                    />
                </div>
            </div>
        )
    }
}

export default DoneDescription;