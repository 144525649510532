import request from "helpers/api/PublicApiRequest";
import LoadPromise from "helpers/LoadPromise";
import { action, makeObservable, observable } from "mobx";

export interface InventoryShopifyDetailsInterface {
    "shopUrl": string,
    "token": string,
    "appSecret": string,
    "inventoryId": number,
    "shipmondoUsername": string,
    "shipmondoPassword": string,
    "shipmondoDeactivateTestMode": boolean
}

class InventoryShopifyDetailsStore {

    @observable details: InventoryShopifyDetailsInterface | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    resetStore = () => {
        this.details = null;
    }

    @action
    getDetails = (inventoryId: number) => {
        return LoadPromise(async(resolve, reject) => {
            try {
                const res = await request.get(`inventories/${inventoryId}/shopify`);
                if (res.status === 200) {
                    this.details = res.data;
                    resolve(res.data);
                } else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    createDetails = (details: InventoryShopifyDetailsInterface) => {
        return LoadPromise(async(resolve, reject) => {
            try {
                const res = await request.post(`inventories/${details.inventoryId}/shopify`, details);
                if (res.status === 200) {
                    this.details = res.data;
                    resolve(res.data);
                } else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

    @action
    deleteDetails = (inventoryId: number) => {
        return LoadPromise(async(resolve, reject) => {
            try {
                const res = await request.delete(`inventories/${inventoryId}/shopify`);
                if (res.status === 204) {
                    resolve(true);
                } else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const inventoryShopifyDetailsStore = new InventoryShopifyDetailsStore();