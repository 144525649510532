import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'rsuite';
import { statistikStore, PaamindelseInterface } from 'stores/StatistikStore';
import { loadingStore } from 'stores/LoadingStore';
import _ from 'lodash';
const { Column, HeaderCell, Cell } = Table;

@observer class Paamindelser extends React.Component {

    deletePaamindelse = (id: number) => {
        if (window.confirm('Er du sikker på at du vil slette denne påmindelse?')) {
            statistikStore.deletePaamindelse(id);
        }
    }

    render() {
        const data = statistikStore.paamindelser || [];
        const isLoading = loadingStore.isLoading;

        return (
            <Table
                data={_.orderBy(data, 'id', 'desc')}
                rowKey='id'
                renderEmpty={() => !isLoading ? <div className='rs-table-body-info'>Du har ikke oprettet nogle påmindelser endnu.</div> : null}
                autoHeight>

                <Column flexGrow={1}>
                    <HeaderCell>Identifikation</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            return rowData.identifikationstekst || rowData.katalog?.navn || '';
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1}>
                    <HeaderCell>Holdertype</HeaderCell>
                    <Cell dataKey='holderTypeNavn' />
                </Column>

                <Column flexGrow={1}>
                    <HeaderCell>Påmind ved (antal)</HeaderCell>
                    <Cell dataKey='paamindVedBeholding' />
                </Column>

                <Column flexGrow={1}>
                    <HeaderCell>Send til email</HeaderCell>
                    <Cell dataKey='email' />
                </Column>

                <Column width={85}>
                    <HeaderCell>Handling</HeaderCell>
                    <Cell>
                        {(rowData: PaamindelseInterface, rowIndex: number) => {
                            return (
                                <button
                                    style={{
                                        color: '#d66161',
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                        marginLeft: '18px'
                                    }}
                                    onClick={() => this.deletePaamindelse(rowData.id!)}>[Slet]</button>
                            )
                        }}
                    </Cell>
                </Column>

            </Table>
        )
    }
}

export default Paamindelser;